import { React, Component } from 'react';
import LegalEntityFormHandler from './LegalEntityForm/LegalEntityFormHandler';
import SingleNavigationBar from '../../components/SingleNavigationBar/SingleNavigationBar';

class PropertyPage extends Component {

    render() {
        return(
            <SingleNavigationBar content={
                <LegalEntityFormHandler/>
            } />
        );
    };
}

export default PropertyPage;