import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { AppBar, Toolbar, IconButton } from "@material-ui/core";
import { MdAccountCircle } from "react-icons/md";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import {Link} from 'react-router-dom';

import Logo from "../../assets/images/you-logo-here.png";

const MyAppBar = (props) => {

  return (
    <AppBar position="fixed" className={props.classes.appBar}>
      <Toolbar>
        <a href="/">
          <img
            src={Logo}
            alt="Logo"
            width="200"
            height="50"
            style={{ position: "relative", left: "-10px" }}
          />
        </a>
        <h5 style={{ marginLeft: "30px" }}>Report Tracking & Management</h5>
        <div style={{ flexGrow: 1 }} />
        <div className={props.classes.sectionDesktop}>
          <Stack direction="row" spacing={2}>
            <div>
              <IconButton
                ref={props.anchorRef}
                id="composition-button"
                aria-controls={props.open ? 'composition-menu' : undefined}
                aria-expanded={props.open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={props.handleToggle}
              >
                <MdAccountCircle size={40}/>
              </IconButton>
              <Popper
                open={props.open}
                anchorEl={props.anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={props.handleClose}>
                      <MenuList
                        autoFocusItem={props.open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={props.handleListKeyDown}
                      >
                        <MenuItem component={Link} to='/profile'>Profile</MenuItem>
                        <MenuItem component={Link} to='/help'>Help</MenuItem>
                        <MenuItem onClick={props.logOut}>Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>)}
              </Popper>
            </div>
          </Stack>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default MyAppBar;
