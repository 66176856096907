import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { FaFileDownload } from "react-icons/fa";
import Switch from '@mui/material/Switch';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  smallBoxMargin: {
    margin: theme.spacing(2),
    minWidth: 60,
  },
  buttonRoot: {
    margin: theme.spacing(4),
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    border: "2px solid #3f85f0",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function BulkUpload(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const [errListActive, setErrList] = React.useState(false);

  const toggleErrorList = (e) => {
    e.preventDefault();
    setErrList(!errListActive);
  }

  const detailed_errors = (<div style={{overflowY: 'auto', maxHeight: '200px'}}><ul>
    {props.detailed_errors &&
    props.detailed_errors.map((value, idx) => {
      return (<li>{value}</li>);
    })}
  </ul></div>);

  const body = props.success ? (
    <div style={modalStyle} className={classes.paper}>
      <h3>{props.csv_result}</h3>
      {props.detailed_errors && props.detailed_errors.length !== 0 && 
        <button className={"button"} 
          style={ errListActive ? 
              {marginBottom: '5px', border: "2px solid red"}:{marginBottom: '5px'} 
            } 
          onClick={toggleErrorList}>
          Show Error List
        </button>}
      {errListActive && detailed_errors}
      <button
        className={"button"}
        style={{
          marginTop: "10px",
          float: "right",
        }}
        onClick={props.handleClose}
      >
          Close
      </button>
    </div>
  ) : (
    <div style={modalStyle} className={classes.paper}>
      <button
        className={"flex-row button"}
        style={{ marginBottom: "10px", float: "right" }}
        onClick={props.handleTemplateClick}
      >
        <FaFileDownload />
        <div
          style={{
            fontSize: 14,
            paddingLeft: "5px",
            // color: "rgb(63, 133, 240)",
          }}
        >
          Template
        </div>
      </button>
      <br />
      <input
        className="input"
        type="file"
        accept=".csv"
        id="csvFile"
        onChange={(e) => {
          props.setCsvFile(e.target.files[0]);
        }}
      ></input>
      <br />
      {!props.no_toggle && "Update Existing?"}
      {!props.no_toggle && <Switch checked={props.checked} onChange={props.handleSwitchClick}/>}
      <button
        className={"flex-row button"}
        style={{ marginTop: "10px", float: "right" }}
        onClick={props.handleSubmit}
      >
        <div
          style={{
            fontSize: 14,
            // color: "rgb(63, 133, 240)",
          }}
        >
          Submit
        </div>
      </button>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.show}
        onClose={props.handleClose}
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
