// This component is used when the user clicks on the link sent to their email 
// after completing the 'forgot my password' form

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import CenterBanner from '../CenterBanner/CenterBanner'
import PropTypes from 'prop-types';
import {url} from '../../config';
import Cookies from 'js-cookie';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loading: {
        margin: '1em',
        fontSize: '24px',
    },
});

class ResetPassword extends Component {
    constructor() {
        super();

        this.state = {
            email: '',
            password: '',
            confirmPass: '',
            updated: false,
            isLoading: true,
            error: false,
            match: true,
        };
    }

    async componentDidMount() {
        const {
            match: {
                params: { token },
            },
        } = this.props;
        try {
            // This call verifies that the token was valid
            const response = await axios.get(`${url}/login/reset_password`, {
                params: {
                    resetPasswordToken: token,
                },
            });
            // console.log(response);
            if (response.data.message === 'password reset link a-ok') {
                this.setState({
                    email: response.data.email,
                    updated: false,
                    isLoading: false,
                    error: false,
            });
        }
        } catch (error) {
            console.log(error.response.data);
            this.setState({
                updated: false,
                isLoading: false,
                error: true,
            });
        }
    }

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    updatePassword = async (e) => {
        e.preventDefault();
        const { email, password, confirmPass } = this.state;
        const {
            match: {
                params: { token },
            },
        } = this.props;
        // check that the 2 password fields match
        if (password === confirmPass){
            try {
                const response = await axios.post(
                    `${url}/login/reset_password_via_email`,
                    {
                        email,
                        password,
                        resetPasswordToken: token,
                    },
                    {
                        headers: {
                            authorization: Cookies.get('currentUserToken')
                        }
                    }
                );
                if (response.status === 200) {
                    this.setState({
                        updated: true,
                        error: false,
                        match: true,
                    });
                } else {
                    this.setState({
                    updated: false,
                    error: true,
                    });
                }
            } catch (error) {
            console.log(error.response.data);
            }
        } else {
            this.setState({
                match: false
            })
        }
    };

    render() {
        const { classes } = this.props;
        const { password, error, isLoading, updated, confirmPass, match } = this.state;

        if (error) {
            return (
                <div>
                    <CenterBanner />
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <div className={classes.paper}>
                            <Typography component="h1" variant="h5">
                                Reset Failed
                            </Typography>
                            <div>
                                <h4>Problem resetting password. Please send another reset link.</h4>
                                <Link  href='/'>
                                    <Button variant="contained" color="primary" >
                                        Go Home
                                    </Button>
                                </Link>
                                <Link  href='/forgot_password'>
                                    <Button variant="contained" color="primary" >
                                        Forgot Password?
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Container>
                </div>
            );
        }
        if (isLoading) {
        return (
            <div>
                <CenterBanner />
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.loading}>
                        <Typography component="h1" variant="h5">
                            Loading User Data
                        </Typography>
                    </div>
                </Container>
            </div>
        );
        }
        return (
        <div>
            <CenterBanner />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Create New Password
                    </Typography>
                    <form className="password-form" onSubmit={this.updatePassword}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="password"
                            onChange={this.handleChange('password')}
                            value={password}
                            type="password"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="confirm_password"
                            label="confirm password"
                            onChange={this.handleChange('confirmPass')}
                            value={confirmPass}
                            type="password"
                        />
                         {!updated && match && <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Update Password
                        </Button>} 
                    </form>

                    {updated && match && (
                    <div>
                        <p>
                        Your password has been successfully reset, please try logging in
                        again.
                        </p>
                        <Link  href='/login'>
                            <Button variant="contained" color="primary" >
                                Login
                            </Button>
                        </Link>
                    </div>
                    )}
                    {!match && (
                        <div>
                            <p>
                            Your passwords don't match, please try again.
                            </p>
                        </div>
                    )}
                    {!updated && (<Link  href='/'>
                        <Button variant="contained" color="primary" >
                            Go Home
                        </Button>
                    </Link> )}
                </div>
            </Container>
        </div>
        );
    }
}

ResetPassword.propTypes = {
    // eslint-disable-next-line react/require-default-props
    match: PropTypes.shape({
        params: PropTypes.shape({
        token: PropTypes.string.isRequired,
        }),
    }),
};

export default withStyles(styles)(ResetPassword);