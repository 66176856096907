import React from "react";

export default function Counter(props) {
  const [counter, setCounter] = React.useState(3);

  React.useEffect(() => {
    counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
  }, [counter]);

  return (
    <div className="Counter">
      <div>{counter}</div>
    </div>
  );
}
