import { React } from "react";
import "./FormItem.css";
import Autocomplete from "../Autocomplete/Autocomplete";
import { List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import SimpleDeleteDialog from "../DeleteModal/SimpleDeleteDialog";

const FormItem = (props) => {
  let classes = "input";
  let boxStyle;
  if (props.extendedBox === true) classes = classes + " formItem_extendedBox";
  if (props.boxStyle) boxStyle = props.boxStyle;
  if (props.requiredField && props.afterCreate && props.value !== undefined && !props.value.toString().length) classes += " highlight-red";

  let selectOptions;
  if (props.listItems) {
    selectOptions = props.listItems.map((listItem) => {
      return <option value={listItem}>{listItem}</option>;
    });
  }

  let multiEntry;
  if (props.multiEntry) {
    multiEntry = (
      <List className={props.listClassname} dense={props.dense}>
        {/* List all the selected elements */}
        {props.listItems.map((row) => {
          return (
            <ListItem button onClick={props.listItemClick(row).bind()}>
              <ListItemText primary={row} />
              <ListItemSecondaryAction>
                {/* Delete icon functionality */}
                {props.form_disabled && (
                  <SimpleDeleteDialog
                    removeFunc={props.listItemDelete}
                    itemName={row}
                  />
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  }

  let textArea = (
    <div className="Column">
      <div className="box" style={boxStyle}>
        <div>{props.itemName}</div>
        <textarea
          className={classes}
          style={{ overflow: "auto" }}
          type={props.type === null ? "text" : props.type}
          id={props.id}
          value={props.value}
          onChange={
            props.handleChange
              ? props.handleChange(props.handle)
              : () => {
                  return;
                }
          }
        />
      </div>
    </div>
  );

  let basicInput = (
    <div className="Column">
      <div className="box">
        <div>{props.itemName}</div>
        <input
          className={classes}
          disabled={props.disabled}
          type={props.type === null ? "text" : props.type}
          id={props.id}
          value={props.value}
          checked={props.checked}
          onChange={
            props.onChange
              ? (props.handle ? props.onChange(props.handle) : props.onChange)
              : () => {}
          }
          list={props.list}
        />
        {props.showSuggestions && (
          <datalist id={props.list}> {selectOptions}</datalist>
        )}
      </div>
    </div>
  );

  let autoComplete = (
    <div className="Column">
      <Autocomplete
        suggestions={props.suggestions}
        form_disabled={props.form_disabled}
        name={props.name}
        requiredField={props.requiredField ? props.requiredField : false}
        afterCreate={props.afterCreate}
        activeSuggestion={props.activeSuggestion}
        filteredSuggestions={props.filteredSuggestions}
        showSuggestions={props.showSuggestions}
        userInput={props.userInput}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        onClick={props.onClick}
        userContacts={props.userContacts}
        handleDeleteClick={props.handleDeleteClick}
        contactActive={props.contactActive}
      >
        {props.name}
        </Autocomplete>
    </div>
  );

  let select = (
    <div className="Column">
      <div className="box" >
        <div>{props.itemName}</div>
        <select
          style={{backgroundColor: "white"}}
          className={classes}
          id={props.id}
          value={props.value}
          onChange={
            props.handleChange
              ? props.handleChange(props.handle)
              : () => {
                  return;
                }
          }
        >
          {selectOptions}
        </select>
      </div>
    </div>
  );

  let fileInput = (
    <div className="Column">
      <div className="box" >
        <div>{props.itemName}</div>
        <input
          style={{
            padding: '0.25rem',
          }}
          className={classes}
          type={props.type}
          id={props.id}
          onChange={props.onChange}
          multiple={props.multiple}
        />
        {props.multiEntry && multiEntry}
      </div>
    </div>
  );

  let finalOutput;
  if (props.itemType === "basicInput") finalOutput = basicInput;
  else if (props.itemType === "dropDown") finalOutput = select;
  else if (props.itemType === "autoComplete") finalOutput = autoComplete;
  else if (props.itemType === "fileInput") finalOutput = fileInput;
  else if (props.itemType === "textInput") finalOutput = textArea;
  else finalOutput = textArea;

  return finalOutput;
};

export default FormItem;
