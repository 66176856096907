import { React,useEffect, useState } from "react";
import SingleNavigationBar from "../components/SingleNavigationBar/SingleNavigationBar";
import FilterObj from "../components/Filter/FilterObj";
import { Redirect } from "react-router-dom";
import LoadingPage from "../components/LoadingPage/LoadingPage";
import { getCached, putCache } from "../caching/cacher";
import FormTable from '../shared_components/FormTable/FormTable';
import ApiService from '../services/apiService';

const LegalEntity = () => {

  const apiService = new ApiService(true); 
  const defaultFilterList = {
    0: new FilterObj("status", "Current", "E"),
    1: new FilterObj(),
  };

  const filterFields = [
        "code",
        "name",
        "start_date",
        "end_date",
        "contact",
        "contact_name",
        "property",
        "status",
  ];
  const dependentTables = ["PARTY", "PROPERTY", "CONTACT"];  
  const tableSorter="code";
  const tableDataElements=["name","property","start_date","end_date"];
  const pageBaseTag="/parties";
  const removeElementTag="/remove_party";
  const addNewElementTag="/add_entity";
  const pageName="Legal Documents";
  const headCells=[
    { id: 'name', numeric: false, disablePadding: true, label: 'Relevant Party Name' },
    { id: 'property', numeric: true, disablePadding: true, label: 'Property' },
    { id: 'start_date', numeric: true, disablePadding: true, label: 'Start Date' },
    { id: 'end_date', numeric: true, disablePadding: true, label: 'End Date' },
  ];
  const singleElementTag="/entity=";
  const showEl=["BulkUpload"];

  

  const [allRows,setAllRows] = useState([]);
  const [rows,setRows] = useState([]);
  const [displayRows,setDisplayRows] = useState([]);
  const [tablePage,setTablePage] = useState(0);
  const [filterList,setFilterList] = useState(defaultFilterList);
  const [filterCounter,setFilterCounter] = useState(1);
  const [modalOpen,setModalOpen] = useState(false);
  const [role,setRole] = useState('');
  const [disabled,setDisabled] = useState(true);
  const [redirect,setRedirect] = useState(false);
  const [loadingDialogOpen,setLoadingDialogOpen] = useState(true);

// this.baseUrl = `${url}/parties`;
// this.contactBaseUrl = `${url}/contacts`;
// this.userBaseUrl = `${url}/login`;
// this.propertyBaseUrl = `${url}/properties`;
// axios.defaults.headers.common["authorization"] = `${Cookies.get(
//   "currentUserToken"
// )} ${Cookies.get("device_mfa_key") ? Cookies.get("device_mfa_key") : ""}`;

  const dataCreator = (row) => {
    return {
      code:row.code,
      name:row.name,
      property:row.property,
      start_date:row.start_date,
      end_date:row.end_date,
    }

  }
  
  const states = {
    allRows,setAllRows,
    rows,setRows,
    displayRows,setDisplayRows,
    tablePage,setTablePage,
    filterList,setFilterList,
    filterCounter,setFilterCounter,
    modalOpen,setModalOpen,
    role,setRole,
    disabled,setDisabled,
    redirect,setRedirect,
    loadingDialogOpen,setLoadingDialogOpen,
    defaultFilterList,
    filterFields,
    dependentTables,
    dataCreator,
    tableSorter,
    tableDataElements,
    pageBaseTag,
    removeElementTag,
    addNewElementTag,
    pageName,
    headCells,
    singleElementTag,
    showEl,
}

  // Retrieve all content
  const retrieveContent = async (path,field, type) => {
    const [resp,error] = await apiService.get({
      path: path,
      dataType: type,      
    });
    let respMap = {};
    if (resp.data[0]){
      resp.data.forEach(item => {
        if (typeof(field) === 'object') {
          let tempMap = {};
          field.forEach(e => tempMap[e] = item[e]);
          respMap[item._id] = tempMap;
        } else {
          respMap[item._id] = item[field];
        }
      });
    }
    return respMap;
  };

  useEffect(() => {

    setLoadingDialogOpen(true); 
        
    const fetchData = async () => {
      const [response, error] = await apiService.get({
        path: '/authenticate',
        dataType: 'users',
      });
      if (error) setRedirect(true);
      else {
          let role = response.headers['role-data'];
          let disabled = role !== 'Admin';
          setRole(role);
          setDisabled(disabled);
      }

      const [up_to_date, cached_data] = await getCached(pageName);
      if(!up_to_date){

        const [entityResp,entityError] = await apiService.get({
          path:"/get_all_parties",
          dataType: 'entities',
        });

        const [contactMap, propMap] = await Promise.all([
          retrieveContent("/get_all_contacts", ['email', 'name'],"contacts"),
          retrieveContent("/get_all_properties", "name","properties"),
        ]);
      

        if (!error)  
        {
          let rows = [];

          for (let x in entityResp.data) {
            entityResp.data[x].contact_name = entityResp.data[x].contact.map(x => contactMap[x].name);
            entityResp.data[x].contact = entityResp.data[x].contact.map(x => contactMap[x].email);
            entityResp.data[x].property = propMap[entityResp.data[x].property];

            rows.push(
              dataCreator(entityResp.data[x])
            );
          }           
            // for (let data of propResp.data) {
            //   data.contact_name = data.contact.map(data => contactName[data]);
            //   data.contact = data.contact.map(data => contactMap[data]);
    
            //   rows.push(
            //     dataCreator(data)
            //   );
            // }

          putCache(pageName,dependentTables, {
              allRows: entityResp.data,
              rows: rows,
          });
          setAllRows(entityResp.data);
          setRows(rows);
          setDisplayRows(entityResp.data);
            
        }

      } else {
        if (cached_data) {
          setAllRows(cached_data.allRows);
          setRows(cached_data.rows);
          setDisplayRows(cached_data.allRows);
        }
      }
      
      setLoadingDialogOpen(false);
    };

    fetchData().catch(console.error);

    return () => {

    };

  },[]);


  if (redirect) {
    return <Redirect to="/login" />;
  }
  else return (
    <SingleNavigationBar
      content={
        <div>
          {loadingDialogOpen ? (
            <LoadingPage />
          ) : (
            <FormTable
              states={states}
            />
          )}
        </div>
      }
    />
  );
}

export default LegalEntity;
