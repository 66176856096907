import { React, Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { url } from "../config";
import Cookies from "js-cookie";
import "../components/FormItem/FormItem.css";
import SingleNavigationBar from "../components/SingleNavigationBar/SingleNavigationBar";
import ProfilePageLayout from "./ProfilePageLayout";

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_open: false,
      role: "",
      disabled: true,
      redirect: false,
      user: {},
      totalReportsCompleted: 0,
      totalReportsDue: 0,
      showResetModal: false,
      resetSuccess: false,
      old_password: '',
      new1_password: '',
      new2_password: '',
      match: true,
      err_msg: '',

    };
    this.userBaseUrl = `${url}/login`;
    this.reportInstanceBaseUrl = `${url}/report_instances`;
    axios.defaults.headers.common["authorization"] = `${Cookies.get(
      "currentUserToken"
    )} ${Cookies.get("device_mfa_key") ? Cookies.get("device_mfa_key") : ""}`;
  }

  async componentDidMount() {
    try {
      const auth = await axios.get(this.userBaseUrl + "/authenticate");
      const role = auth.headers["role-data"];
      const disabled = role !== "Admin";
      this.setState({ role: role, disabled: disabled });
    } catch (err) {
      this.setState({ redirect: true });
    }

    try {
      const userInfo = await axios.get(this.userBaseUrl + "/get_one_by_id", {
        params: {
          _id: Cookies.get("user_id"),
        },
      });
      const totalReportsCompleted = await axios.get(this.reportInstanceBaseUrl + '/get_all_completed_by_user', {
          params: {
              email: userInfo.data.email
          }
      });
      const totalReportsDue = await axios.get(this.reportInstanceBaseUrl + '/get_all_incompleted_by_user', {
        params: {
            email: userInfo.data.email
        }
    });
      this.setState({ user: userInfo.data, totalReportsCompleted: totalReportsCompleted.data.length, totalReportsDue: totalReportsDue.data.length });
    } catch (err) {}
  }

    // When reset password is pressed, show the confirmation modal
    toggleResetModal = () => {
        this.setState({showResetModal: !this.state.showResetModal, resetSuccess: false, old_password: '', new1_password: '', new2_password: '', err_msg: '', match: true});
    };

  //Handle reset user password clicked
  handleResetClick = async() => {
        // check that the 2 password fields match
        if (this.state.new1_password === this.state.new2_password){
            try {
                const result = await axios.post(this.userBaseUrl + '/user_update_password',
                    {
                        email: this.state.user.email,
                        old_password: this.state.old_password,
                        new_password: this.state.new1_password,
                    });
                    console.log(result.data.message)
                    this.setState({
                        resetSuccess: true,
                        err_msg: '',
                        match: true,
                    });
            } catch (error) {
                console.log(error.response.data.message)
                this.setState({
                    resetSuccess: false,
                    err_msg: error.response.data.message,
                });
            }
        } else {
            this.setState({
                match: false,
            })
        }
  };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }

    return (
      <SingleNavigationBar
        content = {<ProfilePageLayout 
                user={this.state.user}
                totalReportsCompleted={this.state.totalReportsCompleted}
                totalReportsDue={this.state.totalReportsDue}
                handleResetClick={this.handleResetClick}
                toggleResetModal={this.toggleResetModal}
                showResetModal={this.state.showResetModal}
                resetSuccess={this.state.resetSuccess}
                old_password={this.state.old_password}
                new1_password={this.state.new1_password}
                new2_password={this.state.new2_password}
                handleChange={this.handleChange}
                err_msg={this.state.err_msg}
                match={this.state.match}
            />}
      />
    );
  }
}

export default ProfilePage;
