import { React, Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import NavigationIcon from "@material-ui/icons/Navigation";
import CreateIcon from "@material-ui/icons/Create";
import { Divider } from "@material-ui/core";
import FormItem from "../../../components/FormItem/FormItem";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from "@mui/material";

const useStyles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      flexGrow: 1,
    },
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(0),
    bottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 300,
  },
  hidden: {
    display: "none",
  },
  show: {
    display: "",
  },
});

class ReportInstanceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newInstance:
        window.location.pathname.includes("add_report_instance") === true,
      editModeActive:
        window.location.pathname.includes("add_report_instance") === true,
    };
  }

  editFormHandler = (event) => {
    this.setState({
      editModeActive: !this.state.editModeActive,
    });
  };

  render() {
    const showBasic =
      !this.state.editModeActive &&
      !this.props.form_disabled &&
      !this.state.newInstance;
    const showUpdate =
      this.state.editModeActive &&
      !this.props.form_disabled &&
      !this.state.newInstance;

    return (
      <div>
        <div className="flex-row">
          {!this.state.newInstance ? (
            <h2 style={{maxWidth: "70rem", overflow: 'clip',}}>{this.props.property + " - " + this.props.type}</h2>
          ) : (
            <h2>New Report</h2>
          )}
          {showUpdate && (
            <div className="flex-row">
              <button 
                className={"flex-row button"} 
                onClick={this.props.toggle}
                style={{marginLeft: '20px', marginRight: '5px'}}
              >
                <NavigationIcon style={{ color: "rgb(63, 133, 240)" }} />
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    // color: "rgb(63, 133, 240)",
                  }}
                >
                  Update
                </div>
              </button>
              <div style={{ marginRight: "10px" }}></div>
              <button
                className={"flex-row button"}
                style={{ border: "2px solid red", marginRight: '10px' }}
                onClick={this.editFormHandler}
              >
                <CreateIcon style={{ color: "red" }} />
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    // color: "rgb(63, 133, 240)",
                  }}
                >
                  Edit
                </div>
              </button>
            </div>
          )}
          {showBasic && (
            <button
              className={"flex-row button"}
              onClick={this.editFormHandler}
              style={{marginLeft: '20px', marginRight: '10px'}}
            >
              <CreateIcon style={{ color: "rgb(63, 133, 240)" }} />
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  // color: "rgb(63, 133, 240)",
                }}
              >
                Edit
              </div>
            </button>
          )}
          {this.state.newInstance && (
            <button 
              className={"flex-row button"}
              onClick={this.props.toggle}
              style={{marginLeft: '20px', marginRight: '5px'}}
            >
              <NavigationIcon style={{ color: "rgb(63, 133, 240)" }} />
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  // color: "rgb(63, 133, 240)",
                }}
              >
                Create
              </div>
            </button>
          )}
        </div>
        <Divider />
        <form autoComplete="off">
          <fieldset disabled={!this.state.editModeActive}>
            <div className="Row">
              <div className="Column">
                <div className="box">
                  <FormItem
                    disabled= {this.props.role !== "Admin"}
                    itemName="Due Date"
                    itemType="basicInput"
                    requiredField={true}
                    afterCreate={this.props.afterCreate}
                    id="due_date"
                    type="date"
                    value={this.props.date}
                    onChange={this.props.handleChange}
                    handle="date"
                  />
                </div>
                {this.props.completed && (
                  <div className="box" style={{ paddingTop: "10px" }} disabled>
                    <FormItem
                      itemName="Last Updated"
                      itemType="basicInput"
                      id="updatedAt"
                      type="text"
                      value={this.props.updatedAt}
                      onChange={null}
                    />
                  </div>
                )}
                <div className="box" style={{ paddingTop: "10px" }} disabled>
                    <FormItem
                      itemName="Report Description"
                      id="reportDescription"
                      value={this.props.reportDescription}
                      extendedBox={true}
                      onChange={null}
                    />
                </div>
              </div>
              <Stack>
                <div className="box">
                <FormItem
                  itemType="fileInput"
                  itemName="Attachments"
                  type="file"
                  id="entity-attachment"
                  onChange={this.props.onFileChange}
                  multiple="multiple"
                  multiEntry={true}
                  listClassname="formItem_list"
                  listItems={this.props.fileNames}
                  listItemClick={this.props.handleDocClick}
                  listItemDelete={this.props.handleRemoveDoc}
                  form_disabled={this.state.editModeActive}
                  dense={true}
                />
                </div>
                <div className="box">
                <FormItem
                  itemType="autoComplete"
                  suggestions={this.props.assigneeSuggestions}
                  name={"Assignees"}
                  requiredField={true}
                  afterCreate={this.props.afterCreate}
                  form_disabled={!this.state.editModeActive}
                  activeSuggestion={this.props.assigneeActiveSuggestion}
                  filteredSuggestions={this.props.assigneeFilteredSuggestions}
                  showSuggestions={this.props.assigneeShowSuggestions}
                  userInput={this.props.userInput}
                  onChange={this.props.onAssigneeChange}
                  onKeyDown={this.props.onAssigneeKeyDown}
                  onClick={this.props.onAssigneeClick}
                  userContacts={this.props.assignees}
                  contactActive={true}
                  handleDeleteClick={this.props.handleDeleteClick}
                /></div>
              </Stack>
              <div className="Column"> 
                <FormItem
                  itemType="basicInput"
                  itemName="Completed?"
                  type="checkbox"
                  checked={this.props.completed}
                  onChange={this.props.handleCheckboxChange}
                />
                {/* <div className="Column">
                  <div className="box">
                    <div>Completed?</div>
                    <input
                      type="checkbox"
                      id="checkCompleted"
                      checked={this.props.completed}
                      onChange={
                        this.props.handleCheckboxChange
                      }
                    />
        
                    </div>
                </div> */}
                {this.props.completed && (
                  <div className="box" style={{ paddingTop: "10px" }} disabled>
                    <FormItem
                      itemName="Completed By"
                      itemType="basicInput"
                      id="completed_by"
                      type="text"
                      value={this.props.completed_by}
                      onChange={null}
                    />
                  </div>
                )}
                <div className="box" disabled>
                    <FormItem
                      itemName="Email Receivers"
                      id="email-receivers"
                      value={this.props.receiverList.join("\n")}
                      extendedBox={true}
                      onChange={null}
                    />
                  </div>
                </div>
            </div>
          </fieldset>
        </form>
        {this.props.loading && <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>}
        <ConfirmationModal
          handleUpdate={this.props.onUpdateClick}
          toggle={this.props.toggle}
          show={this.props.showModal}
          success={this.props.success}
          handleClose={this.props.handleClose}
          create={this.state.newInstance}
          handleCreate={this.props.onCreateClick}
          err_msg={this.props.err_msg}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(ReportInstanceForm);
