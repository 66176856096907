import { React, Component } from "react";
import ContactForm from "./ContactForm";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { url } from "../../../config";
import Cookies from "js-cookie";
import { getCached } from "../../../caching/cacher";

class ContactFormHandler extends Component {
  constructor(props) {
    super(props);
    this.contactsBaseUrl = `${url}/contacts`;
    this.userBaseUrl = `${url}/login`;
    this.state = {
      name: "",
      _id: '',
      address: "",
      email: "",
      status: "Current",
      afterCreate: false,
      phone: 0,
      phone2: 0,
      role: "",
      user_role: "",
      emailUserInput: "",
      phoneUserInput: "",
      send_reports_to: "Yes",
      disabled: true,
      redirect: false,
      showModal: false,
      success: false,
      goBack: false,
      invalid: false,
      err_msg: "",
      found_msg: "",
    };
    axios.defaults.headers.common["authorization"] = `${Cookies.get(
      "currentUserToken"
    )} ${Cookies.get("device_mfa_key") ? Cookies.get("device_mfa_key") : ""}`;
  }

  // On component load, get the specific contact details
  componentDidMount() {
    axios
      .get(this.userBaseUrl + "/authenticate")
      .then((res) => {
        let role = res.headers["role-data"];
        let disabled = role !== "Admin";
        this.setState({ user_role: role, disabled: disabled });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ redirect: true });
      });
    let _id = window.location.pathname.split("=")[1];
    if (_id) {
      axios
        .get(this.contactsBaseUrl + "/get_one_by_id", {
          params: {
            _id: _id,
          },
        })
        .then((result) => {
          this.setState({
            name: result.data.name,
            address: result.data.address,
            email: result.data.email,
            status: result.data.status,
            phone: result.data.phone,
            phone2: result.data.phone2,
            role: result.data.role,
            send_reports_to: result.data.send_reports_to,
            _id: result.data._id,
          });
          this.setState({ err_msg: "" });
        })
        .catch((err) => {
          this.setState({ err_msg: err.response.data.message });
        });
    }
  }

  // When update is pressed, show the confirmation modal
  toggle = async () => {
    const [up_to_date, cached_data] = await getCached("Contacts");
      if (cached_data.rows.filter(e => e.email === this.state.email).length > 0) {
        this.setState({found_msg: `A contact with the email ${this.state.email} already exists. Do you want to overwrite?`});
      }
    this.setState({ showModal: !this.state.showModal, err_msg: "", success: false });
  };

  handleClose = () => {
    if (window.location.pathname.includes("add_contact") === true) {
      this.setState({
        showModal: false,
        success: false,
        goBack: true,
        err_msg: "",
        found_msg: '',
      });
    } else {
      this.setState({
        showModal: false,
        success: false,
        err_msg: "",
        found_msg: '',
      });
    }
  };

  // When the user updates the contact info, save that data
  onUpdateClick = (e) => {
    e.preventDefault();
    if (!this.state.name ||
      !this.state.email ||
      !this.state.role ||
      !this.state.send_reports_to ||
      !this.state.status) {
      this.setState({err_msg: "Fill in the missing fields highlighted in red.", afterCreate: true});
      return;
    }
    if (!this.state.invalid) {
      axios
        .patch(this.contactsBaseUrl + "/update_contact", {
          _id: this.state._id,
          name: this.state.name,
          address: this.state.address,
          email: this.state.email,
          status: this.state.status,
          phone: this.state.phone,
          phone2: this.state.phone2,
          role: this.state.role,
          send_reports_to: this.state.send_reports_to,
        })
        .then((result) => {
          this.setState({ success: true, err_msg: "" });
        })
        .catch((err) => {
          this.setState({ err_msg: err.response.data.message });
        });
    }
  };

  // When a new contact is created, send the data to the backend
  onCreateClick = (e) => {
    e.preventDefault();
    if (!this.state.name ||
      !this.state.email ||
      !this.state.role ||
      !this.state.send_reports_to ||
      !this.state.status) {
      this.setState({err_msg: "Fill in the missing fields highlighted in red.", afterCreate: true});
      return;
    }
    if (!this.state.invalid) {
      axios
        .post(this.contactsBaseUrl + "/add_contact", {
          name: this.state.name,
          address: this.state.address,
          email: this.state.email,
          status: this.state.status,
          phone: this.state.phone,
          phone2: this.state.phone2,
          role: this.state.role,
          send_reports_to: this.state.send_reports_to,
        })
        .then((result) => {
          this.setState({ success: true, err_msg: "" });
        })
        .catch((err) => {
          this.setState({ err_msg: err.response.data.message });
        });
    }
  };

  handleChange = (name) => (event) => {
    if (name === "email") {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // Check that email fits the proper format
      if (re.test(event.target.value)) {
        this.setState({ invalid: false });
      }
      // If email format is incorrect, don't allow update or create
      else {
        this.setState({ invalid: true });
      }
    }
    
    if (name.includes("phone")) {
      event.target.value = event.target.value.replace(
        /[.,\/#!$%\^&\*;:{}=\-_`~()" "A-Za-z]/g,
        ""
      );
    }

    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    if (this.state.goBack) {
      return <Redirect to="/contacts" />;
    }
    return (
      <ContactForm
        name={this.state.name}
        address={this.state.address}
        email={this.state.email}
        status={this.state.status}
        phone={this.state.phone}
        phon2e={this.state.phone2}
        role={this.state.role}
        send_reports_to={this.state.send_reports_to}
        handleChange={this.handleChange}
        onUpdateClick={this.onUpdateClick}
        onCreateClick={this.onCreateClick}
        disabled={this.state.disabled}
        user_role={this.state.user_role}
        toggle={this.toggle}
        showModal={this.state.showModal}
        success={this.state.success}
        handleClose={this.handleClose}
        err_msg={this.state.err_msg}
        found_msg={this.state.found_msg}
        afterCreate={this.state.afterCreate}
      />
    );
  }
}

export default ContactFormHandler;
