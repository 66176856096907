import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ReactCodeInput from 'react-code-input';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const MFAForm = (props) => {
    const classes = useStyles();
    const [emailSent, setEmailSent] = React.useState(true);
    useEffect (() => {
        setTimeout(() => setEmailSent(false), 120000);
    });

    const handleNewCode = () => {
        setEmailSent(true);
        props.handleEmailNewCode();
        setTimeout(() => setEmailSent(false), 120000);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Enter Authentication Code
                </Typography>              
                <form className={classes.form} onSubmit={props.validateUser}>
                    <div style={{textAlign: 'center'}}>
                        {props.err_msg ?
                            <p style={{color: 'red'}}>
                                {props.err_msg}
                            </p>
                        :
                            <p>
                                {props.message}
                            </p>
                        }
                        <ReactCodeInput
                            type='text'
                            fields={6}
                            autoFocus
                            onChange={props.handleChange} 
                            value={props.mfa_token}
                            inputStyle={{
                                textAlign: 'center',
                                fontFamily: 'monospace',
                                margin:  '4px',
                                MozAppearance: 'textfield',
                                width: '55px',
                                fontSize: '32px',
                                height: '75px',
                                paddingLeft: '7px',
                                border: '1px solid rgb(63, 133, 240)',
                                borderRadius: '12px',
                            }}
                        />
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Authenticate
                    </Button>
                    {!emailSent && <a onClick={handleNewCode} href='#'>
                        Send new code
                    </a>}
                </form>
            </div>
        </Container>
    );
}

export default MFAForm;