import axios from 'axios';
import Cookies from 'js-cookie';
import { url, basePaths } from "../config";

export default class ApiService {
    constructor(auth = false, baseUrl = url){
        this.baseUrl = baseUrl;
        if (auth){
            axios.defaults.headers.common["authorization"] = `${Cookies.get(
                "currentUserToken"
              )} ${Cookies.get("device_mfa_key") ? Cookies.get("device_mfa_key") : ""}`;
        }
    }

    /*
    * For the following 4 functions, the inputs and outputs are defined as follows:
    *
    * @param dataType:  The backend table we are sending the data to. The options are defined in config.js
    * @param path:      The path for getting, updating, creating or deleting
    * @param data:      The data to send to the backend
    *
    * @return result:   The data retrieved from backend or the error
    */
    async post({path, data = {}, dataType = ''}) {
        try {
            const rootPath = dataType ? basePaths[dataType] : "";
            const results = await axios.post(`${this.baseUrl}${rootPath}${path}`, data);
            return [results, null];
        } catch(error){
            return [null, error];
        }
    }

    async patch({path, data = {}, dataType = ''}) {
        try {
            const rootPath = dataType ? basePaths[dataType] : "";
            const results = await axios.patch(`${this.baseUrl}/${rootPath}${path}`, data);
            return [results, null];
        } catch(error){
            return [null, error];
        }
    }

    async get({path, data = {}, dataType = ''}) {
        try {
            const rootPath = dataType ? basePaths[dataType] : "";
            const results = await axios.get(`${this.baseUrl}/${rootPath}${path}`, {params: data});
            return [results, null];
        } catch(error){
            return [null, error];
        }
    }

    async delete({path, data = {}, dataType = ''}) {
        try {
            const rootPath = dataType ? basePaths[dataType] : dataType;
            const results = await axios.delete(`${this.baseUrl}/${rootPath}${path}`, {data: data});
            return [results, null];
        } catch(error){
            return [null, error];
        }
    }
}