import { React,useEffect,useState } from "react";
import SingleNavigationBar from "../components/SingleNavigationBar/SingleNavigationBar";
import { Redirect } from "react-router-dom";
import LoadingPage from "../components/LoadingPage/LoadingPage";
import { getCached, putCache } from "../caching/cacher";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Settings from './Settings/Settings';
import FormTable from '../shared_components/FormTable/FormTable';
import ApiService from '../services/apiService';
import FilterObj from "../components/Filter/FilterObj";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const MyAccount = () => {

  const apiService = new ApiService(true);  
  const defaultFilterList = {
    0: new FilterObj(),
  };


  const filterFields = [
      "name",
      "email",
      "role",
      "title",
  ];
  const dependentTables = ["USER"];
  const tableSorter="email";
  const tableDataElements=["name","email","role"];
  const pageBaseTag="/login";
  const removeElementTag="/remove_user";
  const addNewElementTag="/add_user";
  const pageName="Users";
  const headCells=[
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'email',
            numeric: true,
            disablePadding: true,
            label: 'Email',
        },
        {
            id: 'role',
            numeric: true,
            disablePadding: true,
            label: 'Role',
        },
    ]
  const singleElementTag="/user=";
  const showEl=["BulkUpload"];

  
  const [allRows,setAllRows] = useState([]);
  const [rows,setRows] = useState([]);
  const [displayRows,setDisplayRows] = useState([]);
  const [tablePage,setTablePage] = useState(0);
  const [filterList,setFilterList] = useState(defaultFilterList);
  const [filterCounter,setFilterCounter] = useState(0);
  const [modalOpen,setModalOpen] = useState(false);
  const [role,setRole] = useState('');
  const [disabled,setDisabled] = useState(true);
  const [redirect,setRedirect] = useState(false);
  const [loadingDialogOpen,setLoadingDialogOpen] = useState(true);
  const [tabPage,setTabPage] = useState(0);

  const dataCreator = (row) => {
    return {
      name:row.name,
      email:row.email,
      role:row.role,
    }

  }  
  
  const states = {
        allRows,setAllRows,
        rows,setRows,
        displayRows,setDisplayRows,
        tablePage,setTablePage,
        filterList,setFilterList,
        filterCounter,setFilterCounter,
        modalOpen,setModalOpen,
        role,setRole,
        disabled,setDisabled,
        redirect,setRedirect,
        loadingDialogOpen,setLoadingDialogOpen,
        defaultFilterList,
        filterFields,
        dependentTables,
        dataCreator,
        tableSorter,
        tableDataElements,
        pageBaseTag,
        removeElementTag,
        addNewElementTag,
        pageName,
        headCells,
        singleElementTag,
        showEl,
    }

  // Retrieve all content
  const retrieveContent = async (path,field, type) => {
    const [resp,error] = await apiService.get({
      path: path,
      dataType: type,      
    });
    let respMap = {};
    if (resp.data[0]){
      resp.data.forEach(item => {
        if (typeof(field) === 'object') {
          let tempMap = {};
          field.forEach(e => tempMap[e] = item[e]);
          respMap[item._id] = tempMap;
        } else {
          respMap[item._id] = item[field];
        }
      });
    }
    return respMap;
  };

  useEffect(() => {
    
    setLoadingDialogOpen(true); 

    const fetchData = async () => {
        const [response, error] = await apiService.get({
            path: '/authenticate',
            dataType: 'users',
        });
        if (error) setRedirect(true);
        else {
            let role = response.headers['role-data'];
            let disabled = role !== 'Admin';

            if( role !== 'Admin') setRedirect(true);

            setRole(role);
            setDisabled(disabled);
        }

        
      const [up_to_date, cached_data] = await getCached(pageName);
      if(!up_to_date){
        
        const [userResp,userError] = await apiService.get({
            path:"/get_all_users",
            dataType: 'users',
        });

        if (!error)  
        {
            let rows = [];
            for(let x in userResp.data){
                rows.push(dataCreator(userResp.data[x]));
            }
            
            putCache(pageName,dependentTables, {
                allRows: userResp.data,
                rows: rows,
            });
            setAllRows(userResp.data);
            setRows(rows);
            setDisplayRows(userResp.data);
            
        }

      } 
      else {
        if (cached_data) {
            setAllRows(cached_data.allRows);
            setRows(cached_data.rows);
            setDisplayRows(cached_data.allRows);
        }
      }
        
      setLoadingDialogOpen(false);
    };

    
    fetchData().catch(console.error);

    return () => {

    };
        
  },[]);

  if (redirect) {
    return <Redirect to="/login" />;
  }
  else return (
    <SingleNavigationBar
      content={
        <div>
            <Tabs value={tabPage} onChange={(event, newValue) => setTabPage(newValue)} aria-label="basic tabs example">
                <Tab label="Users" {...a11yProps(0)} />
                <Tab label="Settings" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabPage} index={0}>
                {loadingDialogOpen ? (
                    <LoadingPage />
                ) : (
                    <FormTable
                    states={states}
                    />
                )}
            </TabPanel>
            <TabPanel value={tabPage} index={1}>
              <Settings/>
            </TabPanel>
        </div>
      }
    />
  );

}

export default MyAccount;