import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function LoadingPage() {
  return (
    // <div>
    //   <script src="https://cdnjs.cloudflare.com/ajax/libs/react/15.4.1/react.js"></script>
    //   <script src="https://cdnjs.cloudflare.com/ajax/libs/react/15.4.1/react-dom.js"></script>

    //   <div class="sk-cube-grid">
    //     <div class="sk-cube sk-cube1"></div>
    //     <div class="sk-cube sk-cube2"></div>
    //     <div class="sk-cube sk-cube3"></div>
    //     <div class="sk-cube sk-cube4"></div>
    //     <div class="sk-cube sk-cube5"></div>
    //     <div class="sk-cube sk-cube6"></div>
    //     <div class="sk-cube sk-cube7"></div>
    //     <div class="sk-cube sk-cube8"></div>
    //     <div class="sk-cube sk-cube9"></div>
    //   </div>
    // </div>
    // <div className="loader center">
    //   <i className="fa fa-spinner fa-spin" style={{ color: "blue" }} />
    // </div>
    <div
      //   className="center"
      style={{
        textAlign: "center",
        verticalAlign: "middle",
        alignItems: "center",
      }}
    >
      <CircularProgress
        size="50px"
        style={{ marginTop: "40vh", color: "#3F85F0" }}
      />
    </div>
  );
}
