import { React, Component } from "react";
import { withRouter } from "react-router";
import ReportInstanceForm from "./ReportInstanceForm";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { url } from "../../../config";
import Cookies from 'js-cookie';

class ReportInstanceFormHandler extends Component {
  constructor(props) {
    super(props);
    this.reportInstanceBaseUrl = `${url}/report_instances`;
    this.reportBaseUrl = `${url}/reports`;
    this.entityBaseUrl = `${url}/parties`;
    this.contactsBaseUrl = `${url}/contacts`;
    this.docBaseUrl = `${url}/docs`;
    this.userBaseUrl = `${url}/login`;
    this.state = {
      date: "",
      completed: false,
      completed_by: "",
      attachments: [],
      updatedAt: "",
      disabled: true,
      name: "",
      redirect: false,
      type: "",
      afterCreate: false,
      property: "",
      assignees: [],
      reportId: "",
      showModal: false,
      success: false,
      fileNames: [],
      instanceId: "",
      entity: "",
      role: "",
      err_msg: '',
      reportDescription: "",
      receiverList: [],
      loading: false,
      assigneeSuggestions: [],
      assigneeActiveSuggestion: 0,
      assigneeFilteredSuggestions: [],
      assigneeShowSuggestions: true,
      userInput: '',
    };
    axios.defaults.headers.common["authorization"] =
      `${Cookies.get("currentUserToken")} ${Cookies.get("device_mfa_key")?Cookies.get("device_mfa_key"):''}`;
  }

  // On component load, get the specific contact details
  async componentDidMount() {
    try{
        const auth = await axios.get(this.userBaseUrl + "/authenticate");
        const role = auth.headers["role-data"];
        const disabled = role !== "Admin" && role !== "User";
        this.setState({ role: role, disabled: disabled, name: auth.headers["name"], });
    }
    catch (err) {
        console.log(err);
        this.setState({ redirect: true });
    }
    let _id = window.location.pathname.split("=")[1];
    const userResponse = await axios.get(this.userBaseUrl + "/get_all_users");
    const users = userResponse.data.map(item => item.name);

    if (_id !== null && _id !== undefined) {
        this.setState({ instanceId: _id });
        const instanceResp = await axios.get(this.reportInstanceBaseUrl + "/get_one", {
            params: {_id: _id},
        });
        let lastUpdated = new Date(instanceResp.data.updatedAt);
        let lastUpdatedReadable = lastUpdated.toLocaleDateString("en-US");
        let due_date = new Date(instanceResp.data.date);
        let due_date_readable = due_date.toISOString().substring(0, 10);
        let reportDescription = "";
        let receiverList = [];
        let disabled = instanceResp.data.completed? 
            instanceResp.headers["role-data"] !== "Admin" : 
            instanceResp.headers["role-data"] !== "Admin" && instanceResp.headers["role-data"] !== "User";
        let reportResp;
        try {
          reportResp = await axios.get(this.reportBaseUrl + "/get_one", {
            params: {_id: instanceResp.data.reportId},
          });
        } catch (error) {
          console.log(error)
        }
        if (reportResp) {
          const partyId = reportResp.data.relevant_party;
          reportDescription = reportResp.data.desc_type === 'Yardi'? reportResp.data.yardi_report_desc : reportResp.data.report_detail_desc
          let entityResp;
          try {
            entityResp = await axios.get(this.entityBaseUrl + "/get_one_by_id", {
              params: {_id: partyId},
            });
          } catch (error) {
            console.log(error)
          }
          if (entityResp){
            let contactResp;
            try {
              contactResp = await axios.get(this.contactsBaseUrl + "/get_list_contacts", {
                params: {contact: entityResp.data.contact},
              });
            } catch (error) {
              console.log(error)
            }
            if (contactResp){
              contactResp.data.forEach(element => {
                receiverList.push(`${element.name} <${element.email}>`)
              });
            }
          }
        }
        this.setState({
            date: due_date_readable,
            completed: instanceResp.data.completed,
            completed_by: instanceResp.data.completed_by,
            updatedAt: lastUpdatedReadable,
            fileNames: instanceResp.data.attachments,
            reportId: instanceResp.data.reportId,
            property: instanceResp.data.property,
            type: instanceResp.data.type,
            assignees: instanceResp.data.assignees,
            entity: instanceResp.data.party,
            receiverList: receiverList,
            reportDescription: reportDescription,
            disabled: disabled,
            assigneeSuggestions: users,
        });
    } else {
      this.setState({
        type: this.props.location.state.type,
        property: this.props.location.state.property,
        assignees: this.props.location.state.assignees,
        reportId: this.props.location.state.reportId,
        entity: this.props.location.state.entity,
        assigneeSuggestions: users,
      });
    }
  }

  // When the user updates the contact info, save that data
  onUpdateClick = (e) => {
    e.preventDefault();

    if (!this.state.date) {
      this.setState({err_msg: "Fill in the missing fields highlighted in red.", afterCreate: true});
      return;
    }

    const { attachments } = this.state;

    const formData = new FormData();

    let fileNames = [...this.state.fileNames];
    let newFileNames = [];

    for (let x = 0; x < attachments.length; x++) {
      formData.append("file", attachments[x]);
      fileNames.push(attachments[x].name);
      newFileNames.push(attachments[x].name);
    }

    this.setState({ fileNames: fileNames });
    axios
      .post(this.docBaseUrl + "/upload", formData, {
        headers: {
          names: newFileNames.join(','),
          path: `report-instances/${this.state.type}/${this.state.property}/${this.state.entity}`,
          'Content-Type': 'multipart/form-data',
        },
      })
    .then((result) => { 
        axios
          .patch(this.reportInstanceBaseUrl + "/update", {
            _id: this.state.instanceId,
            date: this.state.date.replaceAll("-", "/"),
            completed: this.state.completed,
            completed_by: this.state.completed_by,
            attachments: fileNames,
          })
          .then((res) => {
            this.setState({ 
                success: true,
                disabled: this.state.completed? this.state.role !== "Admin": this.state.role !== "Admin" && this.state.role !== "User",
                err_msg: '',
            });
          })
          .catch((err) => this.setState({err_msg: err.response.data.message}));
      })
      .catch((err) => {
        this.setState({err_msg: err.response.data.message});
      });
  };

  // When a new instance is created, send the data to the backend
  onCreateClick = (e) => {
    e.preventDefault();

    if (!this.state.date) {
      this.setState({err_msg: "Fill in the missing fields highlighted in red.", afterCreate: true});
      return;
    }

    const { attachments } = this.state;

    const formData = new FormData();

    let fileNames = [...this.state.fileNames];
    let newFileNames = [];

    for (let x = 0; x < attachments.length; x++) {
      formData.append("file", attachments[x]);
      fileNames.push(attachments[x].name);
      newFileNames.push(attachments[x].name);
    }

    this.setState({ fileNames: fileNames });
    axios
      .post(this.docBaseUrl + "/upload", formData, {
        headers: {
          names: fileNames.join(','),
          path: `report-instances/${this.state.type}/${this.state.property}/${this.state.entity}`,
          'Content-Type': 'multipart/form-data',
        },
      })
    .then((result) => {
        axios
          .post(this.reportInstanceBaseUrl + "/add", {
            _id: this.state.reportId,
            date: this.state.date.replaceAll("-", "/"),
            completed: this.state.completed,
            completed_by: this.state.completed_by,
            attachments: fileNames,
            type: this.state.type,
            property: this.state.property,
            assignees: this.state.assignees,
            party: this.state.entity,
          })
          .then((res) => {
            this.setState({ 
                success: true,
                disabled: this.state.completed? this.state.role !== "Admin": this.state.role !== "Admin" && this.state.role !== "User",
                err_msg: '',
            });
          })
          .catch((err) => this.setState({err_msg: err.response.data.message}));
      })
      .catch((err) => {
        this.setState({err_msg: err.response.data.message});
      });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheckboxChange = (e) => {
    this.setState({ completed: !this.state.completed });
    if (e.target.checked) {
      this.setState({ completed_by: this.state.name });
    } else {
      this.setState({ completed_by: "" });
    }
  };

  // When update is pressed, show the confirmation modal
  toggle = () => {
    this.setState({ showModal: !this.state.showModal, err_msg: "", success: false});
  };

  handleClose = () => {
    this.setState({
      showModal: false,
      success: false,
      err_msg: '',
    });
    if (window.location.pathname.includes("add_report_instance") === true){
      this.props.history.goBack();
    }
  };

    

  // On file select (from the pop up)
  onFileChange = (event) => {
    this.setState({ attachments: event.target.files });
  };

  removeDocFromReportInstanceHelper = (fileName) => {
    let newFileNames = [...this.state.fileNames];
    const index = this.state.fileNames.indexOf(fileName);
    if (index > -1) {
      newFileNames.splice(index, 1);
      axios
        .patch(this.reportInstanceBaseUrl + "/update", {
          _id: this.state.instanceId,
          attachments: newFileNames,
        })
        .then((response) => {
          this.setState({ fileNames: newFileNames });
        })
        .catch((err) => console.log(err));
    }
  };

  handleRemoveDoc = (e) => {
    const val = e.currentTarget.value;
    axios
      .post(this.docBaseUrl + "/delete",
        {
          fileName: val,
          code: `${this.state.type}/${this.state.property}/${this.state.entity}`,
          folder: "report-instances"
      })
      .then((res) => {
        this.removeDocFromReportInstanceHelper(val);
      })
      .catch((err) => console.log(err));
  };

  handleDocClick = (name) => (e) => {
    if (!this.state.loading){
      this.setState({loading: true})
      axios
        .get(this.docBaseUrl + "/download", {
          params: {
            fileName: name,
            code: `${this.state.type}/${this.state.property}/${this.state.entity}`,
            folder: "report-instances"
          },
          responseType: "blob",
        })
        .then((response) => {
          // then print response status
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          const fileName = name;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
          this.setState({loading: false})
        })
        .catch((err) => {
          console.log(err);
          this.removeDocFromReportInstanceHelper(name);
          this.setState({loading: false})
        });
      }
  };

  // As the user types the assignee name, update the suggestions to show
  onAssigneeChange = (e) => {
    const userInput = e.currentTarget.value;

    const assigneeFilteredSuggestions = this.state.assigneeSuggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      assigneeActiveSuggestion: 0,
      assigneeFilteredSuggestions,
      assigneeShowSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  // handle an assignee being clicked from the suggestion list
  onAssigneeClick = (e) => {
    let newAssignees = [...this.state.assignees];
    if (!newAssignees.includes(e.currentTarget.innerText)) {
      newAssignees.push(e.currentTarget.innerText);
      this.setState({
        assigneeActiveSuggestion: 0,
        assigneeFilteredSuggestions: [],
        assigneeShowSuggestions: false,
        userInput: "",
        assignees: newAssignees,
      });
    }
  };

  // Handle up/down and enter keys being used while assignee suggestion list is open
  onAssigneeKeyDown = (e) => {
    const { assigneeActiveSuggestion, assigneeFilteredSuggestions } = this.state;
    // Enter key
    if (e.keyCode === 13) {
      let newAssignees = [...this.state.assignees];
      if (
        !newAssignees.includes(
          assigneeFilteredSuggestions[assigneeActiveSuggestion]
        ) &&
        assigneeFilteredSuggestions[assigneeActiveSuggestion]
      ) {
        newAssignees.push(assigneeFilteredSuggestions[assigneeActiveSuggestion]);
        this.setState({
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: "",
          assignees: newAssignees,
        });
      }
    }
    // Up key
    else if (e.keyCode === 38) {
      if (assigneeActiveSuggestion === 0) {
        this.setState({assigneeActiveSuggestion: assigneeFilteredSuggestions.length - 1 })
        return;
      }
      this.setState({ assigneeActiveSuggestion: assigneeActiveSuggestion - 1 });
    }
    // Down key
    else if (e.keyCode === 40) {
      if (assigneeActiveSuggestion + 1 === assigneeFilteredSuggestions.length) {
        this.setState({assigneeActiveSuggestion: 0 })
        return;
      }
      this.setState({ assigneeActiveSuggestion: assigneeActiveSuggestion + 1 });
    }
  };

  // Handle the removal of an assignee from the assignee field
  handleDeleteClick = (e) => {
    let userAssignees = [...this.state.assignees];
    const index = this.state.assignees.indexOf(e.currentTarget.value);
    if (index > -1) {
      userAssignees.splice(index, 1);
      this.setState({ assignees: userAssignees });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    return (
      <ReportInstanceForm
        date={this.state.date}
        completed={this.state.completed}
        completed_by={this.state.completed_by}
        attachments={this.state.attachments}
        updatedAt={this.state.updatedAt}
        handleChange={this.handleChange}
        onUpdateClick={this.onUpdateClick}
        onCreateClick={this.onCreateClick}
        handleCheckboxChange={this.handleCheckboxChange}
        form_disabled={this.state.disabled}
        property={this.state.property}
        type={this.state.type}
        toggle={this.toggle}
        showModal={this.state.showModal}
        success={this.state.success}
        handleClose={this.handleClose}
        fileNames={this.state.fileNames}
        attachment={this.state.attachments}
        onFileChange={this.onFileChange}
        handleRemoveDoc={this.handleRemoveDoc}
        handleDocClick={this.handleDocClick}
        err_msg={this.state.err_msg}
        afterCreate={this.state.afterCreate}
        role={this.state.role}
        receiverList={this.state.receiverList}
        reportDescription={this.state.reportDescription}
        loading={this.state.loading}
        assignees={this.state.assignees}
        assigneeSuggestions={this.state.assigneeSuggestions}
        assigneeActiveSuggestion={this.state.assigneeActiveSuggestion}
        assigneeFilteredSuggestions={this.state.assigneeFilteredSuggestions}
        assigneeShowSuggestions={this.state.assigneeShowSuggestions}
        userInput={this.state.userInput}
        onAssigneeChange={this.onAssigneeChange}
        onAssigneeClick={this.onAssigneeClick}
        onAssigneeKeyDown={this.onAssigneeKeyDown}
        handleDeleteClick={this.handleDeleteClick}
      />
    );
  }
}

export default withRouter(ReportInstanceFormHandler);
