import * as React from 'react';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button } from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Assessment,
  Apartment,
  Contacts,
  AccountCircle,
  AccountBalance,
} from "@material-ui/icons";
import "./SingleNavigationBar.css";
import Cookies from "js-cookie";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const MenuItems = (props) => {
  const [open, setOpen] = React.useState(sessionStorage.getItem('open')=== 'true');

  const handleDrawerOpen = () => {
    setOpen(true);
    sessionStorage.setItem('open', true);
  };;

  const handleDrawerClose = () => {
    setOpen(false);
    sessionStorage.setItem('open', false);
  };

  const openItems = [
    {
      href: "/home",
      icon: <DashboardIcon />,
      title: "Dashboard",
    },
    {
      href: "/reports",
      icon: <Assessment />,
      title: "Reports",
    },
    {
      href: "/entities",
      icon: <AccountBalance />,
      title: "Legal Documents",
    },
    {
      href: "/properties",
      icon: <Apartment />,
      title: "Properties",
    },
    {
      href: "/contacts",
      icon: <Contacts />,
      title: "Contacts",
    },
    {
      href: "/users",
      icon: <AccountCircle />,
      title: "Admin Settings",
    },
  ];

  const name = Cookies.get("name");
  const role = Cookies.get("role");
  let filteredItems;
  if (role !== "Admin") {
    filteredItems = openItems.slice(0, 5);
  } else filteredItems = openItems;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer 
        variant="permanent" 
        open={open}
        className="nav-drawer"
        classes={{
          paper: props.classes.drawerPaper,
        }}
      >
        <DrawerHeader >
          <Toolbar style={{justifyContent: 'space-between',width:'100%',padding: '0'}}>
            <div onClick={handleDrawerClose} style={{paddingLeft:'8px', overflow: 'clip' , display: open ? '' : 'none'}}>
              <div style={{fontSize:'30px'}}>{name.split(' ')[0]}</div>
            </div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{
                marginRight: '-8px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            
            <IconButton onClick={handleDrawerClose} 
            sx={{
                ...(!open && { display: 'none' }),
              }}>
              <MenuOpenIcon />      
            </IconButton>
          </Toolbar>          
        </DrawerHeader>
        <Divider />
        <div>
          <List>
            {filteredItems.map((item, index) => (
              <ListItem component={Link} to={item.href} key={item.href}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            ))}
          </List>
        </div>
       {open && <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "background.default",
          flexDirection: "column",
          p: 4,
        }}
      >
        <Button
          color="primary"
          component={Link}
          to="/help"
          key="/help"
          variant="contained"
        >
          Need Support?
        </Button>
      </Box>}
    </Drawer>
    </Box>
  );
};

export default MenuItems;
