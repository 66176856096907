import { React, Component } from 'react';
import UserFormHandler from './UserForm/UserFormHandler';
import SingleNavigationBar from '../../components/SingleNavigationBar/SingleNavigationBar';

class UserPage extends Component {

    render() {
        return(
            <SingleNavigationBar content={
                <UserFormHandler/>
            } />
        );
    };
}

export default UserPage;