import { React, Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { url } from "../config";
import Cookies from "js-cookie";
import "../components/FormItem/FormItem.css";
import SingleNavigationBar from "../components/SingleNavigationBar/SingleNavigationBar";
import HelpPageFormHandler from "./HelpPageFormHandler";

class HelpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_open: false,
      role: "",
      disabled: true,
      redirect: false,
      user_email: "NULL",
    };
    this.userBaseUrl = `${url}/login`;
    axios.defaults.headers.common["authorization"] = `${Cookies.get(
      "currentUserToken"
    )} ${Cookies.get("device_mfa_key") ? Cookies.get("device_mfa_key") : ""}`;
  }

  async componentDidMount() {
    try {
      const auth = await axios.get(this.userBaseUrl + "/authenticate");
      const role = auth.headers["role-data"];
      const disabled = role !== "Admin";
      this.setState({ role: role, disabled: disabled });
    } catch (err) {
      console.log(err);
      this.setState({ redirect: true });
    }

    try {
      const userInfo = await axios.get(this.userBaseUrl + "/get_one_by_id", {
        params: {
          _id: Cookies.get("user_id"),
        },
      });
      this.setState({ user_email: userInfo.data["email"] });
    } catch (err) {}
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    return (
      <SingleNavigationBar
        content={<HelpPageFormHandler user_email={this.state.user_email} />}
      />
    );
  }
}

export default HelpPage;
