import { React, useState } from "react";
import SingleNavigationBar from "../components/SingleNavigationBar/SingleNavigationBar";
import FilterObj from "../components/Filter/FilterObj";
import { Redirect } from "react-router-dom";
import LoadingPage from "../components/LoadingPage/LoadingPage";
import { getCached, putCache } from "../caching/cacher";
import FormTable from '../shared_components/FormTable/FormTable';
import ApiService from '../services/apiService';
import { useEffect } from 'react';

const Property = () => {

  const apiService = new ApiService(true);
  const defaultFilterList = {
      0: new FilterObj('status', 'Current', 'E'),
      1: new FilterObj(),
  };
  const dependentTables = ["PROPERTY", "CONTACT"];
  const tableSorter="yardi_property_code";
  const tableDataElements=["yardi_property_code","name","address","owner"];
  const filterFields= [
    "yardi_property_code",
    "name",
    "address",
    "owner",
    "contact",
    "contact_name",
    "property_type",
    "year_end",
    "unit_count",
    "hud_program",
    "tax_credits",
    "layered",
    "status",
  ];
  const pageBaseTag="/properties";
  const removeElementTag="/remove_property";
  const addNewElementTag="/add_property";
  const pageName="Properties";
  const headCells=[
  { id: 'yardi_property_code', numeric: false, disablePadding: true, label: 'Yardi Property Code' },
  { id: 'name', numeric: true, disablePadding: true, label: 'Property Name' },
  { id: 'address', numeric: true, disablePadding: true, label: 'Property Address' },
  { id: 'owner', numeric: true, disablePadding: true, label: 'Property Owner' },
  ];
  const singleElementTag="/property=";
  const showEl=["BulkUpload"];

  const [allRows,setAllRows] = useState([]);
  const [rows,setRows] = useState([]);
  const [displayRows,setDisplayRows] = useState([]);
  const [tablePage,setTablePage] = useState(0);
  const [filterList,setFilterList] = useState(defaultFilterList);
  const [filterCounter,setFilterCounter] = useState(1);
  const [modalOpen,setModalOpen] = useState(false);
  const [role,setRole] = useState('');
  const [disabled,setDisabled] = useState(true);
  const [redirect,setRedirect] = useState(false);
  const [loadingDialogOpen,setLoadingDialogOpen] = useState(true);

  const dataCreator = (row) => {
    return {
        yardi_property_code:row.yardi_property_code,
        name:row.name,
        address:row.address,
        owner:row.owner,
    }
  }
   
  const states = {
    allRows,setAllRows,
    rows,setRows,
    displayRows,setDisplayRows,
    tablePage,setTablePage,
    filterList,setFilterList,
    filterCounter,setFilterCounter,
    modalOpen,setModalOpen,
    role,setRole,
    disabled,setDisabled,
    redirect,setRedirect,
    loadingDialogOpen,setLoadingDialogOpen,
    defaultFilterList,
    filterFields,
    dependentTables,
    dataCreator,
    tableSorter,
    tableDataElements,
    pageBaseTag,
    removeElementTag,
    addNewElementTag,
    pageName,
    headCells,
    singleElementTag,
    showEl,
  }

  // Retrieve all content
  const retrieveContent = async (path,field, type) => {
    const [resp,error] = await apiService.get({
      path: path,
      dataType: type,      
    });
    let respMap = {};
    if (resp.data[0]){
      resp.data.forEach(item => {
        if (typeof(field) === 'object') {
          let tempMap = {};
          field.forEach(e => tempMap[e] = item[e]);
          respMap[item._id] = tempMap;
        } else {
          respMap[item._id] = item[field];
        }
      });
    }
    return respMap;
  };

  useEffect(() => {
    setLoadingDialogOpen(true); 
    
    const fetchData = async () => {
      const [response, error] = await apiService.get({
          path: '/authenticate',
          dataType: 'users',
      });
      if (error) setRedirect(true);
      else {
          let role = response.headers['role-data'];
          let disabled = role !== 'Admin';
          setRole(role);
          setDisabled(disabled);
      }
      
      const [up_to_date, cached_data] = await getCached(pageName);
      if (!up_to_date) {

          const contactMap = await Promise.all([
            retrieveContent('/get_all_contacts',['email','name'],'contacts')
          ]);
          
          const [propResp, propError] = await apiService.get({
            path: '/get_all_properties',
            dataType: 'properties',
          });
          if (!error)  
          {
              let rows = [];
              for (let data of propResp.data) {
                data.contact_name = data.contact.map(data => contactMap[data].name);
                data.contact = data.contact.map(data => contactMap[data].email);
      
                rows.push(
                  dataCreator(data)
                );
              }
              putCache(pageName,dependentTables, {
                  allRows: propResp.data,
                  rows: rows,
              });
              setAllRows(propResp.data);
              setRows(rows);
              setDisplayRows(propResp.data);
              
          }
      } else {
          if (cached_data) {                    
              setAllRows(cached_data.allRows);
              setRows(cached_data.rows);
              setDisplayRows(cached_data.allRows); 
          }
      }
                   
      setLoadingDialogOpen(false);
    };
 
    
    fetchData().catch(console.error);

    return () => {
    };

  },[]);

  if (redirect) {
    return <Redirect to="/login" />;
  }
  else return (
    <SingleNavigationBar
      content={
        <div>
          {loadingDialogOpen ? (
            <LoadingPage />
          ) : (
            <FormTable
              states={states}
            />
          )}
        </div>
      }
    />
  );
}

export default Property;
