export default class FilterObj {

    // Filter type C: contains, E: exact, default to contains.
    constructor(ff, fs, ft) {
        this.filter_field = (ff===undefined) ? "" : ff;
        this.filter_string = (fs===undefined) ? "" : fs;
        if (["C", "E"].includes(ft)) {
            this.filter_type = ft;
        } else {
            this.filter_type = "C";
        }
    }

    get filterField() {
        return this.filter_field;
    }

    get filterString() {
        return this.filter_string;
    }

    get filterType() {
        return this.filter_type;
    }

    set setFilterField(ff) {
        this.filter_field = ff;
    }

    set setFilterString(fs) {
        this.filter_string = fs;
    }

    set setFilterType(ft) {
        if (["C", "E"].includes(ft)) {
            this.filter_type = ft;
        }
    }

}