import * as XLSX from "xlsx";

function createHeaderRange(l)
{
    const alpha = ['Z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 
                   'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W','X', 'Y'];
    let refRange = "A1:";
    let rightRange = '';
    let curr = l;
    while (curr > 0)
    {
        rightRange = alpha[curr%26] + rightRange;
        curr = Math.floor(curr/26);
    }
    refRange += rightRange + '1';
    return refRange;
}

export function createSheet(data)
{
    const key_list = Object.keys(data[0]);
    let data_list = [];
    data_list.push(key_list);
    for (let d of data) 
    {
        let item = [];
        for (const k of key_list) {
            if (d.hasOwnProperty(k)) {
                item.push(d[k]);
            } else {
                item.push("");
            }
        }
        data_list.push(item);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(data_list);
    worksheet["!autofilter"] = { ref: createHeaderRange(key_list.length) };
    return worksheet;
}

export function createAndWriteWorkBook(worksheets_obj, filename)
{
    const new_workbook = XLSX.utils.book_new();
    for (const k in worksheets_obj)
    {
        XLSX.utils.book_append_sheet(new_workbook, worksheets_obj[k], k);
    }
    XLSX.writeFile(new_workbook, filename);
}