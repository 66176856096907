import { React, Component } from "react";
import ReportForm from "./ReportForm";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { url } from "../../../config";
import Cookies from "js-cookie";
import {getAndDeleteCached, putCacheAndOpenPage} from "../../../caching/clone"

class ReportFormHandler extends Component {
  constructor(props) {
    super(props);
    this.userBaseUrl = `${url}/login`;
    this.propertyBaseUrl = `${url}/properties`;
    this.reportBaseUrl = `${url}/reports`;
    this.instanceBaseUrl = `${url}/report_instances`;
    this.partyBaseUrl = `${url}/parties`;
    this.docBaseUrl = `${url}/docs`;
    this.state = {
      contactActiveSuggestion: 0,
      contactFilteredSuggestions: [],
      contactShowSuggestions: false,
      contactUserInput: "",
      propertyUserInput: "",
      propertyActiveSuggestion: 0,
      propertyFilteredSuggestions: [],
      propertyShowSuggestions: false,
      entityUserInput: "",
      entityActiveSuggestion: 0,
      entityFilteredSuggestions: [],
      entityShowSuggestions: false,
      relevant_party: "",
      relevant_party_id: "",
      property: "",
      property_id: "",
      attachment: [],
      _id: "",
      type: "",
      due_date_type: "Month End",
      number_of_months: 0,
      number_of_days: 0,
      fixed_date: "",
      fixed: false,
      afterCreate: false,
      annually: "Yes",
      desc_type: "Yardi",
      yardi_report_desc: "",
      report_detail_desc: "",
      responsible_party: [],
      form_disabled: this.props.disabled,
      contactSuggestions: [],
      propertySuggestions: [],
      entitySuggestions: [],
      fileNames: [],
      newFileNames: [],
      redirect: false,
      instances: [],
      userContacts: [],
      showModal: false,
      success: false,
      goBack: false,
      err_msg: "",
      loading: false,
    };
    axios.defaults.headers.common["authorization"] = `${Cookies.get(
      "currentUserToken"
    )} ${Cookies.get("device_mfa_key") ? Cookies.get("device_mfa_key") : ""}`;
  }

  prePopulateData(result_data) {
    if (result_data.due_date_type === "Fixed Date") {
      this.setState({ fixed: true });
    }
    // Get the contacts for that specific report
    axios
      .get(this.userBaseUrl + "/get_list_users", {
        params: {
          users: result_data.responsible_party,
        },
      })
      .then((res) => {
        // Set the contact name
        this.setState({ userContacts: res.data.map((row) => `${row.name} <${row.email}>`) });
      })
      .catch((err) => {
        console.log(err);
      });

    // Get property name
    axios
      .get(this.propertyBaseUrl + "/get_one_by_id", {
        params: {
          _id: result_data.property,
        },
      })
      .then((res) => {
        // Set the property name
        this.setState({
          property: res.data.name,
          propertyUserInput: res.data.name,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    // Get entity name
    axios
      .get(this.partyBaseUrl + "/get_one_by_id", {
        params: {
          _id: result_data.relevant_party,
        },
      })
      .then((res) => {
        // Set the entity name
        this.setState({
          relevant_party: res.data.code,
          entityUserInput: res.data.code,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // On component load, get the specific property details
  componentDidMount() {
    axios
      .get(this.userBaseUrl + "/authenticate")
      .then((res) => {
        let role = res.headers["role-data"];
        let disabled = role !== "Admin";
        this.setState({ form_disabled: disabled });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ redirect: true });
      });
    let code = window.location.pathname.split("=")[1];
    if (code) {
      axios
        .get(this.reportBaseUrl + "/get_one", {
          params: {
            _id: code,
          },
        })
        .then((result) => {
          let fixed_date;
          if (result.data.fixed_date)
            fixed_date = new Date(result.data.fixed_date)
                  .toISOString()
                  .substring(0, 10);
          this.setState({
            _id: result.data._id,
            type: result.data.type,
            due_date_type: result.data.due_date_type,
            number_of_months: result.data.number_of_months,
            number_of_days: result.data.number_of_days,
            fixed_date: fixed_date,
            annually: result.data.annually,
            desc_type: result.data.desc_type,
            yardi_report_desc: result.data.yardi_report_desc,
            report_detail_desc: result.data.report_detail_desc,
            fileNames: result.data.attachment,
            instances: result.data.instances,
            responsible_party: result.data.responsible_party,
            relevant_party_id: result.data.relevant_party,
            property_id: result.data.property,
          });
          this.prePopulateData(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else if (window.location.hash) {
      let cacheID = window.location.hash.split("#")[1];
      let temp_data = getAndDeleteCached(cacheID, "REPORT");
      if (temp_data) {
        this.setState({
          type: temp_data.type,
          due_date_type: temp_data.due_date_type,
          number_of_months: temp_data.number_of_months,
          number_of_days: temp_data.number_of_days,
          fixed_date: temp_data.fixed_date,
          annually: temp_data.annually,
          desc_type: temp_data.desc_type,
          yardi_report_desc: temp_data.yardi_report_desc,
          report_detail_desc: temp_data.report_detail_desc,
          responsible_party: temp_data.responsible_party,
        });
        this.prePopulateData(temp_data);
      }
    }
    // Get all contacts for the autocomplete feature to prepopulate the field
    let contactSuggestions = [];
    axios
      .get(this.userBaseUrl + "/get_all_users")
      .then((result) => {
        for (let i in result.data) {
          contactSuggestions.push(`${result.data[i].name} <${result.data[i].email}>`);
        }
        this.setState({ contactSuggestions: contactSuggestions });
      })
      .catch((err) => {
        console.log(err);
      });

    // Get all properties for the autocomplete feature to prepopulate the field
    let propertySuggestions = [];
    axios
      .get(this.propertyBaseUrl + "/get_all_properties")
      .then((result) => {
        for (let i in result.data) {
          propertySuggestions.push(result.data[i].name);
        }
        this.setState({ propertySuggestions: propertySuggestions });
      })
      .catch((err) => {
        console.log(err);
      });

    // Get all entities for the autocomplete feature to prepopulate the field
    let entitySuggestions = [];
    axios
      .get(this.partyBaseUrl + "/get_all_parties")
      .then((result) => {
        for (let i in result.data) {
          entitySuggestions.push(result.data[i].code);
        }
        this.setState({
          entitySuggestions: entitySuggestions,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // When update is pressed, show the confirmation modal
  toggle = () => {
    this.setState({ showModal: !this.state.showModal, err_msg: "", success: false });
  };

  handleClose = () => {
    if (window.location.pathname.includes("add_report") === true) {
      this.setState({
        showModal: false,
        success: false,
        goBack: true,
        err_msg: "",
      });
    } else {
      this.setState({
        showModal: false,
        success: false,
        err_msg: "",
      });
    }
  };

  // When the user updates the report info, save that data
  onUpdateClick = async (e) => {
    e.preventDefault();

    if (!this.state.entityUserInput ||
      !this.state.propertyUserInput ||
      !this.state.type ||
      !this.state.due_date_type ||
      !this.state.desc_type ||
      !this.state.userContacts.length) {
        this.setState({err_msg: "Fill in the missing fields highlighted in red.", afterCreate: true});
        return;
    }

    const { attachment } = this.state;

    const formData = new FormData();

    let fileNames = [...this.state.fileNames];
    let newFileNames = [];

    for (let x = 0; x < attachment.length; x++) {
      formData.append("file", attachment[x]);
      fileNames.push(attachment[x].name);
      newFileNames.push(attachment[x].name);
    }

    this.setState({ fileNames: fileNames });
    try {      
      await axios.post(this.docBaseUrl + "/upload", formData, {
        headers: {
          names: newFileNames.join(","),
          path: `reports/${this.state.type}/${this.state.propertyUserInput}/${this.state.entityUserInput}`,
          "Content-Type": "multipart/form-data",
        },
      });
      await axios.patch(
        this.reportBaseUrl + "/update_report",
        {
          _id: this.state._id,
          relevant_party: this.state.entityUserInput,
          property: this.state.propertyUserInput,
          type: this.state.type,
          due_date_type: this.state.due_date_type,
          number_of_months: this.state.number_of_months,
          number_of_days: this.state.number_of_days,
          fixed_date: this.state.fixed_date,
          annually: this.state.annually,
          desc_type: this.state.desc_type,
          yardi_report_desc: this.state.yardi_report_desc,
          report_detail_desc: this.state.report_detail_desc,
          responsible_party: this.state.userContacts
                .map(item => item.substring(item.indexOf('<') + 1, item.indexOf('>'))),
          attachment: fileNames,
        }
      );
      this.setState({ success: true, err_msg: "" });
    } catch (err) {
      this.setState({ err_msg: err.response.data.message });
    }
  };

  // When a new property is created, send the data to the backend
  onCreateClick = async (e) => {
    e.preventDefault();

    if (!this.state.entityUserInput ||
      !this.state.propertyUserInput ||
      !this.state.type ||
      !this.state.due_date_type ||
      !this.state.desc_type ||
      !this.state.userContacts.length) {
        this.setState({err_msg: "Fill in the missing fields highlighted in red.", afterCreate: true});
        return;
    }

    const { attachment } = this.state;

    const formData = new FormData();

    let fileNames = [...this.state.fileNames];
    let newFileNames = [];

    for (let x = 0; x < attachment.length; x++) {
      formData.append("file", attachment[x]);
      fileNames.push(attachment[x].name);
      newFileNames.push(attachment[x].name);
    }

    this.setState({ fileNames: fileNames });
    try {
      await axios.post(this.docBaseUrl + "/upload", formData, {
        headers: {
          names: fileNames.join(","),
          path: `reports/${this.state.type}/${this.state.propertyUserInput}/${this.state.entityUserInput}`,
          "Content-Type": "multipart/form-data",
        },
      });
      await axios.post(this.reportBaseUrl + "/add_report", {
        relevant_party: this.state.entityUserInput,
        property: this.state.propertyUserInput,
        type: this.state.type,
        due_date_type: this.state.due_date_type,
        number_of_months: this.state.number_of_months,
        number_of_days: this.state.number_of_days,
        fixed_date: this.state.fixed_date,
        annually: this.state.annually,
        desc_type: this.state.desc_type,
        yardi_report_desc: this.state.yardi_report_desc,
        report_detail_desc: this.state.report_detail_desc,
        responsible_party: this.state.userContacts
              .map(item => item.substring(item.indexOf('<') + 1, item.indexOf('>'))),
        attachment: fileNames,
      });
      this.setState({ success: true, err_msg: "" });
    } catch (err) {
      this.setState({ err_msg: err.response.data.message });
    }
  };

  // Handle the removal of a contact from the contact field
  handleDeleteClick = (e) => {
    let userContacts = [...this.state.userContacts];
    const index = this.state.userContacts.indexOf(e.currentTarget.value);
    if (index > -1) {
      userContacts.splice(index, 1);
      this.setState({ userContacts: userContacts });
    }
  };

  cloneHandler = (e) => {
    putCacheAndOpenPage(this.state._id,"REPORT",{
      type: this.state.type,
      property: this.state.property_id,
      relevant_party: this.state.relevant_party_id,
      due_date_type: this.state.due_date_type,
      number_of_months: this.state.number_of_months,
      number_of_days: this.state.number_of_days,
      fixed_date: this.state.fixed_date,
      annually: this.state.annually,
      desc_type: this.state.desc_type,
      yardi_report_desc: this.state.yardi_report_desc,
      report_detail_desc: this.state.report_detail_desc,
      responsible_party: this.state.responsible_party,
    }, "/add_report")
  }

  // As the user types the contact name, update the suggestions to show
  onContactChange = (e) => {
    const userInput = e.currentTarget.value;

    const contactFilteredSuggestions = this.state.contactSuggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      contactActiveSuggestion: 0,
      contactFilteredSuggestions,
      contactShowSuggestions: true,
      contactUserInput: e.currentTarget.value,
    });
  };

  // As the user types the property name, update the suggestions to show
  onPropertyChange = (e) => {
    const userInput = e.currentTarget.value;

    const propertyFilteredSuggestions = this.state.propertySuggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      propertyActiveSuggestion: 0,
      propertyFilteredSuggestions,
      propertyShowSuggestions: true,
      propertyUserInput: e.currentTarget.value,
    });
  };

  // As the user types the entity name, update the suggestions to show
  onEntityChange = (e) => {
    const userInput = e.currentTarget.value;

    const entityFilteredSuggestions = this.state.entitySuggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      entityActiveSuggestion: 0,
      entityFilteredSuggestions,
      entityShowSuggestions: true,
      entityUserInput: e.currentTarget.value,
    });
  };

  // handle a contact being clicked from the suggestion list
  onContactClick = (e) => {
    let newContacts = [...this.state.userContacts];
    if (!newContacts.includes(e.currentTarget.innerText)) {
      newContacts.push(e.currentTarget.innerText);
      this.setState({
        contactActiveSuggestion: 0,
        contactFilteredSuggestions: [],
        contactShowSuggestions: false,
        contactUserInput: "",
        userContacts: newContacts,
      });
    }
  };

  // handle a property being clicked from the suggestion list
  onPropertyClick = (e) => {
    this.setState({
      propertyActiveSuggestion: 0,
      propertyFilteredSuggestions: [],
      propertyShowSuggestions: false,
      propertyUserInput: e.currentTarget.innerText,
      property: e.currentTarget.innerText,
    });
  };

  // handle an entity being clicked from the suggestion list
  onEntityClick = (e) => {
    this.setState({
      entityActiveSuggestion: 0,
      entityFilteredSuggestions: [],
      entityShowSuggestions: false,
      entityUserInput: e.currentTarget.innerText,
      relevant_party: e.currentTarget.innerText,
    });
  };

  // Handle up/down and enter keys being used while contact suggestion list is open
  onContactKeyDown = (e) => {
    const { contactActiveSuggestion, contactFilteredSuggestions } = this.state;
    // Enter key
    if (e.keyCode === 13) {
      let newContacts = [...this.state.userContacts];
      if (
        !newContacts.includes(
          contactFilteredSuggestions[contactActiveSuggestion]
        ) &&
        contactFilteredSuggestions[contactActiveSuggestion]
      ) {
        newContacts.push(contactFilteredSuggestions[contactActiveSuggestion]);
        this.setState({
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: "",
          userContacts: newContacts,
        });
      }
    }
    // Up key
    else if (e.keyCode === 38) {
      if (contactActiveSuggestion === 0) {
        this.setState({contactActiveSuggestion: contactFilteredSuggestions.length - 1 })
        return;
      }
      this.setState({ contactActiveSuggestion: contactActiveSuggestion - 1 });
    }
    // Down key
    else if (e.keyCode === 40) {
      if (contactActiveSuggestion + 1 === contactFilteredSuggestions.length) {
        this.setState({contactActiveSuggestion: 0 })
        return;
      }
      this.setState({ contactActiveSuggestion: contactActiveSuggestion + 1 });
    }
  };

  // Handle up/down and enter keys being used while property suggestion list is open
  onPropertyKeyDown = (e) => {
    const { propertyActiveSuggestion, propertyFilteredSuggestions } =
      this.state;
    // Enter key
    if (e.keyCode === 13) {
      if (propertyFilteredSuggestions[propertyActiveSuggestion]) {
        this.setState({
          propertyActiveSuggestion: 0,
          propertyFilteredSuggestions,
          propertyShowSuggestions: false,
          propertyUserInput:
            propertyFilteredSuggestions[propertyActiveSuggestion],
        });
      }
    }
    // Up key
    else if (e.keyCode === 38) {
      if (propertyActiveSuggestion === 0) {
        this.setState({ propertyActiveSuggestion: propertyFilteredSuggestions.length - 1 });
        return;
      }
      this.setState({ propertyActiveSuggestion: propertyActiveSuggestion - 1 });
    }
    // Down key
    else if (e.keyCode === 40) {
      if (propertyActiveSuggestion + 1 === propertyFilteredSuggestions.length) {
        this.setState({ propertyActiveSuggestion: 0 });
        return;
      }
      this.setState({ propertyActiveSuggestion: propertyActiveSuggestion + 1 });
    }
  };

  // Handle up/down and enter keys being used while entity suggestion list is open
  onEntityKeyDown = (e) => {
    const { entityActiveSuggestion, entityFilteredSuggestions } = this.state;
    // Enter key
    if (e.keyCode === 13) {
      if (entityFilteredSuggestions[entityActiveSuggestion]) {
        this.setState({
          entityActiveSuggestion: 0,
          entityFilteredSuggestions,
          entityShowSuggestions: false,
          entityUserInput: entityFilteredSuggestions[entityActiveSuggestion],
        });
      }
    }
    // Up key
    else if (e.keyCode === 38) {
      if (entityActiveSuggestion === 0) {
        this.setState({ entityActiveSuggestion: entityFilteredSuggestions.length - 1 });
        return;
      }
      this.setState({ entityActiveSuggestion: entityActiveSuggestion - 1 });
    }
    // Down key
    else if (e.keyCode === 40) {
      if (entityActiveSuggestion + 1 === entityFilteredSuggestions.length) {
        this.setState({ entityActiveSuggestion: 0 });
        return;
      }
      this.setState({ entityActiveSuggestion: entityActiveSuggestion + 1 });
    }
  };

  handleChange = (name) => (event) => {
    let fixed = false;
    if (name === "due_date_type") { 
      if (event.target.value === "Fixed Date") {
        fixed = true;
      }
    }
    this.setState({
      [name]: event.target.value, 
      fixed: fixed,
    });
  };

  // On file select (from the pop up)
  onFileChange = (event) => {
    this.setState({ attachment: event.target.files });
  };

  removeDocFromReportHelper = (fileName) => {
    let newFileNames = [...this.state.fileNames];
    const index = this.state.fileNames.indexOf(fileName);
    if (index > -1) {
      newFileNames.splice(index, 1);
      axios
        .patch(this.reportBaseUrl + "/update_report", {
          _id: this.state._id,
          attachment: newFileNames,
        })
        .then((response) => {
          this.setState({ fileNames: newFileNames });
        })
        .catch((err) => console.log(err));
    }
  };

  handleRemoveDoc = (e) => {
    const val = e.currentTarget.value;
    axios
      .post(this.docBaseUrl + "/delete", {
        fileName: val,
        code: `${this.state.type}/${this.state.propertyUserInput}/${this.state.entityUserInput}`,
        folder: "reports",
      })
      .then((res) => {
        this.removeDocFromReportHelper(val);
      })
      .catch((err) => console.log(err));
  };

  handleDocClick = (name) => (e) => {
    if (!this.state.loading){
      this.setState({loading: true});
      axios
        .get(this.docBaseUrl + "/download", {
          params: {
            fileName: name,
            code: `${this.state.type}/${this.state.propertyUserInput}/${this.state.entityUserInput}`,
            folder: "reports",
          },
          responseType: "blob",
        })
        .then((response) => {
          // then print response status
          console.log(response);
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          const fileName = name;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
          this.setState({loading: false});
        })
        .catch((err) => {
          console.log(err);
          this.removeDocFromReportHelper(name);
          this.setState({loading: false});
        });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    if (this.state.goBack) {
      return <Redirect to="/reports" />;
    }
    return (
      <ReportForm
        form_disabled={this.state.form_disabled}
        relevant_party={this.state.relevant_party}
        property={this.state.property}
        type={this.state.type}
        due_date_type={this.state.due_date_type}
        number_of_months={this.state.number_of_months}
        number_of_days={this.state.number_of_days}
        fixed_date={this.state.fixed_date}
        fixed={this.state.fixed}
        desc_type={this.state.desc_type}
        annually={this.state.annually}
        yardi_report_desc={this.state.yardi_report_desc}
        report_detail_desc={this.state.report_detail_desc}
        responsible_party={this.state.responsible_party}
        onContactChange={this.onContactChange}
        onEntityChange={this.onEntityChange}
        onPropertyChange={this.onPropertyChange}
        onContactClick={this.onContactClick}
        onEntityClick={this.onEntityClick}
        onPropertyClick={this.onPropertyClick}
        onContactKeyDown={this.onContactKeyDown}
        onEntityKeyDown={this.onEntityKeyDown}
        onPropertyKeyDown={this.onPropertyKeyDown}
        handleChange={this.handleChange}
        onUpdateClick={this.onUpdateClick}
        onCreateClick={this.onCreateClick}
        handleDeleteClick={this.handleDeleteClick}
        contactActiveSuggestion={this.state.contactActiveSuggestion}
        contactFilteredSuggestions={this.state.contactFilteredSuggestions}
        contactShowSuggestions={this.state.contactShowSuggestions}
        contactUserInput={this.state.contactUserInput}
        propertyUserInput={this.state.propertyUserInput}
        propertyActiveSuggestion={this.state.propertyActiveSuggestion}
        propertyFilteredSuggestions={this.state.propertyFilteredSuggestions}
        propertyShowSuggestions={this.state.propertyShowSuggestions}
        entityUserInput={this.state.entityUserInput}
        entityActiveSuggestion={this.state.entityActiveSuggestion}
        entityFilteredSuggestions={this.state.entityFilteredSuggestions}
        entityShowSuggestions={this.state.entityShowSuggestions}
        contactSuggestions={this.state.contactSuggestions}
        propertySuggestions={this.state.propertySuggestions}
        entitySuggestions={this.state.entitySuggestions}
        _id={this.state._id}
        cloneHandler={this.cloneHandler}
        assignee={this.state.entityUserInput}
        instances={this.state.instances}
        userContacts={this.state.userContacts}
        toggle={this.toggle}
        showModal={this.state.showModal}
        success={this.state.success}
        handleClose={this.handleClose}
        fileNames={this.state.fileNames}
        attachment={this.state.attachment}
        onFileChange={this.onFileChange}
        handleRemoveDoc={this.handleRemoveDoc}
        handleDocClick={this.handleDocClick}
        err_msg={this.state.err_msg}
        afterCreate={this.state.afterCreate}
        loading={this.state.loading}
      />
    );
  }
}

export default ReportFormHandler;
