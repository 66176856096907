import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useHistory } from "react-router-dom";
import {
  alpha,
  makeStyles,
  CssBaseline,
} from "@material-ui/core";
import {} from "@material-ui/icons";
import MyAppBar from "./MyAppBar";
import MenuItems from "./MenuItems";
import "./SingleNavigationBar.css";
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "white",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    color: "white",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  appBar: {
    height: "65px",
  },
  drawerPaper: {
    width: "225px",
    marginTop: "65px",
    maxHeight: "100vh",
  },
}));

const SingleNavigationBar = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const accountLogOutHandler = (event) => {
    Cookies.remove('name');
    Cookies.remove('role');
    Cookies.remove('currentUserToken');

    localStorage.clear();
    sessionStorage.clear();

    history.push("/");
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  return (
    <div className="nav-root">
      <CssBaseline />
      <MyAppBar classes={classes} 
        logOut={accountLogOutHandler} 
        handleClose={handleClose}
        open={open}
        handleListKeyDown={handleListKeyDown}
        handleToggle={handleToggle}
        anchorRef={anchorRef}
      />
      <MenuItems classes={classes} />
      <main className="nav-content">{props.content}</main>
    </div>
  );
};

export default SingleNavigationBar;
