import React from "react";
import FormControl from "@material-ui/core/FormControl";
import "bootstrap/dist/css/bootstrap.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import "./Autocomplete.css";
import "../FormItem/FormItem.css";
import "../../Report/ReportPage/ReportForm/ReportForm.css";

const Autocomplete = (props) => {
  let suggestionsListComponent;
  //If there are suggestions to show, take the user input and filter the list of
  // suggestions to show the ones that match the input
  if (props.showSuggestions && props.userInput) {
    if (props.filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className="list-group box">
          {props.filteredSuggestions.map((suggestion, index) => {
            return (
              <li
                className={`list-group-item${((props.activeSuggestion===index) ? " highlight-item":"")}`}
                key={suggestion}
                onClick={props.onClick}
                onKeyDown={props.onKeyDown}
              >
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponent = (
        <div className="no-suggestions">
          <em>No suggestions!</em>
        </div>
      );
    }
  }

  const highlight_red_check = () => {
    return (props.requiredField && props.afterCreate &&
      ((props.contactActive && props.userContacts && !props.userContacts.length) || 
      (!props.contactActive && !props.userInput.length)));
  }

  return (
    <React.Fragment>
      <FormControl
        disabled={props.form_disabled}
        // className={clsx(classes.formControl)}
      >
        {/* <InputLabel htmlFor="autocomplete">{props.name}</InputLabel> */}
        <div className="box">
          <div>{props.name}</div>
          <input
            className={`input${highlight_red_check()?" highlight-red":""}`}
            id="autocomplete"
            value={props.userInput}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            autoComplete="off"
          />
        </div>
      </FormControl>
      {suggestionsListComponent}
      {props.contactActive && (
        <Grid>
          <div>
            <List
              className="formItem_list"
              dense={true}
              style={{ maxWidth: "250px", marginTop: "3px" }}
            >
              {/* List all the selected elements */}
              {props.userContacts.map((row) => {
                return (
                  <ListItem space>
                    {/* <div>{row}</div>
                    <div>
                      {!props.form_disabled && (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={props.handleDeleteClick}
                          value={row}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </div> */}
                    <ListItemText primary={row} />
                    <ListItemSecondaryAction>
                      {!props.form_disabled && (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={props.handleDeleteClick}
                          value={row}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};
export default Autocomplete;
