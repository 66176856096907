import { React, Component } from "react";
import ContactFormHandler from "./ContactForm/ContactFormHandler";
import SingleNavigationBar from "../../components/SingleNavigationBar/SingleNavigationBar";

class ContactPage extends Component {
  render() {
    return <SingleNavigationBar content={<ContactFormHandler />} />;
  }
}

export default ContactPage;
