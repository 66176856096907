import { React } from 'react'
import AppRoutes from './AppRoutes/AppRoutes'

const App = () => {
    return (
        <div>
            <AppRoutes />
        </div>
    );
}

export default App;
