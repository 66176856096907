//import FilterObj from "components/Filter/FilterObj";

exports.url = process.env.REACT_APP_BACKEND_URL;
exports.basePaths = {
    users: "login",
    contacts: "contacts",
    properties: "properties",
    entities: "parties",
    docs: "docs",
    reports: "reports",
    reportInstances: "report_instances",
    version: "version",
}

exports.runMode = "demo";

exports.propertyTable = {
    table:{
        // defaultFilterList : {
        //     0: new FilterObj('status', 'Current', 'E'),
        //     1: new FilterObj(),
        // },
        filterFields: [
            "yardi_property_code",
            "name",
            "address",
            "owner",
            "contact",
            "contact_name",
            "property_type",
            "year_end",
            "unit_count",
            "hud_program",
            "tax_credits",
            "layered",
            "status",
        ],
        dependentTables : ["PROPERTY", "CONTACT"],
        tableSorter:"yardi_property_code",
        tableDataElements:["yardi_property_code","name","address","owner"],
        pageBaseTag:"properties",
        removeElementTag:"/remove_property",
        addNewElementTag:"/add_property",
        pageName:"Properties",
        headCells:[
        { id: 'yardi_property_code', numeric: false, disablePadding: true, label: 'Yardi Property Code' },
        { id: 'name', numeric: true, disablePadding: true, label: 'Property Name' },
        { id: 'address', numeric: true, disablePadding: true, label: 'Property Address' },
        { id: 'owner', numeric: true, disablePadding: true, label: 'Property Owner' },
        ],
        singleElementTag:"/property=",
        showEl:["BulkUpload"],
    },
    page:{

    }    
}

exports.propertyPage = {

}

exports.contactTable ={
    table:{
        // defaultFilterList : {
        //     0: new FilterObj('status', 'Current', 'E'),
        //     1: new FilterObj(),
        // },
        filterFields: [
            'name',
            'email',
            'address',
            'phone',
            'phone2',
            'role',
            'send_reports_to',
            'status',
        ],
        dependentTables : ['CONTACT'],
        tableSorter:"_id",
        tableDataElements:["name","email","role"],
        pageBaseTag:"/contacts",
        removeElementTag:"/remove_contact",
        addNewElementTag:"/add_contact",
        pageName:"Contacts",
        headCells:[
            {
                id: 'name',
                numeric: false,
                disablePadding: true,
                label: 'Name',
            },
            {
                id: 'email',
                numeric: true,
                disablePadding: true,
                label: 'Email',
            },
            {
                id: 'role',
                numeric: true,
                disablePadding: true,
                label: 'Role',
            }
        ],
        singleElementTag:"/contact=",
        showEl:["BulkUpload"],
    },
    page:{

    }    
}

exports.legalEntityTable = {
    table:{
        // defaultFilterList : {
        //     0: new FilterObj('status', 'Current', 'E'),
        //     1: new FilterObj(),
        // },
        filterFields: [
            "code",
            "name",
            "start_date",
            "end_date",
            "contact",
            "contact_name",
            "property",
            "status",
        ],
        dependentTables : ["PARTY", "PROPERTY", "CONTACT"],
        tableSorter:"code",
        tableDataElements:["name","property","start_date","end_date"],
        pageBaseTag:"/parties",
        removeElementTag:"/remove_party",
        addNewElementTag:"/add_entity",
        pageName:"Legal Documents",
        headCells:[
            { id: 'name', numeric: false, disablePadding: true, label: 'Relevant Party Name' },
            { id: 'property', numeric: true, disablePadding: true, label: 'Property' },
            { id: 'start_date', numeric: true, disablePadding: true, label: 'Start Date' },
            { id: 'end_date', numeric: true, disablePadding: true, label: 'End Date' },
        ],
        singleElementTag:"/entity=",
        showEl:["BulkUpload"],
    },
    page:{

    }    
}

exports.usersTable = {
    table:{
        // defaultFilterList : {
        //     0: new FilterObj('status', 'Current', 'E'),
        //     1: new FilterObj(),
        // },
        filterFields: [
            "code",
            "name",
            "start_date",
            "end_date",
            "contact",
            "contact_name",
            "property",
            "status",
        ],
        dependentTables : ["PARTY", "PROPERTY", "CONTACT"],
        tableSorter:"code",
        tableDataElements:["name","property","start_date","end_date"],
        pageBaseTag:"/parties",
        removeElementTag:"/remove_party",
        addNewElementTag:"/add_entity",
        pageName:"Legal Documents",
        headCells:[
            { id: 'name', numeric: false, disablePadding: true, label: 'Relevant Party Name' },
            { id: 'property', numeric: true, disablePadding: true, label: 'Property' },
            { id: 'start_date', numeric: true, disablePadding: true, label: 'Start Date' },
            { id: 'end_date', numeric: true, disablePadding: true, label: 'End Date' },
        ],
        singleElementTag:"/entity=",
        showEl:["BulkUpload"],
    },
    page:{

    }   
}