import { React, Component } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

class Authentication extends Component {

    constructor() {
        super();
        this.state = {
            isUserAuthenticated: Cookies.get('currentUserToken')? true : false
        };
    }

    render(){
        return (
            //If user is authenticated, they will be directed to the home page
            //Otherwise, they will bw directed to the login page 
            this.state.isUserAuthenticated ?
                <Redirect to="/home" /> :
                <Redirect to="/login" />
        )
    }
}

export default Authentication;