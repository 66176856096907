
export const check_match = (data, filter_obj) => {
    if (data[filter_obj.filterField] === undefined) return true;
    if (filter_obj.filterField === "contact" || filter_obj.filterField === "responsible_party") return contactFilter(data, filter_obj, filter_obj.filterField);
    const data_to_filter = ('' + data[filter_obj.filterField]).toLowerCase();
    if (filter_obj.filterType === "C") {
        if (data_to_filter.includes(filter_obj.filterString.toLowerCase())) {
            return true;
        } else {
            return false;
        }
    } else if (filter_obj.filterType === "E") {
        if (data_to_filter === filter_obj.filterString.toLowerCase()) {
            return true;
        } else {
            return false;
        }
    }
};

const contactFilter = (data, filter_obj, field) => {
    for (let ind in data[field]) {
        const contact = ('' + data[field][ind].toLowerCase());
        if (filter_obj.filterType === "C") 
            if (contact.includes(filter_obj.filterString.toLowerCase())) return true;
        else if (filter_obj.filterType === "E")
            if (contact ===  filter_obj.filterString.toLowerCase()) return true;
    }  
    return false;
};

export const check_all_match = (data, filter_list) => {
    for (let key in filter_list) {
        let match = check_match(data, filter_list[key]);
        if (!match) return false;
    }
    return true;
};