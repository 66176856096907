import { React, Component } from "react";
import { Divider } from "@material-ui/core";
import NavigationIcon from "@material-ui/icons/Navigation";
import "bootstrap/dist/css/bootstrap.min.css";
import CreateIcon from "@material-ui/icons/Create";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import FormItem from "../../../components/FormItem/FormItem";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newContact: window.location.pathname.includes("add_contact") === true,
      editModeActive: window.location.pathname.includes("add_contact") === true,
    };
  }

  editFormHandler = (event) => {
    this.setState({
      editModeActive: !this.state.editModeActive,
    });
  };

  render() {
    const showBasic =
      !this.state.editModeActive &&
      !this.props.disabled &&
      !this.state.newContact;
    const showUpdate =
      this.state.editModeActive &&
      !this.props.disabled &&
      !this.state.newContact;
    return (
      //<form className={classes.root} noValidate autoComplete="off">
      // <Paper style={{ padding: 15, height: "82.5vh" }}>
      <div>
        <div className="flex-row">
          {!this.state.newContact ? (
            <h2 style={{maxWidth: "70rem", overflow: 'clip'}}>{this.props.name}</h2>
          ) : (
            <h2>New Contact</h2>
          )}
          {showUpdate && (
            <div className="flex-row">
              <button 
                className={"flex-row button"} 
                onClick={this.props.toggle}
                style={{marginLeft: '20px', marginRight: '5px'}}
              >
                <NavigationIcon style={{ color: "rgb(63, 133, 240)" }} />
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    // color: "rgb(63, 133, 240)",
                  }}
                >
                  Update
                </div>
              </button>
              <div style={{ marginRight: "10px" }}></div>
              <button
                className={"flex-row button"}
                style={{ border: "2px solid red", marginRight: '10px' }}
                onClick={this.editFormHandler}
              >
                <CreateIcon style={{ color: "red" }} />
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    // color: "rgb(63, 133, 240)",
                  }}
                >
                  Edit
                </div>
              </button>
            </div>
          )}
          {showBasic && (
            <button
              className={"flex-row button"}
              onClick={this.editFormHandler}
              style={{marginLeft: '20px', marginRight: '10px'}}
            >
              <CreateIcon style={{ color: "rgb(63, 133, 240)" }} />
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  // color: "rgb(63, 133, 240)",
                }}
              >
                Edit
              </div>
            </button>
          )}
          {this.state.newContact && (
            <button 
              className={"flex-row button"} 
              onClick={this.props.toggle}
              style={{marginLeft: '20px', marginRight: '5px'}}
            >
              <NavigationIcon style={{ color: "rgb(63, 133, 240)" }} />
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  // color: "rgb(63, 133, 240)",
                }}
              >
                Create
              </div>
            </button>
          )}
        </div>
        <Divider />
        <form>
          <fieldset disabled={!this.state.editModeActive}>
            <div className="Row">
              <FormItem
                itemName="Name"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="basicInput"
                type="text"
                id="contact-name"
                value={this.props.name}
                onChange={this.props.handleChange}
                handle="name"
              />
              <FormItem
                itemName="Email Address"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="basicInput"
                type="email"
                id="contact-email"
                value={this.props.email}
                onChange={this.props.handleChange}
                handle="email"
              />
              <FormItem
                itemName="Phone Number"
                itemType="basicInput"
                id="contact-phone"
                value={this.props.phone}
                onChange={this.props.handleChange}
                handle="phone"
              />
            </div>
            <div className="Row">
              <FormItem
                itemName="Secondary Number"
                itemType="basicInput"
                // type="number"
                // pattern="[0-9]*"
                // maxLength="10"
                id="contact-phone2"
                value={this.props.phone2}
                onChange={this.props.handleChange}
                handle="phone2"
              />
              <FormItem
                itemName="Role"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="basicInput"
                type="text"
                id="role"
                value={this.props.role}
                onChange={this.props.handleChange}
                handle="role"
                list="roles"
                showSuggestions={true}
                listItems={[
                  "Accountant-Internal",
                  "Accountant-External",
                  "Asset Manager-Internal",
                  "Asset Manager-External",
                  "Lender",
                  "Servicer",
                  "Investor",
                  "Other",
                ]}
              />

              <FormItem
                itemName="Send Reports To?"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="dropDown"
                id="send_reports_to"
                value={this.props.send_reports_to}
                handleChange={this.props.handleChange}
                handle="send_reports_to"
                listItems={["Yes", "No"]}
              />
            </div>
            <div className="Row">
              <FormItem
                itemName="Address"
                extendedBox={true}
                type="text"
                id="contact-address"
                value={this.props.address}
                handleChange={this.props.handleChange}
                handle="address"
              />
              <FormItem
                itemName="Status"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="dropDown"
                id="contact-status"
                value={this.props.status}
                handleChange={this.props.handleChange}
                handle="status"
                listItems={["Current", "Past", "Future"]}
              />
              <div className="Column">
                <div className="box"></div>
              </div>
            </div>
          </fieldset>
        </form>
        <ConfirmationModal
          handleUpdate={this.props.onUpdateClick}
          toggle={this.props.toggle}
          show={this.props.showModal}
          success={this.props.success}
          handleClose={this.props.handleClose}
          create={this.state.newContact}
          handleCreate={this.props.onCreateClick}
          err_msg={this.props.err_msg}
          found_msg={this.props.found_msg}
        />
      </div>

      // </Paper>
    );
  }
}

export default ContactForm;
