// This component displays information in a table format

import { React, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import BulkUpload from '../../components/BulkUpload/BulkUpload';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import { propertyPage, url } from '../../config';
import LoadingDialog from '../../components/LoadingDialog';
import Cookies from 'js-cookie';
import FormTableHead from '../../shared_components/FormTable/FormTableHead';
import FormTableToolbar from '../../shared_components/FormTable/FormTableToolbar';
import { de } from 'date-fns/locale';
import * as FM from '../../StateModifiers/filterModifiers';
import * as TM from '../../StateModifiers/tableModifiers';
import { useEffect } from 'react';
import ApiService from '../../services/apiService';

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
}));

const  FormTable = (props) => {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('calories');
	const [selected, setSelected] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [csvFile, setCsvFile] = useState();
	const [show, setShow] = useState(false);
	const [success, setSuccess] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [deleteSuccess, setDeleteSuccess] = useState(false);
	const [loadingDialogOpen, setLoadingDialogOpen] = useState(false);
	const [loadingDialogStatus, setLoadingDialogStatus] = useState('LOADING');
	const [loadingDialogMessage, setLoadingDialogMessage] = useState(
		'Successfuly uploaded!'
	);
	const [loadingDialogContent, setLoadingDialogContent] =
		useState('Doing stuff...');
	const [checkOverwriteExisting, setCheckedOverwriteExisting] =
		useState(false);
	const [err_msg, setErrMsg] = useState('');
	const [csv_result, setCsvResult] = useState('');
	const [csvErrorList, setCsvErrorList] = useState([]);

	const pageBaseUrl = `${url}` + `${props.states.pageBaseTag}`;
	const classes = useStyles();
	const history = useHistory();	
    const apiService = new ApiService(true);  

  const updateStringFromFilterList = FM.updateStringFromFilterList(props.states);
  const updateFieldFromFilterList = FM.updateFieldFromFilterList(props.states);
  const updateTypeFromFilterList = FM.updateTypeFromFilterList(props.states);
  const updateFilteredRows = FM.updateFilteredRows(props.states);
  const deleteFromFilterList = FM.deleteFromFilterList(props.states);
  const addFilterField = FM.addFilterField(props.states);
  const handleFilterReset = FM.handleFilterReset(props.states);
  const updateRows = TM.updateRows(props.states);
  const handleSearch = TM.handleSearch(props.states);


	const handleLoadingDialogOpen = () => {
		setLoadingDialogOpen(true);
	};

	const handleLoadingDialogClose = () => {
		setLoadingDialogOpen(false);
	};

	const handleRequestSort = (event, element) => {
		const isAsc = orderBy === element && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(element);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = props.states.rows.map((n) => n[props.states.tableSorter]);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	// Handle flipping the switch in bulk upload to overwrite existing content where the id's are the same
	const handleSwitchClick = () => {
		setCheckedOverwriteExisting(!checkOverwriteExisting);
	};

	// When a table row is selected, check the box, highlight the row, and display the delete icon
	const handleClick = (_, _id) => {
		const selectedIndex = selected.indexOf(_id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, _id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	// When delete icon is clicked, remove contact
	const handleDeleteClick = async () => {

		setLoadingDialogContent('Deleting...');
		handleLoadingDialogOpen();

		const [response, autherror] = await apiService.get({
            path: '/authenticate',
            dataType: 'users',
        });
        if (autherror) props.states.setRedirect(true);
        else {
            let role = response.headers['role-data'];
            let disabled = role !== 'Admin';

            if( role !== 'Admin') props.states.setRedirect(true);

            props.states.setRole(role);
            props.states.setDisabled(disabled);
        }

		// const [result,error] = await apiService.delete({
		// 	path: props.states.removeElementTag,
		// 	data: {
		// 		_ids: selected,
		// 	},
		// 	dataType: props.states.pageBaseTag,
		// });

		// if(!error){
		// 	updateRows(result.data.newData);
		// 	setDeleteSuccess(true);
		// 	setErrMsg('');
		// }
		// else{
		//     setErrMsg(error.response.data.message);
		// }

		axios
			.delete(pageBaseUrl + props.states.removeElementTag, {
				headers: {
					authorization: `${Cookies.get('currentUserToken')} ${
						Cookies.get('device_mfa_key')
							? Cookies.get('device_mfa_key')
							: ''
					}`,
				},
				data: {
					_ids: selected,
				},
			})
			.then((result) => {
				console.log(result.data.newData);
				updateRows(result.data.newData);
				setDeleteSuccess(true);
				setErrMsg('');
			})
			.catch((err) => {
				setErrMsg(err.response.data.message);
			});
		
		handleLoadingDialogClose();
		setSelected([]);
	};

	const handleDeleteButtonClick = () => {
		setShowDelete(!showDelete);
		setDeleteSuccess(false);
	};

	const toggleDelete = () => {
		setShowDelete(!showDelete);
		setErrMsg('');
	};

	const handleDeleteClose = () => {
		setShowDelete(!showDelete);
		setDeleteSuccess(false);
		setErrMsg('');
	};

	// Handle user changing max number of elements displayed per page of table
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		props.states.handleTablePageChange(null, 0);
	};

	// Handle user clicking the add contact button
	const handleAddClick = (event) => {
		history.push(props.states.addNewElementTag);
	};

	const isSelected = (_id) => selected.indexOf(_id) !== -1;

	const emptyRows =
		rowsPerPage -
		Math.min(
			rowsPerPage,
			props.states.rows.length - props.states.tablePage * rowsPerPage
		);

	const handleDoNothing = () => {
		return;
	};

	// Toggle the bulk upload modal
	const toggleOpen = () => {
		setShow(true);
		setSuccess(false);
	};

	// On modal submit, read the csv
	const handleSubmit = (e) => {
		e.preventDefault();
		if (csvFile) {
			setLoadingDialogContent('Uploading...');
			handleLoadingDialogOpen();
			const file = csvFile;
			const reader = new FileReader();

			reader.onload = function (e) {
				const text = e.target.result;
				processCSV(text);
			};

			reader.readAsText(file);
		}
	};

	const handleCSVClick = () => {
		let csv = [];
		csv.push(props.states.filterFields);
		for (let i = 0; i < props.states.displayRows.length; i++) {
			let new_row = [];
			let elements = {};
			for (const [key, value] of Object.entries(props.states.displayRows[i])) {
				if (props.states.filterFields.includes(key)) {
					elements[key] = value;
				}
			}
			for (const i of props.states.filterFields) {
				let list_item = '';
				if (elements[i]) {
					if (typeof elements[i] === 'object') {
						list_item = '"' + elements[i].join(', ') + '"';
						new_row.push(list_item);
					} else {
						new_row.push('"' + elements[i] + '"');
					}
				} else {
					new_row.push('');
				}
			}
			csv.push(new_row);
		}
		let csvContent =
			'data:text/csv;charset=utf-8,' +
			csv.map((e) => e.join(',')).join('\n');
		var encodedUri = encodeURI(csvContent);
		var link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', props.states.pageName + '.csv');
		document.body.appendChild(link); // Required for FF

		link.click();
	};

	// Process the csv into a list of maps
	const processCSV = (str, delim = ',') => {
		let headers = str.slice(0, str.indexOf('\n')).split(delim);
		const last_header = headers[headers.length - 1];
		if (last_header.endsWith('\r'))
			headers[headers.length - 1] = last_header.split('\r')[0];
		let rows = str.slice(str.indexOf('\n') + 1).split(/\r?\n/);
		if (!rows[rows.length - 1]) rows.pop();

		const newArray = rows.map((row) => {
			const eachObject = {};
			for (const header of headers) {
				eachObject[header] = '';
			}
			let in_quotes = false;
			let h_i = 0;
			const values = row.split(delim);
			for (let i = 0; i < values.length; i++) {
				let value = values[i];
				const header = headers[h_i];
				if (value.startsWith('"')) {
					value = value.slice(value.indexOf('"') + 1);
					in_quotes = true;
				}
				if (value.endsWith('"')) {
					value = value.slice(0, value.indexOf('"'));
					in_quotes = false;
				}
				if (in_quotes) eachObject[header] += value.trim() + ',';
				else {
					eachObject[header] += value.trim();
					h_i++;
				}
			}
			return eachObject;
		});

		axios
			.post(pageBaseUrl + '/add_csv', {
				data: newArray,
				overwrite: checkOverwriteExisting,
			})
			.then((result) => {
				setLoadingDialogStatus('SUCCESS');
				handleLoadingDialogClose();
				setSuccess(true);
				setCsvResult(result.data.message);
				setCsvErrorList(result.data.detailed_errors);
				setLoadingDialogMessage(result.data.message);
			})
			.catch((err) => {
				setLoadingDialogStatus('ERROR');
				setCsvResult(err.response.data.message);
				setLoadingDialogMessage(err.response.data.message);
			});
	};

	const handleClose = () => {
		setShow(!show);
		setSuccess(!success);
		setErrMsg('');
		setCsvResult('');
		window.location.reload(false);
	};

	const handleTemplateClick = () => {
		let csv = [];
		csv.push(props.states.filterFields);
		let csvContent =
			'data:text/csv;charset=utf-8,' +
			csv.map((e) => e.join(',')).join('\n');
		let encodedUri = encodeURI(csvContent);
		let link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', props.states.csvFileName);
		document.body.appendChild(link); // Required for FF
		link.click();
	};
 
	const handleTablePageChange = (_, new_page) => {
		props.states.setTablePage(new_page);
	};

	useEffect(() => {
		updateFilteredRows()
		return () => {
        			
		};
	},[]);

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<FormTableToolbar
					showEl={props.states.showEl}
					toolbarName={props.states.pageName}
					numSelected={selected.length}
					handleDeleteClick={handleDeleteClick}
					handleAddClick={handleAddClick}
					filterList={props.states.filterList}
					deleteFromFilterList={deleteFromFilterList}
					updateStringFromFilterList={updateStringFromFilterList}
					updateFieldFromFilterList={updateFieldFromFilterList}
					updateTypeFromFilterList={updateTypeFromFilterList}
					addFilterField={addFilterField}
					updateFilteredRows={updateFilteredRows}
					handleFilterReset={handleFilterReset}
					handleSearch={handleSearch}
					modalOpen={props.states.modalOpen}
          			setModalOpen={props.states.setModalOpen}
					filterFields={props.states.filterFields}
					handleCSVClick={handleCSVClick}
					disabled={props.states.disabled}
					toggle={toggleOpen}
					show={show}
					openDeleteModal={handleDeleteButtonClick}
				/>
				<LoadingDialog
					open={loadingDialogOpen}
					handleClose={handleLoadingDialogClose}
					loaderStatus={loadingDialogStatus}
					message={loadingDialogMessage}
					dialogContent={loadingDialogContent}
				/>
				<TableContainer>
					<Table
						className={classes.table}
						aria-labelledby="Table"
						size="small"
						aria-label="Table"
					>
						<BulkUpload
							show={show}
							setCsvFile={setCsvFile}
							handleSubmit={handleSubmit}
							handleTemplateClick={handleTemplateClick}
							success={success}
							handleClose={handleClose}
							csv_result={csv_result}
							detailed_errors={csvErrorList}
							checked={checkOverwriteExisting}
							handleSwitchClick={handleSwitchClick}
						/>
						<DeleteModal
							show={showDelete}
							toggle={toggleDelete}
							handleDelete={handleDeleteClick}
							success={deleteSuccess}
							handleClose={handleDeleteClose}
							err_msg={err_msg}
						/>
						<FormTableHead
							headCells={props.states.headCells}
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={props.states.rows.length}
							disabled={props.states.disabled}
						/>
						<TableBody>
							{/* The properties get sorted and each one is placed into a row
                                the name is a link in which the name gets passed in as a query */}
							{stableSort(
								props.states.rows,
								getComparator(order, orderBy)
							)
								.slice(
									props.states.tablePage * rowsPerPage,
									props.states.tablePage * rowsPerPage + rowsPerPage
								)
								.map((row, index) => {
									const isItemSelected = isSelected(
										row[props.states.tableSorter]
									);
									const labelId = `enhanced-table-checkbox-${index}`;
									return (
										<TableRow
											hover
											onClick={
												!props.states.disabled
													? (event) =>
															handleClick(
																event,
																row[
																	props.states.tableSorter
																]
															)
													: handleDoNothing
											}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row[props.states.tableSorter]}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												{!props.states.disabled && (
													<Checkbox
														checked={isItemSelected}
														inputProps={{
															'aria-labelledby':
																labelId,
														}}
													/>
												)}
											</TableCell>
											{props.states.tableDataElements.map(
												(dataEl, index) => {
													return (
														<TableCell
															key={index}
															component={Link}
															to={
																props.states.singleElementTag +
																row[
																	props.states.tableSorter
																]
															}
															id={labelId}
															scope="row"
															padding="none"
															style={{
																paddingTop:
																	'8px',
																paddingBottom:
																	'8px',
															}}
															// style={{tableLayout: 'fixed',maxWidth:'25rem', overflow: 'clip'}}
														>
															{row[dataEl]}
														</TableCell>
													);
												}
											)}
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 45 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50, 100]}
					component="div"
					count={props.states.rows.length}
					rowsPerPage={rowsPerPage}
					page={props.states.tablePage}
					onPageChange={handleTablePageChange}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	);
}

export default FormTable;
