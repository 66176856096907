import { React, Component } from "react";
import ContactForm from "./UserForm";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { url } from "../../../config";
import Cookies from 'js-cookie';
import { getCached } from "../../../caching/cacher";

class UserFormHandler extends Component {
  constructor(props) {
    super(props);
    this.userBaseUrl = `${url}/login`;
    this.state = {
      _id: '',
      name: "",
      email: "",
      role: "User",
      title: "",
      afterCreate: false,
      redirect: false,
      form_disabled: false,
      showModal: false,
      success: false,
      goBack: false,
      err_msg: '',
      found_msg: "",
    };
    axios.defaults.headers.common["authorization"] =
      `${Cookies.get("currentUserToken")} ${Cookies.get("device_mfa_key")?Cookies.get("device_mfa_key"):''}`;
  }

  // On component load, get the specific contact details
  componentDidMount() {
    axios
      .get(this.userBaseUrl + "/authenticate")
      .then((res) => {
        let role = res.headers["role-data"];
        let disabled = role !== "Admin";
        this.setState({ form_disabled: disabled });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ redirect: true });
      });
    let email = window.location.pathname.split("=")[1];
    if (email) {
      axios
        .get(this.userBaseUrl + "/get_one", {
          params: {
            email: email,
          },
        })
        .then((result) => {
          this.setState({
            _id: result.data._id,
            name: result.data.name,
            email: result.data.email,
            role: result.data.role,
            title: result.data.title,
            err_msg: ''
          });
        })
        .catch((err) => {
          this.setState({err_msg: err.response.data.message});
        });
    }
  }

  // When update is pressed, show the confirmation modal
  toggle = async () => {
    const [up_to_date, cached_data] = await getCached("Users");
      if (cached_data.rows.filter(e => e.email === this.state.email).length > 0) {
        this.setState({found_msg: `A user with the email ${this.state.email} already exists. Do you want to overwrite?`});
      }
    this.setState({ showModal: !this.state.showModal, err_msg: "", success: false });
  };

  handleClose = () => {
    if (window.location.pathname.includes("add_user") === true){
      this.setState({
        showModal: false,
        success: false,
        goBack: true,
        err_msg: '',
        found_msg: '',
      });
    } else {
      this.setState({
        showModal: false,
        success: false,
        err_msg: '',
        found_msg: '',
      });
    }
  };

  // When the admin updates the user info, save that data
  onUpdateClick = (e) => {
    e.preventDefault();
    if (!this.state.name ||
      !this.state.email ||
      !this.state.role) {
        this.setState({err_msg: "Fill in the missing fields highlighted in red.", afterCreate: true});
        return;
    }
    axios
      .patch(this.userBaseUrl + "/update_user", {
        _id: this.state._id,
        name: this.state.name,
        email: this.state.email,
        role: this.state.role,
        title: this.state.title,
      })
      .then((result) => {
        console.log("Updated Successfully");
        this.setState({ success: true, err_msg: '' });
      })
      .catch((err) => {
        this.setState({err_msg: err.response.data.message});
      });
  };

  // When a new user is created, send the data to the backend
  onCreateClick = (e) => {
    e.preventDefault();
    if (!this.state.name ||
      !this.state.email ||
      !this.state.role) {
        this.setState({err_msg: "Fill in the missing fields highlighted in red.", afterCreate: true});
        return;
    }
    // Generate a password
    let length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*",
      retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    axios
      .post(this.userBaseUrl + "/add_user", {
        name: this.state.name,
        email: this.state.email,
        role: this.state.role,
        password: retVal,
        temp: true,
        title: this.state.title,
      })
      .then((result) => {
        console.log("Added Successfully");
        this.setState({ success: true, err_msg: '' });
      })
      .catch((err) => {
        this.setState({err_msg: err.response.data.message});
      });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    if (this.state.form_disabled) {
      return <Redirect to="/404" />;
    }
    if (this.state.goBack) {
      return <Redirect to="/users" />;
    }
    return (
      <ContactForm
        name={this.state.name}
        email={this.state.email}
        role={this.state.role}
        title={this.state.title}
        handleChange={this.handleChange}
        onUpdateClick={this.onUpdateClick}
        onCreateClick={this.onCreateClick}
        form_disabled={this.state.form_disabled}
        toggle={this.toggle}
        showModal={this.state.showModal}
        success={this.state.success}
        handleClose={this.handleClose}
        err_msg={this.state.err_msg}
        found_msg={this.state.found_msg}
        afterCreate={this.state.afterCreate}
      />
    );
  }
}

export default UserFormHandler;
