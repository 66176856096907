import React from "react";
import { useHistory } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";

import "./ReportCard.css";

function ReportCard(props) {
  let itemDate = new Date(props.reportInfo.date);
  const month = itemDate.toLocaleString("en-US", {
    month: "2-digit",
  });
  const day = itemDate.toLocaleString("en-US", {
    day: "2-digit",
  });
  const year = itemDate.getFullYear();

  const currentDate = new Date();
  const history = useHistory();

  const daysBetween =
    (itemDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);

  let style = "";
  if (props.reportInfo.completed) style = "report-card report-card-completed"
  else if (itemDate < currentDate) style = "report-card report-card__overDue";
  else if (
    (itemDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24) <
    7
  )
    style = "report-card report-card__1week";
  else if (daysBetween < 31) style = "report-card report-card__1month";
  else style = "report-card";

  const reportOpenHandler = (id) => () => {
    //history.push(`/reports=${value}`);
    history.push("/report_instance=" + id);
  };

  return (
    <ListItem
      //className={"report-card"}
      key={props.reportInfo.reportId}
      role={undefined}
      dense
      button
      onClick={reportOpenHandler(props.reportInfo._id)}
    >
      <div className={style}>
        <div className="report-card__item" style={{ textAlign: "left" }}>
          {props.reportInfo.property}
        </div>
        <div className="report-card__item">
          {props.reportInfo.party ? props.reportInfo.party : "No Data"}
        </div>
        <div className="report-card__item">{props.reportInfo.type}</div>
        <div className="report-card__item">{props.currentTab !== 2 ? 
          Math.ceil(daysBetween) < 0
            ? "Past Due"
            : Math.ceil(daysBetween) > 0 && Math.ceil(daysBetween) < 2
            ? Math.ceil(daysBetween) + " Day"
            : Math.ceil(daysBetween) + " Days"
         : new Date(props.reportInfo.updatedAt).toLocaleString("en-US", {month: "2-digit", day:"2-digit",year:"numeric"})}</div>
        {/* <div className="report-card__item">
          {Math.ceil(daysBetween) < 0
            ? "Past Due"
            : Math.ceil(daysBetween) > 0 && Math.ceil(daysBetween) < 2
            ? Math.ceil(daysBetween) + " Day"
            : Math.ceil(daysBetween) + " Days"}
        </div> */}
        <div className="report-card__item" style={{ textAlign: "right" }}>
          {month + "/" + day + "/" + year}
        </div>
      </div>
    </ListItem>
  );
}

export default ReportCard;
