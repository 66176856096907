import { check_all_match } from "../components/Filter/FilterFunc";
import FilterObj from "../components/Filter/FilterObj";


export const updateStringFromFilterList = ({filterList, setFilterList}) => {
    return (key, value) => {
        let newFilterList = { ...filterList };
        if (newFilterList[key]) newFilterList[key].setFilterString = value;
        setFilterList(newFilterList);
    };
};


export const updateFieldFromFilterList = ({filterList, setFilterList}) => {
    return (key, value) => {
        let newFilterList = { ...filterList };
        if (newFilterList[key]) newFilterList[key].setFilterField = value;
        setFilterList(newFilterList);
    };
};


export const updateTypeFromFilterList = ({filterList, setFilterList}) => {
    return (key, value) => {
        let newFilterList = { ...filterList };
        if (newFilterList[key]) newFilterList[key].setFilterType = value;
        setFilterList(newFilterList);
    };
};



export const updateFilteredRows = ({filterList, allRows, setRows, setDisplayRows, setTablePage, dataCreator}) => {
    return () => {
        let newRows = [];
        let newDisplayRows = [];
        for (let row of allRows) {
            if (check_all_match(row, filterList)) {
                newRows.push(dataCreator(row));
                newDisplayRows.push(row);
            }
        }
        setRows(newRows);
        setDisplayRows(newDisplayRows);
        setTablePage(0);
    };
};


export const deleteFromFilterList = ({filterList, setFilterList}) => {
    return (key) => {
        let newFilterList = { ...filterList };
        delete newFilterList[key];
        setFilterList(newFilterList);
    };
};


export const addFilterField = ({filterList, setFilterList, filterCounter, setFilterCounter}) => {
    return () => {
        let newFilterCounter = filterCounter;
        newFilterCounter += 1;
        let newFilterList = { ...filterList };
        newFilterList[newFilterCounter] = new FilterObj(); 
        setFilterCounter(newFilterCounter);
        setFilterList(newFilterList);
    };
};


export const handleFilterReset = ({defaultFilterList, setFilterList, setFilterCounter, allRows, setRows, setDisplayRows, setTablePage, dataCreator}) => {
    const updateRows = updateFilteredRows({filterList: defaultFilterList, allRows, setRows, setDisplayRows, setTablePage, dataCreator});
    return () => {
        const filterLength = Object.keys(defaultFilterList).length;
        setFilterList(defaultFilterList);
        setFilterCounter(filterLength-1);
        updateRows();
    };
};