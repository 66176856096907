import { React, Component } from "react";
import { Redirect } from "react-router-dom";
import "../components/FormItem/FormItem.css";
import { Divider } from "@material-ui/core";
import FormItem from "../components/FormItem/FormItem";
import NavigationIcon from "@material-ui/icons/Navigation";
import EmailResultModal from "./EmailResultModal";
import CircularProgress from '@mui/material/CircularProgress';

class HelpPageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      software_module_name: "RTM",
      issue_categ: "General",
      issue_msg: "",
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    if (this.state.goBack) {
      return <Redirect to="/help" />;
    }
    return (
      <div>
        <h2>Contact Us</h2>
        <Divider />
        <h5
          style={{
            marginTop: "50px",
            width: "50%",
            textAlign: "center",
          }}
        >
          How can we help you?
        </h5>
        <hr style={{ width: "50%" }} />
        <div className="Row" style={{ width: "50%" }}>
          <FormItem
            itemName="Software Module"
            itemType="dropDown"
            id="software_module_name"
            value={this.props.software_module_name}
            handleChange={this.props.handleChange}
            handle="software_module_name"
            listItems={["RTM", "Other"]}
          />
          <FormItem
            itemName="Question Topic"
            itemType="dropDown"
            id="issue_categ"
            value={this.props.issue_categ}
            handleChange={this.props.handleChange}
            handle="issue_categ"
            listItems={[
              "General",
              "How To",
              "Bug Found",
              "Feature Improvement",
              "New Feature Idea",
              "Just Wanna Vent",
            ]}
          />
        </div>
        <div className="Row">
          <FormItem
            itemName="Message"
            requiredField={true}
            afterCreate={this.props.afterCreate}
            id="issue_msg"
            value={this.props.issue_msg}
            extendedBox={true}
            handleChange={this.props.handleChange}
            handle="issue_msg"
            boxStyle={{ width: "50%" }}
          />
        </div>
        <div className="Row">
          <FormItem
            itemType="fileInput"
            itemName="Attachments"
            type="file"
            id="entity-attachment"
            onChange={this.props.onFileChange}
            multiple="multiple"
            multiEntry={true}
            dense={true}
            listItems={[]}
          />
        </div>   
        <div
          className="flex-row"
          style={{
            width: "50%",
            justifyContent: "end",
          }}
        >
          <button className={"flex-row button"} onClick={this.props.sendEmail}>
            <NavigationIcon style={{ color: "rgb(63, 133, 240)" }} />
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
                // color: "rgb(63, 133, 240)",
              }}
            >
              Send
            </div>
          </button>
        </div>
        {this.props.loading && <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>}
        <EmailResultModal
          show={this.props.show}
          success={this.props.success}
          err={this.props.err}
          handleClose={this.props.handleClose}
        />
      </div>
    );
  }
}

export default HelpPageForm;
