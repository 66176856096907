import { React, Component } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { Divider } from "@material-ui/core";
import FormItem from "../../../components/FormItem/FormItem";
import NavigationIcon from "@material-ui/icons/Navigation";
import ReportInstance from "../../../ReportInstance/ReportInstance";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import CircularProgress from '@mui/material/CircularProgress';

class ReportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newReport: window.location.pathname.includes("add_report") === true,
      editModeActive: window.location.pathname.includes("add_report") === true,
    };
  }

  editFormHandler = (event) => {
    this.setState({
      editModeActive: !this.state.editModeActive,
    });
  };

  render() {
    const showBasic =
      !this.state.editModeActive &&
      !this.props.form_disabled &&
      !this.state.newReport;
    const showUpdate =
      this.state.editModeActive &&
      !this.props.form_disabled &&
      !this.state.newReport;

    return (
      <div>
        <div className="flex-row">
          {!this.state.newReport ? (
            <h2 style={{maxWidth: "70rem", overflow: 'clip'}}>{this.props.property + " - " + this.props.type}</h2>
          ) : (
            <h2>New Report</h2>
          )}
          {showUpdate && (
            <div className="flex-row">
              <button 
                className={"flex-row button"} 
                onClick={this.props.toggle}
                style={{marginLeft: '20px', marginRight: '5px'}}
              >
                <NavigationIcon style={{ color: "rgb(63, 133, 240)" }} />
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    // color: "rgb(63, 133, 240)",
                  }}
                >
                  Update
                </div>
              </button>
              <button
                className={"flex-row button"}
                style={{ border: "2px solid red", marginRight: '10px'}}
                onClick={this.editFormHandler}
              >
                <CreateIcon style={{ color: "red" }} />
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",                    
                    // color: "rgb(63, 133, 240)",
                  }}
                >
                  Edit
                </div>
              </button>
            </div>
          )}
          {showBasic && (
            <div className="flex-row">
              <button
              className={"flex-row button"}
              style={{marginLeft: '20px', marginRight: '5px'}}
              onClick={this.props.cloneHandler}
            >
              <CreateIcon style={{ color: "rgb(63, 133, 240)" }} />
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  // color: "rgb(63, 133, 240)",
                }}
              >
                Clone
              </div>
            </button>
            <div style={{ marginRight: "10px" }}></div>
              <button
                className={"flex-row button"}
                onClick={this.editFormHandler}
                style={{marginRight: '10px'}}
              >
                <CreateIcon style={{ color: "rgb(63, 133, 240)" }} />
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    // color: "rgb(63, 133, 240)",
                  }}
                >
                  Edit
                </div>
              </button>
            </div>
          )}
          {this.state.newReport && (
            <button 
              className={"flex-row button"} 
              onClick={this.props.toggle}
              style={{marginLeft: '20px', marginRight: '5px'}}
            >
              <NavigationIcon style={{ color: "rgb(63, 133, 240)" }} />
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  // color: "rgb(63, 133, 240)",
                }}
              >
                Create
              </div>
            </button>
          )}
        </div>
        <Divider />
        <form autoComplete="off">
          <fieldset disabled={!this.state.editModeActive}>
            <div className="Row">
              <FormItem
                itemName="Report Name"
                id="type"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                value={this.props.type}
                handleChange={this.props.handleChange}
                handle="type"
              />
              <FormItem
                itemType="autoComplete"
                suggestions={this.props.propertySuggestions}
                name={"Property"}
                requiredField={true}
                afterCreate={this.props.afterCreate}
                activeSuggestion={this.props.propertyActiveSuggestion}
                filteredSuggestions={this.props.propertyFilteredSuggestions}
                showSuggestions={this.props.propertyShowSuggestions}
                userInput={this.props.propertyUserInput}
                onChange={this.props.onPropertyChange}
                onKeyDown={this.props.onPropertyKeyDown}
                onClick={this.props.onPropertyClick}
                userContacts={this.props.userProperties}
                contactActive={false}
              />
              <FormItem
                itemType="autoComplete"
                suggestions={this.props.entitySuggestions}
                name={"Relevant Party"}
                requiredField={true}
                afterCreate={this.props.afterCreate}
                activeSuggestion={this.props.entityActiveSuggestion}
                filteredSuggestions={this.props.entityFilteredSuggestions}
                showSuggestions={this.props.entityShowSuggestions}
                userInput={this.props.entityUserInput}
                onChange={this.props.onEntityChange}
                onKeyDown={this.props.onEntityKeyDown}
                onClick={this.props.onEntityClick}
                userContacts={this.props.relevant_party}
                contactActive={false}
              />
            </div>
            {/* <div className="Row">
              <FormItem
                itemName="Sub Report Name"
                id="type"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                value={this.props.type}
                handleChange={this.props.handleChange}
                handle="type"
              />
            </div> */}
            <div className="Row">
              <FormItem
                itemType="dropDown"
                itemName="Due Date Type"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                id="due_date_type"
                value={this.props.due_date_type}
                handleChange={this.props.handleChange}
                handle="due_date_type"
                listItems={[
                  "Month End",
                  "Quarter End",
                  "Year End",
                  "Fixed Date",
                ]}
              />

              {!this.props.fixed && (
                <FormItem
                  itemName="Number of Months"
                  itemType="basicInput"
                  id="number_of_months"
                  value={this.props.number_of_months}
                  onChange={this.props.handleChange}
                  handle="number_of_months"
                />
              )}
              {!this.props.fixed && (
                <FormItem
                  itemName="Number of Days"
                  itemType="basicInput"
                  id="number_of_days"
                  value={this.props.number_of_days}
                  onChange={this.props.handleChange}
                  handle="number_of_days"
                />
              )}
              {this.props.fixed && (
                <FormItem
                  itemName="Fixed Date"
                  itemType="basicInput"
                  id="fixed_date"
                  type="date"
                  value={this.props.fixed_date}
                  onChange={this.props.handleChange}
                  handle="fixed_date"
                />
              )}
              {this.props.fixed && (
                <FormItem
                  itemType="dropDown"
                  itemName="Repeats annually?"
                  id="annually"
                  value={this.props.annually}
                  handleChange={this.props.handleChange}
                  handle="annually"
                  listItems={["Yes", "No"]}
                />
              )}
            </div>
            <div className="Row">
              <FormItem
                itemType="dropDown"
                itemName="Description Type"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                id="desc_type"
                value={this.props.desc_type}
                handleChange={this.props.handleChange}
                handle="desc_type"
                listItems={["Yardi", "Other", "Both"]}
              />
              <FormItem
                itemType="autoComplete"
                suggestions={this.props.contactSuggestions}
                name={"Responsible Party"}
                requiredField={true}
                afterCreate={this.props.afterCreate}
                form_disabled={!this.state.editModeActive}
                activeSuggestion={this.props.contactActiveSuggestion}
                filteredSuggestions={this.props.contactFilteredSuggestions}
                showSuggestions={this.props.contactShowSuggestions}
                userInput={this.props.contactUserInput}
                onChange={this.props.onContactChange}
                onKeyDown={this.props.onContactKeyDown}
                onClick={this.props.onContactClick}
                userContacts={this.props.userContacts}
                contactActive={true}
                handleDeleteClick={this.props.handleDeleteClick}
              />
              <FormItem
                itemType="fileInput"
                itemName="Attachments"
                type="file"
                id="entity-attachment"
                onChange={this.props.onFileChange}
                multiple="multiple"
                multiEntry={true}
                dense={true}
                listClassname="formItem_list"
                listItems={this.props.fileNames}
                listItemClick={this.props.handleDocClick}
                listItemDelete={this.props.handleRemoveDoc}
                form_disabled={this.state.editModeActive}
              />
            </div>
            <div className="Row">
              {(this.props.desc_type === "Yardi" ||
                this.props.desc_type === "Both") && (
                <FormItem
                  itemName="Yardi Report Description"
                  id="yardi_report_desc"
                  value={this.props.yardi_report_desc}
                  extendedBox={true}
                  handleChange={this.props.handleChange}
                  handle="yardi_report_desc"
                />
              )}
              {(this.props.desc_type === "Other" ||
                this.props.desc_type === "Both") && (
                <FormItem
                  itemName="Report Detail Description"
                  id="report_detail_desc"
                  value={this.props.report_detail_desc}
                  extendedBox={true}
                  handleChange={this.props.handleChange}
                  handle="report_detail_desc"
                />
              )}
              <div className="Column">
                <div className="box"></div>
              </div>
            </div>
          </fieldset>
          {!this.state.newReport && (
            <div>
              <ReportInstance
                type={this.props.type}
                _id={this.props._id}
                property={this.props.property}
                disabled={this.props.form_disabled}
                assignees={this.props.userContacts}
                instances={this.props.instances}
              />
            </div>
          )}
        </form>
        {this.props.loading && <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>}
        <ConfirmationModal
          handleUpdate={this.props.onUpdateClick}
          toggle={this.props.toggle}
          show={this.props.showModal}
          success={this.props.success}
          handleClose={this.props.handleClose}
          create={this.state.newReport}
          handleCreate={this.props.onCreateClick}
          err_msg={this.props.err_msg}
        />
      </div>
    );
  }
}

export default ReportForm;
