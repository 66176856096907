import { getCached, putCache } from "../caching/cacher";

export const updateRows = ({pageName, dependentTables,tableSorter, rows, setRows, allRows, setAllRows}) => {
    return (ids) => {
        let newAllRows = [];
        let newRows = [];
        rows.forEach((row) => {
            if (!ids.includes(row[tableSorter])) newRows.push(row);
        });
        allRows.forEach((row) => {
            if (!ids.includes(row[tableSorter])) newAllRows.push(row);
        });
        setRows(newRows);
        setAllRows(newAllRows);
        putCache(pageName, dependentTables, {
            allRows: newAllRows,
            rows: newRows,
        });
    };
};


export const handleSearch = ({displayRows, setRows, setTablePage, filterFields, dataCreator}) => {
    return (e) => {
        let newRows = [];
        displayRows.forEach((item) => {
            let found = false;
            Object.entries(item).forEach((subItem) => {
                if (
                    !found &&
                    filterFields.includes(subItem[0]) &&
                    subItem[1] &&
                    subItem[1].toString().toLowerCase().includes(e.target.value.toLowerCase())
                ) {
                    newRows.push(dataCreator(item));
                    found = true;
                }
            });
        });
        setRows(newRows);
        setTablePage(0);
    };
};