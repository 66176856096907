import { React, Component } from "react";
import PropertyForm from "./PropertyForm";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { url } from "../../../config";
import Cookies from 'js-cookie';
import { getCached } from "../../../caching/cacher";

class PropertyFormHandler extends Component {
  constructor(props) {
    super(props);
    this.contactsBaseUrl = `${url}/contacts`;
    this.propertyBaseUrl = `${url}/properties`;
    this.userBaseUrl = `${url}/login`;
    this.state = {
      contacts: [],
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      afterCreate: false,
      userInput: "",
      _id: '',
      name: "",
      code: "",
      address: "",
      owner: "",
      contact: [],
      status: "Current",
      property_type: "Mixed Income",
      year_end: "January",
      unit_count: "",
      hud_program: "",
      tax_credits: "",
      layered: "",
      form_disabled: this.props.disabled,
      suggestions: [],
      redirect: false,
      showModal: false,
      success: false,
      goBack: false,
      addRows: false,
      err_msg: '',
      found_msg: '',
    };
    axios.defaults.headers.common["authorization"] =
      `${Cookies.get("currentUserToken")} ${Cookies.get("device_mfa_key")?Cookies.get("device_mfa_key"):''}`;
  }

  // On component load, get the specific property details
  async componentDidMount() {
     try{
        const auth = await axios.get(this.userBaseUrl + "/authenticate");
        const role = auth.headers["role-data"];
        const disabled =  role !== "Admin";
        this.setState({ role: role, form_disabled: disabled });
    }
    catch (err) {
        console.log(err);
        this.setState({ redirect: true });
    }

    let code = decodeURI(window.location.pathname.split("=")[1]);
    if (code !== null && code !== 'undefined') {
        const propResp = await axios.get(this.propertyBaseUrl + "/get_one", {
            params: {
                yardi_property_code: code,
            },
        });

        const contactResp = await axios.get(this.contactsBaseUrl + "/get_list_contacts", {
              params: {
                contact: propResp.data.contact,
              },
        });
        // Set the contacts and the contact names
        let contacts = [];
        if (contactResp.data) contacts = contactResp.data.map((row) => `${row.name} <${row.email}>`);

        this.setState({
            _id: propResp.data._id,
            name: propResp.data.name,
            code: propResp.data.yardi_property_code,
            owner: propResp.data.owner,
            contact: contacts,
            address: propResp.data.address,
            status: propResp.data.status,
            property_type: propResp.data.property_type,
            year_end: propResp.data.year_end,
            unit_count: propResp.data.unit_count,
            hud_program: propResp.data.hud_program,
            tax_credits: propResp.data.tax_credits,
            layered: propResp.data.layered,
            addRows: propResp.data.property_type === "Affordable",
        });
    }

    // Get all contacts for the autocomplete feature to prepopulate the field
    const allContacts = await axios.get(this.contactsBaseUrl + "/get_all_contacts")
    let suggestions = allContacts.data.map((row) =>`${row.name} <${row.email}>`);
    this.setState({ suggestions: suggestions });
  }

  // When update is pressed, show the confirmation modal
  toggle = async () => {
    const [up_to_date, cached_data] = await getCached("Properties");
      if (cached_data.rows.filter(e => e.code === this.state.code).length > 0) {
        this.setState({found_msg: `A property with the code ${this.state.code} already exists. Do you want to overwrite?`});
      }
    this.setState({ showModal: !this.state.showModal, err_msg: "", success: false });
  };

  handleClose = () => {
    if (window.location.pathname.includes("add_property") === true){
      this.setState({
        showModal: false,
        success: false,
        goBack: true,
        err_msg: "",
        found_msg: '',
      });
    } else {
      this.setState({
        showModal: false,
        success: false,
        err_msg: "",
        found_msg: '',
      });
    }
  };

  // When the user updates the property info, save that data
  onUpdateClick = (e) => {
    e.preventDefault();
    if (!this.state.code ||
      !this.state.name ||
      !this.state.address ||
      !this.state.owner ||
      !this.state.status ||
      !this.state.unit_count) {
        this.setState({err_msg: "Fill in the missing fields highlighted in red.", afterCreate: true});
        return;
    }
    axios
      .patch(this.propertyBaseUrl + "/update_property", {
        _id: this.state._id,
        yardi_property_code: this.state.code,
        name: this.state.name,
        address: this.state.address,
        owner: this.state.owner,
        contact: this.state.contact
              .map(item => item.substring(item.indexOf('<') + 1, item.indexOf('>'))),
        status: this.state.status,
        property_type: this.state.property_type,
        year_end: this.state.year_end,
        unit_count: this.state.unit_count,
        hud_program: this.state.hud_program,
        tax_credits: this.state.tax_credits,
        layered: this.state.layered,
      })
      .then((result) => {
        this.setState({ success: true, err_msg: '' });
      })
      .catch((err) => {
        this.setState({err_msg: err.response.data.message});
      });
  };

  // When a new property is created, send the data to the backend
  onCreateClick = (e) => {
    e.preventDefault();
    if (!this.state.code ||
      !this.state.name ||
      !this.state.address ||
      !this.state.owner ||
      !this.state.status ||
      !this.state.unit_count) {
        this.setState({err_msg: "Fill in the missing fields highlighted in red.", afterCreate: true});
        return;
    }
    axios
      .post(this.propertyBaseUrl + "/add_property", {
        yardi_property_code: this.state.code,
        name: this.state.name,
        address: this.state.address,
        owner: this.state.owner,
        contact: this.state.contact
              .map(item => item.substring(item.indexOf('<') + 1, item.indexOf('>'))),
        status: this.state.status,
        property_type: this.state.property_type,
        year_end: this.state.year_end,
        unit_count: this.state.unit_count,
        hud_program: this.state.hud_program,
        tax_credits: this.state.tax_credits,
        layered: this.state.layered,
      })
      .then((result) => {
        console.log("Added Successfully");
        this.setState({ success: true, err_msg: '' });
      })
      .catch((err) => {
        this.setState({err_msg: err.response.data.message});
      });
  };

  // Handle the removal of a contact from the contact field
  handleDeleteClick = (e) => {
    let newContactsName = [...this.state.contact];
    const index = this.state.contact.indexOf(e.currentTarget.value);
    if (index > -1) {
      newContactsName.splice(index, 1);
      this.setState({ contact: newContactsName });
    }
  };

  // As the user types the contact name, update the suggestions to show
  onContactChange = (e) => {
    const userInput = e.currentTarget.value;

    const filteredSuggestions = this.state.suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  // handle a contact being clicked from the suggestion list
  onContactClick = (e) => {
    let newContactsName = [...this.state.contact];
    if (!newContactsName.includes(e.currentTarget.innerText)) {
      newContactsName.push(e.currentTarget.innerText);
      this.setState({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: "",
        contact: newContactsName,
      });
    }
  };

  // Handle up/down and enter keys being used while contact suggestion list is open
  onContactKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    // Enter key
    if (e.keyCode === 13) {
      let newContactsName = [...this.state.contact];
      if (
        !newContactsName.includes(filteredSuggestions[activeSuggestion]) &&
        filteredSuggestions[activeSuggestion]
      ) {
        newContactsName.push(filteredSuggestions[activeSuggestion]);
        this.setState({
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: "",
          contact: newContactsName,
        });
      }
    }
    // Up key
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        this.setState({ activeSuggestion: filteredSuggestions.length - 1 });
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // Down key
    else if (e.keyCode === 40) {
      if (activeSuggestion + 1 === filteredSuggestions.length) {
        this.setState({ activeSuggestion: 0 });
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  handleChange = (name) => (event) => {
    if (name === 'property_type'){
        this.setState({
            [name]: event.target.value,
            addRows: event.target.value === "Affordable",
        });
    } else {
        this.setState({
            [name]: event.target.value,
        });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    if (this.state.goBack) {
      return <Redirect to="/properties" />;
    }
    return (
      <PropertyForm
        form_disabled={this.state.form_disabled}
        activeSuggestion={this.state.activeSuggestion}
        filteredSuggestions={this.state.filteredSuggestions}
        showSuggestions={this.state.showSuggestions}
        userInput={this.state.userInput}
        name={this.state.name}
        code={this.state.code}
        address={this.state.address}
        owner={this.state.owner}
        contact={this.state.contact}
        status={this.state.status}
        year_end={this.state.year_end}
        unit_count={this.state.unit_count}
        hud_program={this.state.hud_program}
        tax_credits={this.state.tax_credits}
        layered={this.state.layered}
        property_type={this.state.property_type}
        onContactChange={this.onContactChange}
        onContactClick={this.onContactClick}
        onContactKeyDown={this.onContactKeyDown}
        handleChange={this.handleChange}
        onUpdateClick={this.onUpdateClick}
        onCreateClick={this.onCreateClick}
        handleDeleteClick={this.handleDeleteClick}
        suggestions={this.state.suggestions}
        toggle={this.toggle}
        showModal={this.state.showModal}
        success={this.state.success}
        handleClose={this.handleClose}
        addRows={this.state.addRows}
        err_msg={this.state.err_msg}
        found_msg={this.state.found_msg}
        afterCreate={this.state.afterCreate}
      />
    );
  }
}

export default PropertyFormHandler;
