import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  smallBoxMargin: {
    margin: theme.spacing(2),
    minWidth: 60,
  },
  buttonRoot: {
    margin: theme.spacing(4),
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    border: "2px solid #3f85f0",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function ConfirmationModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const Change = props.create ? "Create" : "Update";
  const change = props.create ? "create" : "update";
  const body = props.success ? (
    <div style={modalStyle} className={classes.paper}>
     <h4>{Change}d Successfully!</h4>
      <button
        className={"button"}
        style={{
          marginTop: "10px",
          float: "right",
        }}
      onClick={props.handleClose}
      >
        Close
      </button>
    </div>
  ) : props.err_msg? (
    <div style={modalStyle} className={classes.paper}>
      <h4>{ props.err_msg}</h4>
      <button
        className={"button"}
        style={{
          marginTop: "10px",
          float: "right",
        }}
        onClick={props.toggle}
      >
        Close
      </button>
    </div>
    ) : (
    <div style={modalStyle} className={classes.paper}>
      {props.found_msg && Change!=="Update" ? props.found_msg : <h4>Are you sure you want to {change}?</h4>}
      <br />
      <div className={"flex-row button"} style={{ justifyContent: "right" }}>
        <button
          className={"flex-row button"}
          style={{ marginTop: "10px" }}
          onClick={props.create ? props.handleCreate : props.handleUpdate}
        >
          {Change}
        </button>
        <button
          className={"flex-row button"}
          style={{ marginTop: "10px", marginLeft: "10px", borderColor: "red" }}
          onClick={props.toggle}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.show}
        onClose={props.toggle}
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
