import { React, Component } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { Divider } from "@material-ui/core";
import FormItem from "../../../components/FormItem/FormItem";
import NavigationIcon from "@material-ui/icons/Navigation";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import CircularProgress from '@mui/material/CircularProgress';

class LegalEntityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newEntity: window.location.pathname.includes("add_entity") === true,
      editModeActive: window.location.pathname.includes("add_entity") === true,
    };
  }

  editFormHandler = (event) => {
    this.setState({
      editModeActive: !this.state.editModeActive,
    });
  };

  render() {
    const showBasic =
      !this.state.editModeActive &&
      !this.props.form_disabled &&
      !this.state.newEntity;
    const showUpdate =
      this.state.editModeActive &&
      !this.props.form_disabled &&
      !this.state.newEntity;

    const { handleDocClick } = this.props;
    return (
      <div>
        <div className="flex-row">
          {!this.state.newEntity ? (
            <h2 style={{maxWidth: "70rem", overflow: 'clip'}}>{this.props.name}</h2>
          ) : (
            <h2>New Legal Document</h2>
          )}
          {showUpdate && (
            <div className="flex-row">
              <button 
                className={"flex-row button"} 
                onClick={this.props.toggle}
                style={{marginLeft: '20px', marginRight: '5px'}}
                >
                <NavigationIcon style={{ color: "rgb(63, 133, 240)" }} />
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    // color: "rgb(63, 133, 240)",
                  }}
                >
                  Update
                </div>
              </button>
              <div style={{ marginRight: "10px" }}></div>
              <button
                className={"flex-row button"}
                style={{ border: "2px solid red", marginRight: '10px' }}
                onClick={this.editFormHandler}
              >
                <CreateIcon style={{ color: "red" }} />
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    // color: "rgb(63, 133, 240)",
                  }}
                >
                  Edit
                </div>
              </button>
            </div>
          )}
          {showBasic && (
            <button
              className={"flex-row button"}
              onClick={this.editFormHandler}
              style={{marginLeft: '20px', marginRight: '10px'}}
            >
              <CreateIcon style={{ color: "rgb(63, 133, 240)" }} />
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  // color: "rgb(63, 133, 240)",
                }}
              >
                Edit
              </div>
            </button>
          )}
          {this.state.newEntity && (
            <button 
              className={"flex-row button"} 
              onClick={this.props.toggle}
              style={{marginLeft: '20px', marginRight: '5px'}}
            >
              <NavigationIcon style={{ color: "rgb(63, 133, 240)" }} />
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  // color: "rgb(63, 133, 240)",
                }}
              >
                Create
              </div>
            </button>
          )}
        </div>
        <Divider />
        <form>
          <fieldset disabled={!this.state.editModeActive}>
            <div className="Row">
              <FormItem
                itemName="Relevant Party Code"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                id="entity-code"
                value={this.props.code}
                handleChange={this.props.handleChange}
                handle="code"
              />
              <FormItem
                itemName="Relevant Party Name"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                id="entity-name"
                value={this.props.name}
                handleChange={this.props.handleChange}
                handle="name"
              />
              <FormItem
                itemType="autoComplete"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                suggestions={this.props.propSuggestions}
                name={"Property"}
                form_disabled={!this.state.editModeActive}
                activeSuggestion={this.props.propActiveSuggestion}
                filteredSuggestions={this.props.propFilteredSuggestions}
                showSuggestions={this.props.propShowSuggestions}
                userInput={this.props.property}
                onChange={this.props.onPropChange}
                onKeyDown={this.props.onPropKeyDown}
                onClick={this.props.onPropClick}
                userContacts={this.props.property}
                contactActive={false}
              />              
            </div>
            <div className="Row">
              <FormItem
                itemName="Start Date"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="basicInput"
                id="start_date"
                type="date"
                value={this.props.start_date}
                onChange={this.props.handleChange}
                handle="start_date"
              />
              <FormItem
                itemName="End Date"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="basicInput"
                id="end_date"
                type="date"
                value={this.props.end_date}
                onChange={this.props.handleChange}
                handle="end_date"
              />
              <FormItem
                itemType="autoComplete"
                suggestions={this.props.suggestions}
                name={"Contacts"}
                requiredField={true}
                afterCreate={this.props.afterCreate}
                form_disabled={!this.state.editModeActive}
                activeSuggestion={this.props.activeSuggestion}
                filteredSuggestions={this.props.filteredSuggestions}
                showSuggestions={this.props.showSuggestions}
                userInput={this.props.userInput}
                onChange={this.props.onContactChange}
                onKeyDown={this.props.onContactKeyDown}
                onClick={this.props.onContactClick}
                userContacts={this.props.contact}
                handleDeleteClick={this.props.handleDeleteClick}
                contactActive={true}
              />              
            </div>
            <div className="Row">
              <FormItem
                itemName="Status"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="dropDown"
                id="contact-status"
                value={this.props.status}
                handleChange={this.props.handleChange}
                handle="status"
                listItems={["Current", "Past", "Future"]}
              />
              <FormItem
                itemType="fileInput"
                itemName="Attachments"
                type="file"
                id="entity-attachment"
                onChange={this.props.onFileChange}
                multiple="multiple"
                multiEntry={true}
                listClassname="formItem_list"
                listItems={this.props.fileNames}
                listItemClick={this.props.handleDocClick}
                listItemDelete={this.props.handleRemoveDoc}
                form_disabled={this.state.editModeActive}
                dense={true}
              />
              <div className="Column">
                <div className="box"></div>
              </div>
            </div>
          </fieldset>
        </form>
        {this.props.loading && <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>}
        <ConfirmationModal
          handleUpdate={this.props.onUpdateClick}
          toggle={this.props.toggle}
          show={this.props.showModal}
          success={this.props.success}
          handleClose={this.props.handleClose}
          create={this.state.newEntity}
          handleCreate={this.props.onCreateClick}
          err_msg={this.props.err_msg}
          found_msg={this.props.found_msg}
        />
      </div>
    );
  }
}

export default LegalEntityForm;
