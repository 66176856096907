import { React, useState,useEffect } from "react";
import { withRouter } from "react-router";
import FilterObj from "../components/Filter/FilterObj";
import { Redirect } from "react-router-dom";
import FormTable from "../shared_components/FormTable/FormTable";
import ApiService from '../services/apiService';
import LoadingPage from "../components/LoadingPage/LoadingPage";

const ReportInstance = () => {
    
    const apiService = new ApiService(true);  
    const filterFields = [
        "date",
        "type",
        "property",
        "party",
        "assignees",
        "completed",
        "completed_by",
        "updatedAt",
    ];
    const defaultFilterList = {
        0: new FilterObj(),
    };
    // const dependentTables = ["REPORT", "PARTY", "PROPERTY", "CONTACT"];
    
    const [allRows,setAllRows] = useState([]);
    const [rows,setRows] = useState([]);
    const [displayRows,setDisplayRows] = useState([]);
    const [tablePage,setTablePage] = useState(0);
    const [filterList,setFilterList] = useState(defaultFilterList);
    const [filterCounter,setFilterCounter] = useState(0);
    const [modalOpen,setModalOpen] = useState(false);
    const [role,setRole] = useState('');
    const [disabled,setDisabled] = useState(true);
    const [redirect,setRedirect] = useState(false);
    const [loadingDialogOpen,setLoadingDialogOpen] = useState(true);
    const [type,setType] = useState("");
    const [reportId,setReportId] = useState("");
    const [property,setProperty] = useState("");
    const [assignees,setAssignees] = useState([]);
    const [entity,setEntity] = useState("");
    const [instances,setInstances] = useState("");


    
    const dataCreator = (row) => {
        return {
        _id:row._id,
        type:row.type,
        property:row.property,
        party:row.party,
        date:row.date,
        assignees:row.assignees,
        }
    }
    
    
    const tableSorter="_id";
    const tableDataElements=["type","property","party","date"];
    const pageBaseTag="/report_instances";
    const removeElementTag="/remove";
    const addNewElementTag="/add_report_instance";
    const pageName="Reports Instances";
    const headCells=[
        {
            id: "report_type",
            numeric: false,
            disablePadding: true,
            label: "Report Type",
          },
          { id: "property", numeric: false, disablePadding: true, label: "Property" },
          {
            id: "entity",
            numeric: false,
            disablePadding: true,
            label: "Relevant Party",
          },
          { id: "date", numeric: true, disablePadding: true, label: "Due Date" },
    ]
    const singleElementTag="/report_instance=";
    const showEl=[""];

    const callUpdate = true;
    
    const states = {
        allRows,setAllRows,
        rows,setRows,
        displayRows,setDisplayRows,
        tablePage,setTablePage,
        filterList,setFilterList,
        filterCounter,setFilterCounter,
        modalOpen,setModalOpen,
        role,setRole,
        disabled,setDisabled,
        redirect,setRedirect,
        loadingDialogOpen,setLoadingDialogOpen,
        defaultFilterList,
        filterFields,
        //dependentTables,
        dataCreator,
        tableSorter,
        tableDataElements,
        pageBaseTag,
        removeElementTag,
        addNewElementTag,
        pageName,
        headCells,
        singleElementTag,
        showEl,       
        callUpdate,
        type,
        reportId,
        property,
        assignees,
        entity,
        instances, 
    }

    useEffect(() => {

        
        setLoadingDialogOpen(true); 

        const fetchData = async () => {
            const [response, error] = await apiService.get({
                path: '/authenticate',
                dataType: 'users',
            });
            if (error) setRedirect(true);
            else {
                let role = response.headers['role-data'];
                let disabled = role !== 'Admin';
                setRole(role);
                setDisabled(disabled);
            }

            let code = window.location.pathname.split("=")[1];
            if(code) {
              
                const [reportResp,reportErr] = await apiService.get({
                    path:"/get_one",
                    dataType:"reports",
                    data: {_id: code},
                });

                setType(reportResp.data.type);
                setReportId(reportResp.data._id);
                setInstances(reportResp.data.instances);
                setAssignees(reportResp.data.responsible_party);

                const [propertyResp,propertyErr] = await apiService.get({
                    path:"/get_one_by_id",
                    dataType:"properties",
                    data: {_id: reportResp.data.property},
                });
                setProperty(`${propertyResp.data.name} (${propertyResp.data.yardi_property_code})`);

                const [entityResp,enityErr] = await apiService.get({
                    path:"/get_one_by_id",
                    dataType:"entities",
                    data: {_id: reportResp.data.relevant_party},
                    
                })
                setEntity(`${entityResp.data.name} (${entityResp.data.code})`);

                if (reportResp.data.instances.length > 0) {
                    
                    const [instanceResp,instanceErr] = await apiService.get({
                    path: "/get_list",
                    dataType:"reportInstances",
                    data: {instances: reportResp.data.instances},
                    });

                    let rows = [];
                    if(instanceResp.data[0]){
                        for(let x in instanceResp.data){                        
                            let due_date = new Date(instanceResp.data[x].date);
                            let display_date = due_date.toLocaleDateString("en-US");

                            rows.push(dataCreator([
                                instanceResp.data[x]._id,
                                reportResp.data.type,
                                `${propertyResp.data.name} (${propertyResp.data.yardi_property_code})`,
                                `${entityResp.data.name} (${entityResp.data.code})`,
                                display_date,
                                reportResp.data.responsible_party
                            ]));
                            //`${entityResp.data.name} (${entityResp.data.code})`,
                        }
                        setAllRows(instanceResp.data);
                        setRows(rows);
                        setDisplayRows(instanceResp.data);
                    }                    
                }

            }
            
            setLoadingDialogOpen(false);
        };
        
        fetchData().catch(console.error);
        return () => {

        };           

    },[]);

    
    if (redirect) {
        return <Redirect to="/login" />;
    }
    else return (
      <div>
      {loadingDialogOpen ? (
          <LoadingPage />
      ) : (
          <FormTable
          states={states}
          />
      )}
      </div>
    );

}

export default withRouter(ReportInstance);