import { React, Component } from 'react';
import ReportFormHandler from './ReportForm/ReportFormHandler';
import SingleNavigationBar from '../../components/SingleNavigationBar/SingleNavigationBar';

class ReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false
        } 
    }

    render() {
        return(
            <SingleNavigationBar content={
                <ReportFormHandler
                disabled={this.state.disabled}
                />
            } />
        );
    };
}

export default ReportPage;