import { React, Component } from "react";
import SignIn from "../SignIn/SignIn";
import Dashboard from "../Dashboard/Dashboard";
import PReset from "../PReset/PReset";
import Authentication from "../components/Authentication/Authentication";
import Property from "../Property/Property";
import PropertyPage from "../Property/PropertyPage/PropertyPage";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import Contact from "../Contacts/Contact";
import ContactPage from "../Contacts/ContactPage/ContactPage";
import MyAccount from "../MyAccount/MyAccount";
import UserPage from "../MyAccount/UserPage/UserPage";
import NotFoundPage from "../components/NotFoundPage/NotFoundPage";
import LegalEntity from "../LegalEntity/LegalEntity";
import LegalEntityPage from "../LegalEntity/LegalEntityPage/LegalEntityPage";
import Report from "../Report/Report";
import ReportPage from "../Report/ReportPage/ReportPage";
import ReportInstancePage from "../ReportInstance/ReportInstancePage/ReportInstancePage";
import HelpPage from "../HelpPage/HelpPage";
import ProfilePage from "../ProfilePage/ProfilePage";

class AppRoutes extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={SignIn} />
            <Route exact path="/home" component={Dashboard} />
            <Route
              exact
              path="/reset_password/:token"
              component={ResetPassword}
            />
            <Route exact path="/update_password" component={PReset} />
            <Route exact path="/forgot_password" component={ForgotPassword} />
            <Route exact path="/reports" component={Report} />
            <Route
              exact
              path="/report_instance=:id"
              component={ReportInstancePage}
            />
            <Route
              exact
              path="/add_report_instance"
              component={ReportInstancePage}
            />
            <Route exact path="/property=:code" component={PropertyPage} />
            <Route exact path="/add_property" component={PropertyPage} />
            <Route exact path="/properties" component={Property} />
            <Route exact path="/contact=:name" component={ContactPage} />
            <Route exact path="/add_contact" component={ContactPage} />
            <Route exact path="/contacts" component={Contact} />
            <Route exact path="/user=:email" component={UserPage} />
            <Route exact path="/add_user" component={UserPage} />
            <Route exact path="/users" component={MyAccount} />
            <Route exact path="/entity=:email" component={LegalEntityPage} />
            <Route exact path="/add_entity" component={LegalEntityPage} />
            <Route exact path="/report=:id" component={ReportPage} />
            <Route exact path="/add_report" component={ReportPage} />
            <Route exact path="/entities" component={LegalEntity} />
            <Route exact path="/help" component={HelpPage} />
            <Route exact path="/profile" component={ProfilePage} />
            <Route exact path="/" component={Authentication} />
            <Route path="/404" component={NotFoundPage} />
            <Redirect to="/404" />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default AppRoutes;
