import { React, Component } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { Divider } from "@material-ui/core";
import FormItem from "../../../components/FormItem/FormItem";
import NavigationIcon from "@material-ui/icons/Navigation";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";

class PropertyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newProperty: window.location.pathname.includes("add_property") === true,
      editModeActive:
        window.location.pathname.includes("add_property") === true,
    };
  }

  editFormHandler = (event) => {
    this.setState({
      editModeActive: !this.state.editModeActive,
    });
  };

  render() {
    const showBasic =
      !this.state.editModeActive &&
      !this.props.form_disabled &&
      !this.state.newProperty;
    const showUpdate =
      this.state.editModeActive &&
      !this.props.form_disabled &&
      !this.state.newProperty;
    return (
      <div>
        <div className="flex-row">
          {!this.state.newProperty ? (
            <h2  style={{maxWidth: "70rem", overflow: 'clip'}}>{this.props.name}</h2>
          ) : (
            <h2>New Property</h2>
          )}
          {showUpdate && (
            <div className="flex-row">
              <button 
                className={"flex-row button"} 
                onClick={this.props.toggle}
                style={{marginLeft: '20px', marginRight: '5px'}}
              >
                <NavigationIcon style={{ color: "rgb(63, 133, 240)" }} />
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    // color: "rgb(63, 133, 240)",
                  }}
                >
                  Update
                </div>
              </button>
              <div style={{ marginRight: "10px" }}></div>
              <button
                className={"flex-row button"}
                style={{ border: "2px solid red", marginRight: '10px' }}
                onClick={this.editFormHandler}
              >
                <CreateIcon style={{ color: "red" }} />
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    // color: "rgb(63, 133, 240)",
                  }}
                >
                  Edit
                </div>
              </button>
            </div>
          )}
          {showBasic && (
            <button
              className={"flex-row button"}
              onClick={this.editFormHandler}
              style={{marginLeft: '20px', marginRight: '10px'}}
            >
              <CreateIcon style={{ color: "rgb(63, 133, 240)" }} />
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Edit
              </div>
            </button>
          )}
          {this.state.newProperty && (
            <button 
              className={"flex-row button"} 
              onClick={this.props.toggle}
              style={{marginLeft: '20px', marginRight: '5px'}}
            >
              <NavigationIcon style={{ color: "rgb(63, 133, 240)" }} />
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Create
              </div>
            </button>
          )}
        </div>
        <Divider />
        <form autoComplete="off">
          <fieldset disabled={!this.state.editModeActive}>
            <div className="Row">
              <FormItem
                itemName="Property Code"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="textArea"
                type="text"
                id="property-code"
                value={this.props.code}
                handleChange={this.props.handleChange}
                handle="code"
              />
              <FormItem
                itemName="Property Name"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="textArea"
                type="text"
                id="property-name"
                value={this.props.name}
                handleChange={this.props.handleChange}
                handle="name"
              />

              <FormItem
                itemName="Property Owner"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="textArea"
                type="text"
                id="property-owner"
                value={this.props.owner}
                handleChange={this.props.handleChange}
                handle="owner"
              />
              <FormItem
                itemName="Property Address"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="textArea"
                type="text"
                extendedBox={true}
                id="property-address"
                value={this.props.address}
                handleChange={this.props.handleChange}
                handle="address"
              />
            </div>
            <div className="Row">
              <FormItem
                itemType="autoComplete"
                suggestions={this.props.suggestions}
                form_disabled={!this.state.editModeActive}
                name={"Contacts"}
                activeSuggestion={this.props.activeSuggestion}
                filteredSuggestions={this.props.filteredSuggestions}
                showSuggestions={this.props.showSuggestions}
                userInput={this.props.userInput}
                onChange={this.props.onContactChange}
                onKeyDown={this.props.onContactKeyDown}
                onClick={this.props.onContactClick}
                userContacts={this.props.contact}
                handleDeleteClick={this.props.handleDeleteClick}
                contactActive={true}
              />
              <FormItem
                itemName="Status"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="dropDown"
                id="property-status"
                value={this.props.status}
                handleChange={this.props.handleChange}
                handle="status"
                listItems={["Current", "Past", "Future"]}
              />
              <FormItem
                itemName="Property Type"
                itemType="dropDown"
                id="property-type"
                value={this.props.property_type}
                handleChange={this.props.handleChange}
                handle="property_type"
                listItems={["Affordable", "Mixed Income"]}
              />
              <FormItem
                itemName="Year End"
                itemType="dropDown"
                id="year-end"
                value={this.props.year_end}
                handleChange={this.props.handleChange}
                handle="year_end"
                listItems={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ]}
              />
            </div>
            <div className="Row">
              <FormItem
                itemName="Unit Count"
                requiredField={true}
                afterCreate={this.props.afterCreate}
                itemType="basicInput"
                id="unit-count"
                value={this.props.unit_count}
                onChange={this.props.handleChange}
                handle="unit_count"
              />
              {this.props.addRows && (
                <FormItem
                  itemName="HUD Program"
                  itemType="basicInput"
                  id="hud-program"
                  value={this.props.hud_program}
                  onChange={this.props.handleChange}
                  handle="hud_program"
                />
              )}
              {this.props.addRows && (
                <FormItem
                  itemName="Tax Credits"
                  itemType="dropDown"
                  id="tax-credit"
                  value={this.props.tax_credits}
                  handleChange={this.props.handleChange}
                  handle="tax_credits"
                  listItems={["Yes", "No"]}
                />
              )}
              {this.props.addRows && (
                <FormItem
                  itemName="Layered"
                  itemType="dropDown"
                  id="layered"
                  value={this.props.layered}
                  handleChange={this.props.handleChange}
                  handle="layered"
                  listItems={["Yes", "No"]}
                />
              )}
            </div>
          </fieldset>
        </form>
        <ConfirmationModal
          handleUpdate={this.props.onUpdateClick}
          toggle={this.props.toggle}
          show={this.props.showModal}
          success={this.props.success}
          handleClose={this.props.handleClose}
          create={this.state.newProperty}
          handleCreate={this.props.onCreateClick}
          err_msg={this.props.err_msg}
          found_msg={this.props.found_msg}
        />
      </div>
    );
  }
}

export default PropertyForm;
