import { React, Component } from "react";
import "../components/FormItem/FormItem.css";
import { Divider } from "@material-ui/core";
import FormItem from "../components/FormItem/FormItem";
import '../Dashboard/Dashboard.css';
import ResetPasswordForm from "../components/ResetPasswordForm/ResetPasswordForm";

class ProfilePageLayout extends Component {
  getDate = fullDate => {
      return new Date(fullDate).toLocaleDateString('en-US');
  }

  render() {
    return (
      <div>
        <ResetPasswordForm 
          handleReset={this.props.handleResetClick}
          toggle={this.props.toggleResetModal}
          show={this.props.showResetModal}
          success={this.props.resetSuccess}
          old_password={this.props.old_password}
          new1_password={this.props.new1_password}
          new2_password={this.props.new2_password}
          handleChange={this.props.handleChange}
          err_msg={this.props.err_msg}
          match={this.props.match}
        />
        <h2>User Profile</h2>
        <Divider />
        <div className="same-row" style={{ minWidth: "47rem" }}>
          <div>
            <h2
              style={{               
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              {this.props.user.name}
            </h2>
            <div className="Row">
              <fieldset disabled={true}>
                <FormItem
                    itemName="Email"
                    itemType="basicInput"
                    type="email"
                    id="user_email"
                    value={this.props.user.email}
                  />
                  <FormItem
                    itemName="Role"
                    itemType="basicInput"
                    type="text"
                    id="user_role"
                    value={this.props.user.role}
                  />
                  <FormItem
                    itemName="User Since"
                    itemType="basicInput"
                    type="text"
                    id="user_created"
                    value={this.getDate(this.props.user.createdAt)}
                  />
              </fieldset>
            </div>
            <div className="Row">
              <div className="same-row" style={{ justifyContent: 'center'}}>
                <div className="stat-block" style={{marginRight: '50px'}}>
                  <h2 className="main-stat">{this.props.totalReportsCompleted}</h2>
                  <h5 className="name-stat">Total Reports Completed</h5>
                </div>
                <div className="stat-block">
                  <h2 className="main-stat">{this.props.totalReportsDue}</h2>
                  <h5 className="name-stat">Total Reports Due</h5>
                </div>
              </div>
            </div>
          </div>
            <div className="excel-export">
              <button onClick={this.props.toggleResetModal}>Reset Password</button>
            </div>
        </div>       
      </div>
    );
  }
}

export default ProfilePageLayout;
