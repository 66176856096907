// Class to store and delete temp data


const STORAGE_TABLE = "TEMP-CACHE";

export function getAndDeleteCached(cacheID, className) 
{
    let local_instance = localStorage.getItem(`${STORAGE_TABLE}-${className}-${cacheID}`);
    local_instance = JSON.parse(local_instance);
    localStorage.removeItem(`${STORAGE_TABLE}-${cacheID}`);
    return local_instance;
}


export function putCacheAndOpenPage(cacheID, className, data, path)
{
    localStorage.setItem(`${STORAGE_TABLE}-${className}-${cacheID}`, JSON.stringify(data));
    window.location.href = `${path}#${cacheID}`;
}