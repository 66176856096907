import { React, Component } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import CenterBanner from "../components/CenterBanner/CenterBanner";
import SIForm from "./SIForm/SIForm";
import MFAForm from "./SIForm/MFAForm";
import { Redirect } from "react-router-dom";
import { url } from "../config";
import Cookies from "js-cookie";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      redirect: "default",
      mfa_red: true,
      mfa_token: "",
      err_msg: "",
      mfa_message: "Check email for authentication code.",
    };
    this.baseUrl = `${url}/login`;
    axios.defaults.headers.common["authorization"] = `${Cookies.get(
      "currentUserToken"
    )} ${Cookies.get("device_mfa_key") ? Cookies.get("device_mfa_key") : ""}`;
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleTokenChange = (token) => {
    this.setState({ mfa_token: token });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      axios.defaults.headers.common["authorization"] = `${Cookies.get(
        "currentUserToken"
      )} ${Cookies.get("device_mfa_key") ? Cookies.get("device_mfa_key") : ""}`;
      const res = await axios.post(this.baseUrl + "/login_user", {
        email: this.state.email,
        password: this.state.password,
      });
      Cookies.set("currentUserToken", JSON.stringify(res.data["token"]));
      Cookies.set("name", res.data["name"]);
      Cookies.set("role", res.data["role"]);
      Cookies.set("user_id", res.data["_id"]);
      sessionStorage.setItem('open', true);

      axios.defaults.headers.common["authorization"] = `${Cookies.get(
        "currentUserToken"
      )} ${Cookies.get("device_mfa_key") ? Cookies.get("device_mfa_key") : ""}`;
      if (!res.data.temp || !res.data.mfa_req) {
        await axios.post(this.baseUrl + "/authenticate", {
          send_mfa_key: true,
        });
      }
      this.setState({
        redirect: res.data.temp
          ? "/update_password"
          : res.data.mfa_req
          ? "mfa"
          : "/home",
        mfa_red: res.data.mfa_req,
        err_msg: "",
      });
    } catch (err) {
      this.setState({ err_msg: err.response.data.message });
    }
  };

  handleAuth = (e) => {
    e.preventDefault();
    axios
      .post(this.baseUrl + "/authenticate", {
        mfa_token: this.state.mfa_token,
      })
      .then((res) => {
        Cookies.set(
          "device_mfa_key",
          JSON.stringify(res.data["device_mfa_key"]),
          { expires: 3650 }
        );
        this.setState({
          redirect: "/home",
          err_msg: "",
        });
      })
      .catch((err) => {
        this.setState({ err_msg: err.response.data.message });
      });
  };

  handleEmailNewCode = async () => {
    axios.defaults.headers.common["authorization"] = `${Cookies.get(
      "currentUserToken"
    )} ${Cookies.get("device_mfa_key") ? Cookies.get("device_mfa_key") : ""}`;
    await axios.post(this.baseUrl + "/authenticate", {
      send_mfa_key: true,
    });
    this.setState({
      err_msg: "",
      mfa_message: "New code emailed.",
    });
  };

  render() {
    if (this.state.redirect !== "default" && this.state.redirect !== "mfa") {
      return <Redirect to={{pathname: this.state.redirect, state:{reload:true}}} />;
    }
    return (
      <div
        className="container-fluid bg-light"
        style={{ height: "calc(100vh - 10px)" }}
      >
        <CenterBanner />
        {this.state.redirect === "default" ? (
          <SIForm
            handleChange={this.handleChange}
            passVal={this.state.password}
            emailVal={this.state.email}
            validateUser={this.handleSubmit}
            err_msg={this.state.err_msg}
          />
        ) : (
          <MFAForm
            handleChange={this.handleTokenChange}
            mfa_token={this.state.mfa_token}
            validateUser={this.handleAuth}
            err_msg={this.state.err_msg}
            handleEmailNewCode={this.handleEmailNewCode}
            message={this.state.mfa_message}
          />
        )}
      </div>
    );
  }
}

export default SignIn;
