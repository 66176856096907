import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import './DeleteModal.css';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    border: "2px solid #3f85f0",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function DeleteModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [deleteMe, setDeleteMe] = React.useState(false);

  const handleChange = (e) => {
    if (e.target.value === 'delete me'){
        setDeleteMe(true);
    }
    else {
        setDeleteMe(false);
    }
  }

  const body = props.success ? (
    props.err_msg? props.err_msg : <div style={modalStyle} className={classes.paper}>
       <h4>{props.successMsg ? props.successMsg : "Deleted Successfully!"}</h4>
      <button
        className={"button"}
        style={{
          marginTop: "10px",
          float: "right",
        }}
        onClick={props.handleClose}
      >
        Close
      </button>
    </div>
  ) : props.err_msg? (
    <div style={modalStyle} className={classes.paper}>
      <h4>{ props.err_msg}</h4>
      <button
        className={"button"}
        style={{
          marginTop: "10px",
          float: "right",
        }}
        onClick={props.handleClose}
      >
        Close
      </button>
    </div>
    ) : (
    <div style={modalStyle} className={classes.paper}>
      <h4>Are you sure you want to delete?</h4>
      <label for='delete'>Type delete me to delete</label>
      <input className='delete-input' type='text' placeholder='delete me' id='delete' onChange={handleChange} style={{'marginLeft': "5px"}}/>
      <br />
      <div className={"flex-row button"} style={{ justifyContent: "right" }}>
        <button
          className={"flex-row button"}
          style={{ marginTop: "10px" }}
          onClick={props.handleDelete}
          disabled={!deleteMe}
        >
          Delete
        </button>
        <button
          className={"flex-row button"}
          style={{ marginTop: "10px", marginLeft: "10px", borderColor: "red" }}
          onClick={props.toggle}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.show}
        onClose={props.toggle}
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
