import { React, Component,useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { Divider } from "@material-ui/core";
import FormItem from "../../../components/FormItem/FormItem";
import NavigationIcon from "@material-ui/icons/Navigation";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";

import { useEffect } from 'react';

const UserForm = (props) => {
  const newEntity = window.location.pathname.includes("add_user") === true;
  const [editModeActive,setEditModeActive] = useState(window.location.pathname.includes("add_user") === true);

  const editFormHandler = () => {
    setEditModeActive(!editModeActive);
  };

  const showBasic =
      !editModeActive &&
      !props.disabled &&
      !newEntity;

  const showUpdate =
    editModeActive &&
    !props.disabled &&
    !newEntity;

  // const TILE_SIZE = 250;
  // const [numColumns,setNumColumns] = useState(3);

  // const [tiles, setTiles] = useState([
  //   { value:"top", x: 0, y: 0, color: 'red' },
  //   { value:"m1", x: 1, y: 0, color: 'green' },
  //   { value:"m2", x: 0, y: 1, color: 'blue' },
  //   { value:"bottom", x: 1, y: 1, color: 'yellow' },
  // ]);

  // const [tiles, setTiles] = useState([
  //   { 
  //     value:
  //       <div className="box">
  //         <FormItem
  //           itemName="Name"
  //           requiredField={true}
  //           afterCreate={props.afterCreate}
  //           itemType="basicInput"
  //           id="user-name"
  //           value={props.name}
  //           onChange={props.handleChange}
  //           handle="name"
  //         />
  //       </div>,
  //       pos:0 },
  //   { 
  //     value:
  //             <div className="box">
  //               <FormItem
  //                 itemName="Email"
  //                 requiredField={true}
  //                 afterCreate={props.afterCreate}
  //                 itemType="basicInput"
  //                 id="user-email"
  //                 type="email"
  //                 value={props.email}
  //                 onChange={props.handleChange}
  //                 handle="email"
  //               />
  //             </div>,
  //             pos:0 },
  //   { 
  //     value:
  //     <FormItem
  //             itemName="Role"
  //             requiredField={true}
  //             afterCreate={props.afterCreate}
  //             itemType="dropDown"
  //             id="role"
  //             value={props.role}
  //             handleChange={props.handleChange}
  //             handle="role"
  //             listItems={["Admin", "User", "Read Only"]}
  //           />,
  //           pos:0 },
  //   { 
  //     value:
  //     <div className="box">
  //                <FormItem
  //                 itemName="Title"
  //                 itemType="basicInput"
  //                 id="user-title"
  //                 value={props.title}
  //                 onChange={props.handleChange}
  //                 handle="title"
  //               />
  //             </div>,
  //             pos:0 },
  // ]); 

  // const [positions,setPositions] = useState([
  //   { id: 0, tile: 0 },
  //   { id: 1, tile: 1 },
  //   { id: 2, tile: 2 },
  //   { id: 3, tile: 3 },
  // ]);

  // const handleDragStart = (event, pos) => {
  //   event.dataTransfer.setData('text/plain', pos.tile);
  // }

  // const handleDrop = (event, targetPos) => {
  //   const draggedTileId = Number(event.dataTransfer.getData('text/plain'));
  //   // const remainingTiles = tiles.filter(tile => tile.id !== Number(draggedTileId));
  //   // const newTiles = remainingTiles.map(tile => {
  //   //   if (tile.id === targetTile.id) {
  //   //     return { ...draggedTile, x: targetTile.x, y: targetTile.y };
  //   //   } else {
  //   //     const { x, y } = tile;
  //   //     if (draggedTile.x === x && draggedTile.y === y) {
  //   //       return { ...targetTile, id: tile.id };
  //   //     } else {
  //   //       return tile;
  //   //     }
  //   //   }
  //   // }).concat(targetTile).sort((a, b) => a.id - b.id);
  //   // setTiles(newTiles);
  
  //   const newPositions = positions.map(pos => {
  //     if(pos.tile === draggedTileId) return {id:pos.id,tile:targetPos.tile};
  //     else if(pos.tile === targetPos.tile) return {id:pos.id, tile:draggedTileId};
  //     else return {id:pos.id,tile:pos.tile}
  //   });
  //   setPositions(newPositions);
  // }

  // const handleDragOver = (event) => {
  //   event.preventDefault();
  // }


  useEffect(()=>{
    return () => {
    };
  },[]);

  return (
    <div>
      <div className="flex-row">
        {!newEntity ? (
          <h2 style={{maxWidth: "70rem", overflow: 'clip'}}>{props.name}</h2>
        ) : (
          <h2>New User</h2>
        )}
        {showUpdate && (
          <div className="flex-row">
            <button
              className={"flex-row button"} 
              onClick={props.toggle}
              style={{marginLeft: '20px', marginRight: '5px'}}
            >
              <NavigationIcon style={{ color: "rgb(63, 133, 240)" }} />
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  // color: "rgb(63, 133, 240)",
                }}
              >
                Update
              </div>
            </button>
            <div style={{ marginRight: "10px" }}></div>
            <button
              className={"flex-row button"}
              style={{ border: "2px solid red", marginRight: '10px'}}
              onClick={editFormHandler}
            >
              <CreateIcon style={{ color: "red" }} />
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  // color: "rgb(63, 133, 240)",
                }}
              >
                Edit
              </div>
            </button>
          </div>
        )}
        {showBasic && (
          <button
            className={"flex-row button"}
            onClick={editFormHandler}
            style={{marginLeft: '20px', marginRight: '10px'}}
          >
            <CreateIcon style={{ color: "rgb(63, 133, 240)" }} />
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
                // color: "rgb(63, 133, 240)",
              }}
            >
              Edit
            </div>
          </button>
        )}
        {newEntity && (
          <button 
            className={"flex-row button"} 
            onClick={props.toggle}
            style={{marginLeft: '20px', marginRight: '5px'}}
          >
            <NavigationIcon style={{ color: "rgb(63, 133, 240)" }} />
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
                // color: "rgb(63, 133, 240)",
              }}
            >
              Create
            </div>
          </button>
        )}
      </div>
      <Divider />
      {/* <form>
        <fieldset disabled={!editModeActive}>
          <div style={{ display: 'flex', flexWrap: 'wrap',}}>        
            {positions.map(pos => {
              console.log(tiles[pos.tile].value);
              return <div
                key={pos.id}
                draggable={editModeActive}
                onDragStart={event => handleDragStart(event, pos)}
                onDragOver={handleDragOver}
                onDrop={event => handleDrop(event, pos)}
                style={{
                  width: TILE_SIZE,
                  height: TILE_SIZE,
                  margin: '0.5 rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: editModeActive ? '1px dotted black' : 'none',
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                {tiles[pos.tile].value}
              </div>
            })}
          </div>
        </fieldset>
      </form> */}
      <form>
        <fieldset disabled={!editModeActive}>
           <div className="Row">
             <div className="Column">
               <div className="box">
                 <FormItem
                  itemName="Name"
                  requiredField={true}
                  afterCreate={props.afterCreate}
                  itemType="basicInput"
                  id="user-name"
                  value={props.name}
                  onChange={props.handleChange}
                  handle="name"
                />
              </div>
            </div>
            <div className="Column">
              <div className="box">
                <FormItem
                  itemName="Email"
                  requiredField={true}
                  afterCreate={props.afterCreate}
                  itemType="basicInput"
                  id="user-email"
                  type="email"
                  value={props.email}
                  onChange={props.handleChange}
                  handle="email"
                />
              </div>
            </div>
            <FormItem
              itemName="Role"
              requiredField={true}
              afterCreate={props.afterCreate}
              itemType="dropDown"
              id="role"
              value={props.role}
              handleChange={props.handleChange}
              handle="role"
              listItems={["Admin", "User", "Read Only"]}
            />
          </div>
          <div className="Row">
            <div className="Column">
              <div className="box">
                <FormItem
                  itemName="Title"
                  itemType="basicInput"
                  id="user-title"
                  value={props.title}
                  onChange={props.handleChange}
                  handle="title"
                />
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <ConfirmationModal
        handleUpdate={props.onUpdateClick}
        toggle={props.toggle}
        show={props.showModal}
        success={props.success}
        handleClose={props.handleClose}
        create={newEntity}
        handleCreate={props.onCreateClick}
        err_msg={props.err_msg}
        found_msg={props.found_msg}
      />
    </div>
  );
  // return (
  //   <div >
  //     <form>
  //       <fieldset disabled={!editModeActive}>
  //         <div className="Row">
  //           <div className="Column">
  //             <div className="box">
  //               <FormItem
  //                 itemName="Name"
  //                 requiredField={true}
  //                 afterCreate={props.afterCreate}
  //                 itemType="basicInput"
  //                 id="user-name"
  //                 value={props.name}
  //                 onChange={props.handleChange}
  //                 handle="name"
  //               />
  //             </div>
  //           </div>
  //           <div className="Column">
  //             <div className="box">
  //               <FormItem
  //                 itemName="Email"
  //                 requiredField={true}
  //                 afterCreate={props.afterCreate}
  //                 itemType="basicInput"
  //                 id="user-email"
  //                 type="email"
  //                 value={props.email}
  //                 onChange={props.handleChange}
  //                 handle="email"
  //               />
  //             </div>
  //           </div>
  //           <FormItem
  //             itemName="Role"
  //             requiredField={true}
  //             afterCreate={props.afterCreate}
  //             itemType="dropDown"
  //             id="role"
  //             value={props.role}
  //             handleChange={props.handleChange}
  //             handle="role"
  //             listItems={["Admin", "User", "Read Only"]}
  //           />
  //         </div>
  //         <div className="Row">
  //           <div className="Column">
  //             <div className="box">
  //               <FormItem
  //                 itemName="Title"
  //                 itemType="basicInput"
  //                 id="user-title"
  //                 value={props.title}
  //                 onChange={props.handleChange}
  //                 handle="title"
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </fieldset>
  //     </form>
  //     <ConfirmationModal
  //       handleUpdate={props.onUpdateClick}
  //       toggle={props.toggle}
  //       show={props.showModal}
  //       success={props.success}
  //       handleClose={props.handleClose}
  //       create={newEntity}
  //       handleCreate={props.onCreateClick}
  //       err_msg={props.err_msg}
  //       found_msg={props.found_msg}
  //     />
  //   </div>
  // );

}

export default UserForm;
