import React from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from "@mui/material/Button";
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import "./DeleteModal.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
export default function SimpleDeleteDialog(props) {
    const [open, setOpen] = React.useState(false);

    const handleDeleteAgreed = (e) => {
        props.removeFunc(e);
        handleClose();
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton
                edge="end"
                aria-label="delete"
                onClick={handleClickOpen}
            >
                <DeleteIcon />
            </IconButton>
            <Dialog
                PaperProps={{
                    style: { 
                        border: "2px solid #3f85f0",
                        borderRadius: "10px",
                        boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)",
                    }
                  }}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby={`delete-item-dialog-${props.itemName}`}
            >
                <DialogTitle>{`Are you sure you want to delete ${props.itemName}?`}</DialogTitle>
                <DialogActions>
                <Button onClick={handleDeleteAgreed} value={props.itemName}>Yes</Button>
                <Button onClick={handleClose}>No</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}