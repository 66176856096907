import { React, useState } from 'react';
import SingleNavigationBar from '../components/SingleNavigationBar/SingleNavigationBar';
import FilterObj from '../components/Filter/FilterObj';
import { Redirect } from 'react-router-dom';
import LoadingPage from '../components/LoadingPage/LoadingPage';
import { getCached, putCache } from '../caching/cacher';
import FormTable from '../shared_components/FormTable/FormTable';
import ApiService from '../services/apiService';
import { useEffect } from 'react';
import { useSwitch } from '@mui/material';
import { url } from '../config';
import { check_all_match } from '../components/Filter/FilterFunc';

const Contact = () => {

   
    const apiService = new ApiService(true);
    const defaultFilterList = {
        0: new FilterObj('status', 'Current', 'E'),
        1: new FilterObj(),
    };    
    
    const filterFields = [
        'name',
        'email',
        'address',
        'phone',
        'phone2',
        'role',
        'send_reports_to',
        'status',
    ];
    const dependentTables = ['CONTACT'];    
    const tableSorter="_id";
    const tableDataElements=["name","email","role"];
    const pageBaseTag="/contacts";
    const removeElementTag="/remove_contact";
    const addNewElementTag="/add_contact";
    const pageName="Contacts";
    const headCells=[
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'email',
            numeric: true,
            disablePadding: true,
            label: 'Email',
        },
        {
            id: 'role',
            numeric: true,
            disablePadding: true,
            label: 'Role',
        }
    ];
    const singleElementTag="/contact=";
    const showEl=["BulkUpload"];



    const [allRows,setAllRows] = useState([]);
    const [rows,setRows] = useState([]);
    const [displayRows,setDisplayRows] = useState([]);
    const [tablePage,setTablePage] = useState(0);
    const [filterList,setFilterList] = useState(defaultFilterList);
    const [filterCounter,setFilterCounter] = useState(1);
    const [modalOpen,setModalOpen] = useState(false);
    const [role,setRole] = useState('');
    const [disabled,setDisabled] = useState(true);
    const [redirect,setRedirect] = useState(false);
    const [loadingDialogOpen,setLoadingDialogOpen] = useState(true);

    const dataCreator = (row) => {
        return {
            _id: row._id,
            name:row.name,
            role:row.role,
            email:row.email,
        }
    }

    const states = {
        allRows,setAllRows,
        rows,setRows,
        displayRows,setDisplayRows,
        tablePage,setTablePage,
        filterList,setFilterList,
        filterCounter,setFilterCounter,
        modalOpen,setModalOpen,
        role,setRole,
        disabled,setDisabled,
        redirect,setRedirect,
        loadingDialogOpen,setLoadingDialogOpen,
        defaultFilterList,
        filterFields,
        dependentTables,
        dataCreator,
        tableSorter,
        tableDataElements,
        pageBaseTag,
        removeElementTag,
        addNewElementTag,
        pageName,
        headCells,
        singleElementTag,
        showEl,
    }


    useEffect(() => {      
         
        setLoadingDialogOpen(true); 
        
        const fetchData = async () => {
            const [response, error] = await apiService.get({
                path: '/authenticate',
                dataType: 'users',
            });
            if (error) setRedirect(true);
            else {
                let role = response.headers['role-data'];
                let disabled = role !== 'Admin';
                setRole(role);
                setDisabled(disabled);
            }
            
            const [up_to_date, cached_data] = await getCached(pageName);
            if (!up_to_date) {
                const [contactResp, error] = await apiService.get({
                    path: '/get_all_contacts',
                    dataType: 'contacts',
                });
                if (!error)  
                {
                    let rows = [];
                    for (let data of contactResp.data) {
                        rows.push(
                            dataCreator(data)
                        );
                    }
                    putCache(pageName,dependentTables, {
                        allRows: contactResp.data,
                        rows: rows,
                    });
                    setAllRows(contactResp.data);
                    setRows(rows);
                    setDisplayRows(contactResp.data);
                    //this.updateRowsFilter();       
                }
            } else {
                if (cached_data) {
                    setAllRows(cached_data.allRows);
                    setRows(cached_data.rows);
                    setDisplayRows(cached_data.allRows);
                    //this.updateRowsFilter();         
                }
            }
                         
            setLoadingDialogOpen(false);
        };
       

        fetchData();

        return () => {
            
            //handleLoadingDialogClose();
            // this now gets called when the component unmounts
        };

    }, []);
    
    if (redirect) {
		return <Redirect to="/login" />;
	}
    else return (
			<SingleNavigationBar
				content={
					<div>
						{loadingDialogOpen ? (
							<LoadingPage />
						) : (
							<FormTable								
                                states={states}
							/>
						)}
					</div>
				}
			/>
		);

}

export default Contact