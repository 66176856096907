import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  smallBoxMargin: {
    margin: theme.spacing(2),
    minWidth: 60,
  },
  buttonRoot: {
    margin: theme.spacing(4),
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    border: "2px solid #3f85f0",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function EmailResultModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const body = props.success ? (
    <div style={modalStyle} className={classes.paper}>
      <h3>Message Sent!</h3>
      <button
        className={"button"}
        style={{
          marginTop: "10px",
          float: "right",
        }}
        onClick={props.handleClose}
      >
          Close
      </button>
    </div>
  ) : (
    <div style={modalStyle} className={classes.paper}>
    <h3>{props.err}</h3>
    <button
      className={"button"}
      style={{
        marginTop: "10px",
        float: "right",
      }}
      onClick={props.handleClose}
    >
        Close
    </button>
  </div>
  );

  return (
    <div>
      <Modal
        open={props.show}
        onClose={props.handleClose}
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
