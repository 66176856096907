import axios from "axios";
import { url } from "../config";
import { createSheet, createAndWriteWorkBook} from "./ExcelCreator";
import Cookies from "js-cookie";

axios.defaults.headers.common["authorization"] = `${Cookies.get("currentUserToken")} ${Cookies.get("device_mfa_key") ? Cookies.get("device_mfa_key") : ""}`;

export async function dataDump(tablename) 
{
    const res = await axios.get(`${url}/datadump/dump_table`, {
        params: { "tablename": tablename },
    });
    let stringify_data = res.data;
    for (let i in stringify_data)
    {
        for (let key of Object.keys(stringify_data[i])) 
        {
            if (stringify_data[i][key] != null)
                stringify_data[i][key] = stringify_data[i][key].toString();
        }
    }
    return stringify_data;
}

export async function dataDumpMutiAndExport(tablenames, filename)
{
    let promise_reqs = {};
    const mapResolveToPromise = res => Object.fromEntries(
        Object.entries(promise_reqs).map(([key], index) => [key, res[index]])
    );
    for (let tablename of tablenames) promise_reqs[tablename] = dataDump(tablename);
    let promise_res = await Promise.all(Object.values(promise_reqs));
    promise_res = mapResolveToPromise(promise_res);
    let sheet_map = {};
    for (const k in promise_res) sheet_map[k] = createSheet(promise_res[k]);
    createAndWriteWorkBook(sheet_map, filename);
}