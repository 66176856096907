import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { List, Divider, InputBase,Tab,Tabs } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { Redirect } from "react-router-dom";
import axios from "axios";
import ReportCard from "./ReportCard";
import SingleNavigationBar from "../components/SingleNavigationBar/SingleNavigationBar";
import "./Dashboard.css";
import { url } from "../config";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import * as XLSX from "xlsx";
import Cookies from "js-cookie";

const styles = (theme) => ({
  root: {
    height: "75vh",
    backgroundColor: theme.palette.background.paper,
  },

  stats: {
    maxWidth: "50%",
    aligncontent: "center",
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      checked: [],
      setChecked: true,
      name: "",
      email: '',
      role: "",
      sortedReports: [],
      displayReports: [],
      completedReports:[],
      assigneeReports: new Set(),
      dateReports: new Set(),
      searchReports: new Set(),
      currentTabReports: new Set(),
      overDueReports: 0,
      dueThisMonth: 0,
      totalDueReports: 0,
      redirect: false,
      showMyReportsOnly: false,
      startDate: undefined,
      endDate: undefined,
      currentTab: 0,
      searchTerm: "",
    };
    this.reportInstanceBaseUrl = `${url}/report_instances`;
    this.userBaseUrl = `${url}/login`;
    axios.defaults.headers.common["authorization"] = `${Cookies.get(
      "currentUserToken"
    )} ${Cookies.get("device_mfa_key") ? Cookies.get("device_mfa_key") : ""}`;
  }

  // Retrieve all content
  retrieveContent = async (path, field) => {
    const resp = await axios.get(path);
      let respMap = {};
      if (resp.data[0]){
        resp.data.forEach(item => {
            respMap[item.email] = item[field];
        });
      }
      return respMap;
  };

  async componentDidMount() {
    if(!window.location.hash && this.props.location.state && this.props.location.state.reload) {
      window.location = window.location + '#loaded';
      window.location.reload();
    }
    axios
      .get(this.userBaseUrl + "/authenticate")
      .then((res) => {
        let role = res.headers["role-data"];
        this.setState({ role: role, email: res.headers['email'], name: res.headers['name'] });
      })
      .catch((err) => {
        this.setState({ redirect: true });
      }); 
      const [userMap, reportResp,completedResp] = await Promise.all([
        this.retrieveContent(this.userBaseUrl + "/get_all_users", 'name'),
          axios.get(this.reportInstanceBaseUrl + "/get_due"),
          axios.get(this.reportInstanceBaseUrl + "/get_completed")]);
    
    let reports = [];
    for (let x in reportResp.data) {
      reportResp.data[x].assigneeNames = reportResp.data[x].assignees.map(x => userMap[x]? userMap[x] : '');
      reports.push(reportResp.data[x]);
    }

    let completed = []
    for (let x in completedResp.data) {
      completedResp.data[x].assigneeNames = completedResp.data[x].assignees.map(x => userMap[x]? userMap[x] : '');
      completed.push(completedResp.data[x]);
    }
    
    this.setState({ reports: reports, completedReports: completed });

    this.setReports(reports);
  }


  setReports(reports) {
    let overDueReports = 0;
    let dueThisMonth = 0;
    let totalDueReports = 0;
    let tempReports = [];
    const currentDate = new Date();

    reports.forEach((item) => {
      let itemDate = new Date(item.date);
      const daysBetween =
        (itemDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);

      tempReports.push(item);
      totalDueReports++;
      if (itemDate < currentDate) overDueReports++;
      else if (daysBetween <= 14) dueThisMonth++;
    });

    tempReports.sort((item1, item2) =>
      new Date(item1.date) > new Date(item2.date) ? 1 : -1
    );

    this.setState({
      overDueReports: overDueReports,
      dueThisMonth: dueThisMonth,
      totalDueReports: totalDueReports,
      sortedReports: tempReports,
      displayReports: tempReports,
      assigneeReports: new Set(tempReports),
      dateReports: new Set(tempReports),
      searchReports: new Set(tempReports),
      currentTabReports: new Set(tempReports),
    });
  }

  combineSets() {
    let overDueReports = 0;
    let dueThisMonth = 0;
    let totalDueReports = 0;
    let displayReports = [];
    let currentReport = [];

    if(this.state.currentTab < 2) currentReport = this.state.sortedReports;
    else if(this.state.currentTab === 2) currentReport = this.state.completedReports;
    for (const item of currentReport) {
      if (
        this.state.dateReports.has(item) &&
        this.state.searchReports.has(item) &&
        this.state.currentTabReports.has(item)
      ) {
        displayReports.push(item);
        const currentDate = new Date();
        totalDueReports++;
        let itemDate = new Date(item.date);
        const daysBetween =
          (itemDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);
        if (itemDate < currentDate) overDueReports++;
        else if (daysBetween <= 14) dueThisMonth++;
      }
    }
    this.setState({
      overDueReports: overDueReports,
      dueThisMonth: dueThisMonth,
      totalDueReports: totalDueReports,
      displayReports: displayReports,
    });
  }

  handleSearch = (e) => {
    let searchReports = new Set();
    let currentReport;

    if(this.state.currentTab < 2) currentReport = this.state.sortedReports;
    else if(this.state.currentTab === 2) currentReport = this.state.completedReports;

    currentReport.forEach((item) => {
      let found = false;
      Object.entries(item).forEach((subItem) => {
        if (
          !found &&
          subItem[0] !== "dueDate" &&
          subItem[1] &&
          subItem[1]
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          searchReports.add(item);
          found = true;
        }
      });
    });

    this.setState({ searchReports: searchReports, searchTerm: e.target.value }, () => this.combineSets());
  };

  handleDateChange = (startDate, endDate) => {
    let dateReports = new Set();
    this.setState({ startDate: startDate, endDate: endDate }); 
    let currentReport;

    if(this.state.currentTab < 2) currentReport = this.state.sortedReports;
    else if(this.state.currentTab === 2) currentReport = this.state.completedReports;

    currentReport.forEach((item) => {
      let itemDate = new Date(item.date);
      if (
        (itemDate >= startDate && itemDate <= endDate) ||
        !startDate ||
        !endDate
      ) {
        dateReports.add(item);
      }
    });
    this.setState({ dateReports: dateReports }, () => this.combineSets());
  };

  exportToExcel = () => {
    let data = [];
    const headers = [
      "Property",
      "Relevant Party",
      "Report Type",
      "Days Left",
      "Due Date",
      "assignees",
    ];
    if(this.state.currentTab === 2) headers[3] = "Date Completed";

    data.push(headers);
    for (const row of this.state.displayReports) {
      const itemDate = new Date(row.date);
      const currentDate = new Date();
      const daysBetween =
        (itemDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);
      const daysLeft =
        Math.ceil(daysBetween) < 0
          ? "Past Due"
          : Math.ceil(daysBetween) > 0 && Math.ceil(daysBetween) < 2
          ? Math.ceil(daysBetween) + " Day"
          : Math.ceil(daysBetween) + " Days";
      const due_date = new Date(row.date);
      
      const currentRow = [
        row.property,
        row.party,
        row.type,
        daysLeft,
        due_date.toLocaleDateString("en-US"),
        row.assignees.join(", "),
      ]
      if(this.state.currentTab === 2) currentRow[3] = new Date(row.updatedAt).toLocaleString("en-US");

      data.push(currentRow);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    worksheet["!autofilter"] = { ref: "A1:F1" };
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Report Sheet");
    XLSX.writeFile(new_workbook, "Report.xlsx");
  };

  tabSwitch_CallBack = () => {
    const e = {
      target:{
        value:this.state.searchTerm,
      }
    };
    
    this.handleSearch(e);
    this.handleDateChange(this.state.startDate,this.state.endDate);
  };
  
  handleTabSwitch = (_,tabNum) => {
    let currentTabReports = new Set();

    //Show all Current reports
    if(tabNum === 0){
      currentTabReports = new Set(this.state.sortedReports);
    }
    //Show only reports that are relevent to the current user
    else if(tabNum === 1){
      this.state.sortedReports.forEach((item) => {
        if (item.assignees.includes(this.state.email) || item.assignees.includes(this.state.name)) {
          currentTabReports.add(item);
        }
      });    
    }
    //Show all Completed Reports
    if(tabNum === 2) {
      //currentTabReports = this.getCompleted();
      currentTabReports = new Set(this.state.completedReports);
    }

    this.setState(
      {
        currentTab: tabNum,
        currentTabReports: currentTabReports,
      },
      () => this.tabSwitch_CallBack(),
    );
  };

  statClickFilterHandler = () => {};

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }

    const mainContent = (
        <div className="root same-row" style={{ justifyContent: "start" }}>
          <div style={{ minWidth: "80%" }}>
            <div className="same-row" style={{ paddingRight: "35px", justifyContent: "normal" }}>
              <RangeDatePicker
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={(startDate, endDate) =>
                  this.handleDateChange(startDate, endDate)
                }
                minDate={new Date(1900, 0, 1)}
                maxDate={new Date(2100, 0, 1)}
                startDatePlaceholder="Start Date"
                endDatePlaceholder="End Date"
              />
              <h2 style={{width: "100%", textAlign:"center"}}>Scheduled Reports</h2>
            </div>
            <div style={{ minWidth: "100%", paddingTop: "10px" }}>
            <Tabs
                value={this.state.currentTab}
                textColor="primary"
                indicatorColor="primary"
                onChange= {this.handleTabSwitch}
                style={{
                  paddingBottom: '10px',
                }}
                centered
              >
                <Tab label="Active Reports"/>
                <Tab label="My Reports" />
                <Tab label="Completed Reports" />
            </Tabs>        
              <div
                className="dash-report_list_header"
                style={{ paddingBottom: "5px" }}
              >
                <div className="dash-report_list_header_item">Property</div>
                <div className="dash-report_list_header_item">
                  Relevant Party
                </div>
                <div className="dash-report_list_header_item">
                  Report Type
                </div>
                {/* <div className="dash-report_list_header_item">Date Completed</div> */}
                {this.state.currentTab !== 2 ? <div className="dash-report_list_header_item">Days Left</div> : <div className="dash-report_list_header_item">Date Completed</div>}
                <div
                  className="dash-report_list_header_item"
                  style={{ textAlign: "right", paddingRight: "35px" }}
                >
                  Due Date
                </div>
              </div>
              <List className="list" style={{}}>
                {this.state.displayReports.map((report) => (
                  <ReportCard reportInfo={report} currentTab={this.state.currentTab} />
                ))}
              </List>
            </div>   
          </div>
          <div
            style={{
              padding: "10px",
              marginLeft: "15px",
              minWidth: "15rem",
            }}
          >
            <div
              style={{
                justifyContent: "right",
                textAlign: "end",
              }}
            >
              <div className="same-row" style={{ justifyContent: "right" }}>
                <div className="search">
                  <div className="searchIcon">
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    color="white"
                    classes={{
                      root: "inputRoot",
                      input: "inputInput",
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={this.handleSearch}
                  />
                </div>
              </div>
              <div className="excel-export">
                <button onClick={this.exportToExcel}>Export</button>
              </div>
            </div>
            <div className="stat-block">
              <h2
                className="main-stat"
                style={{
                  color: this.state.currentTab !== 2 ? this.state.overDueReports > 0 ? "red" : "green" : "grey",
                }}
              >
                {this.state.currentTab !== 2 ? this.state.overDueReports : 0}
              </h2>
              <h5 className="name-stat" style={{
                  color: this.state.currentTab === 2 ? "grey" : null,
                }}>Past Due</h5>
              <Divider />
              <h2 className="main-stat" style={{
                  color: this.state.currentTab === 2 ? "grey" : null,
                }}>{this.state.dueThisMonth}</h2>
              <h5 className="name-stat"  style={{
                  color: this.state.currentTab === 2 ? "grey" : null,
                }}>Due Within 2 Weeks</h5>
              <Divider />
              <h2 className="main-stat">{this.state.totalDueReports}</h2>
              <h5 className="name-stat">Total Reports</h5>
              <Divider />
            </div>
          </div>
        </div>
    );



    return (
      <div>
        <SingleNavigationBar
          // mainContent={<ReportCard reportInfo={reports[0]} />}
          content={mainContent}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Dashboard);
