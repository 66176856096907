// This component is used when the user clicks 'Forgot my password' on the sign in page

import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "axios";
import CenterBanner from "../CenterBanner/CenterBanner";
import { url } from "../../config";
import { Redirect } from "react-router-dom";
import Counter from "../Counter/Counter";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class ForgotPassword extends Component {
  constructor() {
    super();

    this.state = {
      email: "",
      showError: false,
      messageFromServer: "",
      showNullError: false,
      redirect: false,
      showSuccess: false,
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // If the inputted email exists in the system (aka the user exists) send the user an email
  sendEmail = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    if (email === "") {
      this.setState({
        showError: false,
        messageFromServer: "",
        showNullError: true,
      });
    } else {
      try {
        const response = await axios.post(`${url}/login/forgot_password`, {
          email,
        });
        if (response.data.message === "Recovery email sent!") {
          this.setState({
            showError: false,
            messageFromServer: response.data.message,
            showNullError: false,
            showSuccess: true,
          });
          setTimeout(() => this.setState({ redirect: true }), 3000);
        }
      } catch (error) {
        console.error(error.response.data.message);
        if (error.response.data.message === "email not in db") {
          this.setState({
            showError: true,
            messageFromServer: "",
            showNullError: false,
            showSuccess: false,
          });
        }
      }
    }
  };

  render() {
    const { classes } = this.props;

    const { email, showNullError, showError, showSuccess, redirect } = this.state;

    return (
      redirect ? 
      <Redirect to="/login" />
      :
      <div>
        <CenterBanner />
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            <form className={classes.form} onSubmit={this.sendEmail}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                type="email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={this.handleChange("email")}
                value={email}
              />
              {!showSuccess && <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Send Password Reset Email
              </Button>}
            </form>
            {showNullError && (
              <div>
                <p>The email address cannot be null.</p>
              </div>
            )}
            {showSuccess && (
              <div>
                <h3 style={{ textAlign: "center", color: "green" }}>
                  Password Reset Email Successfully Sent!
                  <br/>
                  Redirecting in <Counter/> seconds...
                </h3>
              </div>
            )}
            {showError && (
              <div>
                <p style={{ textAlign: "center", color: "red" }}>
                  That email address isn&apos;t recognized. Please try again or
                  contact the administrator.
                </p>
              </div>
            )}
            <Link href="/">
              <Button variant="contained" color="primary">
                Go Home
              </Button>
            </Link>
          </div>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
