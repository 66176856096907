import { React } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Logo from "../../assets/images/you-logo-here.png";

const SIImg = () => {
  return (
    <nav className="navbar navbar-light mt-2">
      <div className="mx-auto">
        <a className="navbar-brand" href="">
          <img src={Logo} alt="Logo" width="450" height="100" />
        </a>
      </div>
    </nav>
  );
};

export default SIImg;
