import { React, Component } from 'react';
import PropertyFormHandler from './PropertyForm/PropertyFormHandler';
import SingleNavigationBar from '../../components/SingleNavigationBar/SingleNavigationBar';

class PropertyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true
        } 
    }

    render() {
        return(
            <SingleNavigationBar content={
                <PropertyFormHandler
                disabled={this.state.disabled}
                />
            } />
        );
    };
}

export default PropertyPage;