import { React, Component } from "react";
import ReportInstanceFormHandler from "./ReportInstanceForm/ReportInstanceFormHandler";
import SingleNavigationBar from "../../components/SingleNavigationBar/SingleNavigationBar";

class ReportInstancePage extends Component {
  render() {
    return <SingleNavigationBar content={<ReportInstanceFormHandler />} />;
  }
}

export default ReportInstancePage;
