import { React, Component } from "react";
import LegalEntityForm from "./LegalEntityForm";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { url } from "../../../config";
import Cookies from 'js-cookie';
import { getCached } from "../../../caching/cacher";

class LegalEntityFormHandler extends Component {
  constructor(props) {
    super(props);
    this.partiesBaseUrl = `${url}/parties`;
    this.contactsBaseUrl = `${url}/contacts`;
    this.docBaseUrl = `${url}/docs`;
    this.userBaseUrl = `${url}/login`;
    this.propertyBaseUrl = `${url}/properties`;
    this.state = {
      _id: '',
      contacts: [],
      properties: {},
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      suggestions: [],
      code: "",
      name: "",
      start_date: "",
      end_date: "",
      contact: [],
      afterCreate: false,
      status: "Current",
      attachment: [],
      userInput: "",
      fileNames: [],
      newFileNames: [],
      redirect: false,
      form_disabled: true,
      showModal: false,
      success: false,
      goBack: false,
      property: "",
      propSuggestions: [],
      propActiveSuggestion: 0,
      propFilteredSuggestions: [],
      propShowSuggestions: false,
      err_msg: '',
      found_msg: '',
      loading: false,
    };
    axios.defaults.headers.common["authorization"] =
      `${Cookies.get("currentUserToken")} ${Cookies.get("device_mfa_key")?Cookies.get("device_mfa_key"):''}`;
  }

  // On component load, get the specific entity details
  async componentDidMount() {
    try{
        const auth = await axios.get(this.userBaseUrl + "/authenticate");
        const role = auth.headers["role-data"];
        const disabled =  role !== "Admin";
        this.setState({ form_disabled: disabled });
    }
    catch (err) {
        console.log(err);
        this.setState({ redirect: true });
    }

    let code = window.location.pathname.split("=")[1];
    if (code) {
        const entityResp = await axios.get(this.partiesBaseUrl + "/get_one", {
            params: { code: code},
        });
          
        if (entityResp.data){
            // Get the contacts for that specific entity
            const contactsResp = await axios.get(this.contactsBaseUrl + "/get_list_contacts", {
                params: {contact: entityResp.data.contact},
            });

            // Set the contact names
            let contacts = [];
            if (contactsResp.data[0]) contacts =  contactsResp.data.map((row) => `${row.name} <${row.email}>`);

            let prop = "";
            if (entityResp.data.property){
                //Get the property names
                const propResp = await axios.get(this.propertyBaseUrl + "/get_one_by_id", {
                    params: {_id: entityResp.data.property},
                })
        
                //Set the property name
                if (propResp.data) prop = propResp.data.name;
            }

            let start_date = new Date(entityResp.data.start_date)
                .toISOString()
                .substr(0, 10);
            let end_date = new Date(entityResp.data.end_date)
                .toISOString()
                .substr(0, 10);
            this.setState({
                _id: entityResp.data._id,
                code: entityResp.data.code,
                name: entityResp.data.name,
                start_date: start_date,
                end_date: end_date,
                contact: contacts,
                property: prop,
                status: entityResp.data.status,
                fileNames: entityResp.data.attachment,
            });
        }
    }
    // Get all contacts for the autocomplete feature to prepopulate the field
    const contacts = await axios.get(this.contactsBaseUrl + "/get_all_contacts");
    let suggestions = contacts.data.map((row) => `${row.name} <${row.email}>`);
    this.setState({ suggestions: suggestions });

    // Get all properties for the autocomplete feature to prepopulate the field
    const props = await axios.get(this.propertyBaseUrl + "/get_all_properties");
    suggestions = props.data.map((row) => row.name);
    this.setState({ propSuggestions: suggestions });
  }

  // When update/create is pressed, show the confirmation modal
  toggle = async () => {
    const [up_to_date, cached_data] = await getCached("Legal Documents");
      if (cached_data && cached_data.rows.filter(e => e.code === this.state.code).length > 0) {
        this.setState({found_msg: `An entity with the code ${this.state.code} already exists. Do you want to overwrite?`});
      }
    this.setState({ showModal: !this.state.showModal, err_msg: "", success: false });
  };

  handleClose = () => {
    if (window.location.pathname.includes("add_entity") === true){
      this.setState({
        showModal: false,
        success: false,
        goBack: true,
        err_msg: "",
        found_msg: '',
      });
    } else {
      this.setState({
        showModal: false,
        success: false,
        err_msg: "",
        found_msg: '',
      });
    }
  };

  // When the user updates the entity info, save that data
  onUpdateClick = async (e) => {
    e.preventDefault();
    if (!this.state.code ||
      !this.state.name ||
      !this.state.property ||
      !this.state.contact.length ||
      !this.state.start_date ||
      !this.state.end_date ||
      !this.state.status) {
        this.setState({err_msg: "Fill in the missing fields highlighted in red.", afterCreate: true});
        return;
    }
    const { attachment } = this.state;

    const formData = new FormData();

    let fileNames = [...this.state.fileNames];
    let newFileNames = [];

    for (let x = 0; x < attachment.length; x++) {
      formData.append("file", attachment[x]);
      fileNames.push(attachment[x].name);
      newFileNames.push(attachment[x].name);
    }

    this.setState({ fileNames: fileNames });
    try {
      await axios
        .post(this.docBaseUrl + "/upload", formData, {
          headers: {
            names: newFileNames.join(','),
            path: `entities/${this.state.code}`,
            'Content-Type': 'multipart/form-data',
          },
      });
      await axios
        .patch(this.partiesBaseUrl + "/update_party", {
          _id: this.state._id,
          code: this.state.code,
          name: this.state.name,
          start_date: this.state.start_date,
          end_date: this.state.end_date,
          contact: this.state.contact
              .map(item => item.substring(item.indexOf('<') + 1, item.indexOf('>'))),
          property: this.state.property,
          status: this.state.status,
          attachment: fileNames,
        });
        this.setState({ success: true, err_msg: "" });
    }
    catch(err) {
      this.setState({err_msg: err.response.data.message});
    }
  };

  // When a new entity is created, send the data to the backend
  onCreateClick = async (e) => {
    e.preventDefault();

    if (!this.state.code ||
      !this.state.name ||
      !this.state.property ||
      !this.state.contact.length ||
      !this.state.start_date ||
      !this.state.end_date ||
      !this.state.status) {
        this.setState({err_msg: "Fill in the missing fields highlighted in red.", afterCreate: true});
        return;
    }

    const { attachment } = this.state;

    const formData = new FormData();

    let fileNames = [...this.state.fileNames];
    let newFileNames = [];

    for (let x = 0; x < attachment.length; x++) {
      formData.append("file", attachment[x]);
      fileNames.push(attachment[x].name);
      newFileNames.push(attachment[x].name);
    }

    this.setState({ fileNames: fileNames });
    try {
      await axios
      .post(this.docBaseUrl + "/upload", formData, {
        headers: {
          names: fileNames.join(','),
          path: `entities/${this.state.code}`,
          'Content-Type': 'multipart/form-data',
        }});
        await axios
          .post(this.partiesBaseUrl + "/add_party", {
            code: this.state.code,
            name: this.state.name,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            contact: this.state.contact
                  .map(item => item.substring(item.indexOf('<') + 1, item.indexOf('>'))),
            property: this.state.property,
            status: this.state.status,
            attachment: fileNames,
          });
        this.setState({ success: true, err_msg: '' });
    }
    catch(err) {
      this.setState({err_msg: err.response.data.message});
    }
  };

  // Handle the removal of a contact from the contact field
  handleDeleteClick = (e) => {
    let newContactsName = [...this.state.contact];
    const index = this.state.contact.indexOf(e.currentTarget.value);
    if (index > -1) {
      newContactsName.splice(index, 1);
      this.setState({ contact: newContactsName });
    }
  };

  // As the user types the contact name, update the suggestions to show
  onContactChange = (e) => {
    const userInput = e.currentTarget.value;

    const filteredSuggestions = this.state.suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  // handle a contact being clicked from the suggestion list
  onContactClick = (e) => {
    let newContactsName = [...this.state.contact];
    if (!newContactsName.includes(e.currentTarget.innerText)) {
      newContactsName.push(e.currentTarget.innerText);
      this.setState({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: "",
        contact: newContactsName,
      });
    }
  };

  // Handle up/down and enter keys being used while contact suggestion list is open
  onContactKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    // Enter key
    if (e.keyCode === 13) {
      let newContactsName = [...this.state.contact];
      if (
        !newContactsName.includes(filteredSuggestions[activeSuggestion]) &&
        filteredSuggestions[activeSuggestion]
      ) {
        newContactsName.push(filteredSuggestions[activeSuggestion]);
        this.setState({
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: "",
          contact: newContactsName,
        });
      }
    }
    // Up key
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        this.setState({ activeSuggestion: filteredSuggestions.length - 1 });
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // Down key
    else if (e.keyCode === 40) {
      if (activeSuggestion + 1 === filteredSuggestions.length) {
        this.setState({ activeSuggestion: 0 });
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  // As the user types the property name, update the suggestions to show
  onPropChange = (e) => {
    const userInput = e.currentTarget.value;

    const propFilteredSuggestions = this.state.propSuggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
        console.log(propFilteredSuggestions);
    this.setState({
      propActiveSuggestion: 0,
      propFilteredSuggestions,
      propShowSuggestions: true,
      property: e.currentTarget.value,
    });
  };

  // handle a property being clicked from the suggestion list
  onPropClick = (e) => {
      this.setState({
        propActiveSuggestion: 0,
        propFilteredSuggestions: [],
        propShowSuggestions: false,
        property: e.currentTarget.innerText,
      });
  };

  // Handle up/down and enter keys being used while property suggestion list is open
  onPropKeyDown = (e) => {
    const { propActiveSuggestion, propFilteredSuggestions } = this.state;
    // Enter key
    if (e.keyCode === 13) {
      if (propFilteredSuggestions[propActiveSuggestion]) {
        this.setState({
          propActiveSuggestion: 0,
          propShowSuggestions: false,
          property: propFilteredSuggestions[propActiveSuggestion],
        });
      }
    }
    // Up key
    else if (e.keyCode === 38) {
      if (propActiveSuggestion === 0) {
        this.setState({ propActiveSuggestion: propFilteredSuggestions.length - 1 });
        return;
      }
      this.setState({ propActiveSuggestion: propActiveSuggestion - 1 });
    }
    // Down key
    else if (e.keyCode === 40) {
      if (propActiveSuggestion + 1 === propFilteredSuggestions.length) {
        this.setState({ propActiveSuggestion: 0 });
        return;
      }
      this.setState({ propActiveSuggestion: propActiveSuggestion + 1 });
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // On file select (from the pop up)
  onFileChange = (event) => {
    this.setState({ attachment: event.target.files });
  };

  removeDocFromEntityHelper = (fileName) => {
    let newFileNames = [...this.state.fileNames];
    const index = this.state.fileNames.indexOf(fileName);
    if (index > -1) {
      newFileNames.splice(index, 1);
      axios
        .patch(this.partiesBaseUrl + "/update_party", {
          code: this.state.code,
          attachment: newFileNames,
        })
        .then((response) => {
          this.setState({ fileNames: newFileNames });
        })
        .catch((err) => console.log(err));
    }
  };

  handleRemoveDoc = (e) => {
    const val = e.currentTarget.value;
    axios
      .post(this.docBaseUrl + "/delete",
       {
          code: this.state.code,
          fileName: val,
          folder: 'entities'
      })
      .then((res) => {
         this.removeDocFromEntityHelper(val);
      })
      .catch((err) => console.log(err));
  };

  handleDocClick = (code) => (e) => {
    if (!this.state.loading){
      this.setState({loading: true});
      axios
        .get(this.docBaseUrl + "/download", {
          params: {
            fileName: code,
            code: this.state.code,
            folder: 'entities'
          },
          responseType: "blob",
        })
        .then((response) => {
          // then print response status
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          const fileName = code;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
          this.setState({loading: false});
        })
        .catch((err) => {
          console.log(err);
          this.removeDocFromReportInstanceHelper(code);
          this.setState({loading: false});
        });
      }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    if (this.state.goBack) {
      return <Redirect to="/entities" />;
    }
    return (
      <LegalEntityForm
        code={this.state.code}
        name={this.state.name}
        start_date={this.state.start_date}
        end_date={this.state.end_date}
        contact={this.state.contact}
        status={this.state.status}
        fileNames={this.state.fileNames}
        attachment={this.state.attachment}
        activeSuggestion={this.state.activeSuggestion}
        filteredSuggestions={this.state.filteredSuggestions}
        showSuggestions={this.state.showSuggestions}
        userInput={this.state.userInput}
        onContactChange={this.onContactChange}
        onContactClick={this.onContactClick}
        onContactKeyDown={this.onContactKeyDown}
        handleChange={this.handleChange}
        onUpdateClick={this.onUpdateClick}
        onCreateClick={this.onCreateClick}
        handleDeleteClick={this.handleDeleteClick}
        suggestions={this.state.suggestions}
        userContacts={this.state.userContactsName}
        onFileChange={this.onFileChange}
        handleRemoveDoc={this.handleRemoveDoc}
        handleDocClick={this.handleDocClick}
        form_disabled={this.state.form_disabled}
        toggle={this.toggle}
        showModal={this.state.showModal}
        success={this.state.success}
        handleClose={this.handleClose}
        propActiveSuggestion={this.state.propActiveSuggestion}
        propFilteredSuggestions={this.state.propFilteredSuggestions}
        propShowSuggestions={this.state.propShowSuggestions}
        propSuggestions={this.state.propSuggestions}
        onPropChange={this.onPropChange}
        onPropClick={this.onPropClick}
        onPropKeyDown={this.onPropKeyDown}
        property={this.state.property}
        err_msg={this.state.err_msg}
        found_msg={this.state.found_msg}
        afterCreate={this.state.afterCreate}
        loading={this.state.loading}
      />
    );
  }
}

export default LegalEntityFormHandler;
