import { React, useEffect, useState} from "react";
import SingleNavigationBar from "../components/SingleNavigationBar/SingleNavigationBar";
import FilterObj from "../components/Filter/FilterObj";
import LoadingPage from "../components/LoadingPage/LoadingPage";
import { Redirect } from "react-router-dom";
import { getCached, putCache } from "../caching/cacher";
import FormTable from "../shared_components/FormTable/FormTable";
import ApiService from '../services/apiService';

const Report = () => {
    
    const apiService = new ApiService(true);  
    const filterFields = [
        "type",
        "property",
        "relevant_party",
        "relevant_party_name",
        "responsible_party",
        "recepients",
        "due_date_type",
        "number_of_months",
        "number_of_days",
        "fixed_date",
        "annually",
        "desc_type",
        "yardi_report_desc",
        "report_detail_desc",
    ];
    const defaultFilterList = {
        0: new FilterObj(),
    };
    const dependentTables = ["REPORT", "PARTY", "PROPERTY", "CONTACT"];
    
    const [allRows,setAllRows] = useState([]);
    const [rows,setRows] = useState([]);
    const [displayRows,setDisplayRows] = useState([]);
    const [tablePage,setTablePage] = useState(0);
    const [filterList,setFilterList] = useState(defaultFilterList);
    const [filterCounter,setFilterCounter] = useState(0);
    const [modalOpen,setModalOpen] = useState(false);
    const [role,setRole] = useState('');
    const [disabled,setDisabled] = useState(true);
    const [redirect,setRedirect] = useState(false);
    const [loadingDialogOpen,setLoadingDialogOpen] = useState(true);

    
    const dataCreator = (row) => {
        return {
        _id:row._id,
        type:row.type,
        relevant_party_name:row.relevant_party_name,
        property:row.property,
        instances:row.instances,
        responsible_party:row.responsible_party,
        }
    }
    
    
    const tableSorter="_id";
    const tableDataElements=["type","property","relevant_party_name"];
    const pageBaseTag="/reports";
    const removeElementTag="/remove_report";
    const addNewElementTag="/add_report";
    const pageName="Reports";
    const headCells=[
        { id: 'type', numeric: false, disablePadding: true, label: 'Report Name' },
        { id: 'property', numeric: true, disablePadding: true, label: 'Property' },
        { id: 'rel_party', numeric: true, disablePadding: true, label: 'Relevant Party Name' },
    ]
    const singleElementTag="/report=";
    const showEl=["BulkUpload"];

    
    const states = {
        allRows,setAllRows,
        rows,setRows,
        displayRows,setDisplayRows,
        tablePage,setTablePage,
        filterList,setFilterList,
        filterCounter,setFilterCounter,
        modalOpen,setModalOpen,
        role,setRole,
        disabled,setDisabled,
        redirect,setRedirect,
        loadingDialogOpen,setLoadingDialogOpen,
        defaultFilterList,
        filterFields,
        dependentTables,
        dataCreator,
        tableSorter,
        tableDataElements,
        pageBaseTag,
        removeElementTag,
        addNewElementTag,
        pageName,
        headCells,
        singleElementTag,
        showEl,
    }

    
    // Retrieve all content
    const retrieveContent = async (path,field, type) => {
        const [resp,error] = await apiService.get({
            path: path,
            dataType: type,      
        });
        let respMap = {};
        if (resp.data[0]){
            resp.data.forEach(item => {
                if (typeof(field) === 'object') {
                    let tempMap = {};
                    field.forEach(e => tempMap[e] = item[e]);
                    respMap[item._id] = tempMap;
                } 
                else {
                    respMap[item._id] = item[field];
                }
            });
        }
        return respMap;
    };

    useEffect(() => {
        
        setLoadingDialogOpen(true); 

        const fetchData = async () => {
            const [response, error] = await apiService.get({
                path: '/authenticate',
                dataType: 'users',
            });
            if (error) setRedirect(true);
            else {
                let role = response.headers['role-data'];
                let disabled = role !== 'Admin';
                setRole(role);
                setDisabled(disabled);
            }
    
            const [up_to_date, cached_data] = await getCached(pageName);
            if(!up_to_date){

                const [reportResp,reportErr] = await apiService.get({
                    path:"/get_all_reports",
                    dataType:"reports",
                });

                const [entMap, propMap, userMap, contactMap] = await Promise.all([
                    retrieveContent("/get_all_parties", ['code', 'name', 'contact'],"entities"),
                    retrieveContent("/get_all_properties", 'name',"properties"),
                    retrieveContent("/get_all_users", 'email','users'),
                    retrieveContent("/get_all_contacts", 'name','contacts'),
                ]);

                if(!error){
                    let rows = [];
                    
                    for (let x in reportResp.data) {
                        reportResp.data[x].responsible_party = reportResp.data[x].responsible_party.map(x => userMap[x]);
                        reportResp.data[x].property = propMap[reportResp.data[x].property];
                
                        const contacts = entMap[reportResp.data[x].relevant_party].contact;
                        reportResp.data[x].recepients = contacts.map(x => contactMap[x]);
                
                        const rp_id = reportResp.data[x].relevant_party;
                        reportResp.data[x].relevant_party_name = entMap[rp_id].name;
                        reportResp.data[x].relevant_party = entMap[rp_id].code;
                        
                        rows.push(
                          dataCreator(reportResp.data[x])
                        );
                    }

                    putCache(pageName,dependentTables, {
                        allRows: reportResp.data,
                        rows: rows,
                    });
                    setAllRows(reportResp.data);
                    setRows(rows);
                    setDisplayRows(reportResp.data);
                }


            }
            else {
                if (cached_data) {
                    setAllRows(cached_data.allRows);
                    setRows(cached_data.rows);
                    setDisplayRows(cached_data.allRows);
                }
            }       

            setLoadingDialogOpen(false);
        };

        
        fetchData().catch(console.error);

        return () => {

        };           

    },[]);

    
    if (redirect) {
        return <Redirect to="/login" />;
    }
    else return (
        <SingleNavigationBar
        content={
            <div>
            {loadingDialogOpen ? (
                <LoadingPage />
            ) : (
                <FormTable
                states={states}
                />
            )}
            </div>
        }
        />
    );

}

export default Report;