import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import CenterBanner from '../CenterBanner/CenterBanner'
// import PageNotFound from '../../assets/images/PageNotFound';
class NotFoundPage extends Component{
    render(){
        return (
            <div>
            <CenterBanner />
            <Container component="main" maxWidth="xs">
                <h3>404 page not found</h3>
                <p>We are sorry but the page you are looking for does not exist.</p>
                <Link to="/">Go to Home </Link>
            </Container>
        </div>
        );
    }
}
export default NotFoundPage;