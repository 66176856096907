import { React, Component } from 'react'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.css'
import PRForm from './PRForm/PRForm';
import { Redirect } from 'react-router-dom'
import CenterBanner from '../components/CenterBanner/CenterBanner'
import {url} from '../config';
import Cookies from 'js-cookie';

class PReset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            old_password: '',
            new_password1: '',
            new_password2: '',
            redirect: 'default',
        };
        this.baseUrl = `${url}/login`;
    }

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.new_password1 !== this.state.new_password2){
            console.log('Passwords must match')
            return;
        }
        axios
            .post(this.baseUrl + '/update_password', {
                old_password: this.state.old_password,
                password: this.state.new_password2,
                temp: false
            },
            {
                headers: {
                    authorization: Cookies.get('currentUserToken')
                }
            })
            .then(result => {
                this.setState({redirect: '/home'})
            })
            .catch(err => {
                console.log('Error' + err);
            });
    }

    render() {
        if (this.state.redirect !== 'default') {
            return (
                <Redirect to={this.state.redirect} />
            )
        }

        return (
            <div>
                <CenterBanner />
                <PRForm 
                    old_pass_val={this.old_password}
                    new_pass1_val={this.state.new_password1}
                    new_pass2_val={this.state.new_password2}
                    handleChange={this.handleChange}
                    updatePassword={this.handleSubmit}
                />
            </div>
        )
    }
}

export default PReset;