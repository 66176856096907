import axios from "axios";
import {url} from '../config';

const STORAGE_TABLE = "CACHED"

export async function getCached(cacheID)
{
    let local_instance = sessionStorage.getItem(`${STORAGE_TABLE}-${cacheID}`);
    if (local_instance) {
        local_instance = JSON.parse(local_instance);
        const instance_data = local_instance['data'];
        const instance_tables = local_instance['tables'];
        let up_to_date = true;
        const required_tables = Object.entries(instance_tables).map(e => {
            return e[0];
        });
        const filter_null = Object.entries(instance_data).filter(e => e[1]);
        const last_versions = await axios.get(`${url}/version/get_many`, {
            params: {
                table_names: required_tables
            }
        });
        if (last_versions.data || last_versions.data.length === filter_null.length){
            for (const td of last_versions.data) {
                
                if (td.updated === instance_tables[td.name]) {
                    continue;
                } else {
                    up_to_date = false;
                    break;
                }
            }
        } else up_to_date = false;
        return [up_to_date, instance_data];
    } else {
        return [false, null];
    }
}

export async function putCache(cacheID, tables, data)
{
    const store_obj = {
        'data': data,
        'tables': {}
    };
    const last_versions = await axios.get(`${url}/version/get_many`, {
        params: {
            table_names: tables
        }
    });
    for (const tb of tables) {
        store_obj['tables'][tb] = null;
    }
    if (last_versions.data) {
        for (const table of last_versions.data) {
            store_obj['tables'][table.name] = table.updated;
        }
    }
    sessionStorage.setItem(`${STORAGE_TABLE}-${cacheID}`, JSON.stringify(store_obj));
}