// This is the toolbar on top of the table that contains the table name and the filter and add/deltet buttons

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { InputBase } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import AddIcon from "@material-ui/icons/Add";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Filter from "../../components/Filter/Filter";
import { useTheme } from '@material-ui/core/styles';
import Stack from '@mui/material/Stack';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  myc:{
    color:"orange"
  }
}));

const FormTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const theme = useTheme();

  function onFilterOpen() {
    props.setModalOpen(true);
  }

  return (
    <div>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: props.numSelected > 0,
        })}
      >
        {props.numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {props.numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {props.toolbarName}
          </Typography>
        )}
        {/* Search all the items */}
        <div className="search">
          <div className="searchIcon">
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            //color="white"
            classes={{
              root: "inputRoot",
              input: "inputInput",
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={props.handleSearch}
          />
        </div>

        {/* If at least one row is selected, show the delete button only */}
        {props.numSelected > 0 ? (
          <IconButton aria-label="More" onClick={props.openDeleteModal}>
            <DeleteIcon />
          </IconButton>
        ) : (
          // else, show the filter and add button
          <Stack
            direction="row"
          >
            {!props.disabled && (
               <Tooltip title="Add New">
                 <IconButton aria-label="Add" onClick={props.handleAddClick}>
                   <AddIcon />
                 </IconButton>
               </Tooltip>
            )}
            <Tooltip title="Filter list" onClick={onFilterOpen}>
              <IconButton aria-label="filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>  
            {!props.disabled && props.showEl.includes("BulkUpload") && (
              <Button
                  style={{
                    width: "75px",
                    borderBottomLeftRadius: "15px",
                    borderTopLeftRadius: "15px", 
                    color:"white",
                    fontSize:"16px",
                    backgroundColor: theme.palette.primary.main,
                    textTransform:'none'
                  }}
                  onClick={props.toggle}
                >
                   Bulk Upload
              </Button>
            )}
            <Button
              style={{
                width: "75px",
                borderBottomRightRadius: "15px",
                borderTopRightRadius: "15px",
                color:"white",
                fontSize:"16px",
                backgroundColor: "green",
                textTransform:'none'
              }}
              onClick={props.handleCSVClick}
            >
              CSV
            </Button>
          </Stack>
          // <ButtonGroup
          //   variant="outlined" 
          //   color="white"
          //   style={{
          //     border:"none",
          //     outline:"none",
          //     borderBlockWidth:"10px"
          //   }}            
          // >
          //   {!props.disabled && (
          //     <Button 
          //       key="add" 
          //       label="Add" 
          //       startIcon={<AddIcon />} 
          //       onClick={props.handleAddClick}
          //     /> 
          //   )}
          //   <Button aria-label="filter list" startIcon={<FilterListIcon />}/> 
          //   {!props.disabled && (
          //     <Button
          //         style={{
          //           width: "75px",
          //           borderBottomLeftRadius: "15px",
          //           borderTopLeftRadius: "15px", 
          //           color:"white",
          //           fontSize:"16px",
          //           backgroundColor: theme.palette.primary.main,
          //           textTransform:'none'
          //         }}
          //         onClick={props.toggle}
          //       >
          //          Bulk Upload
          //     </Button>
          //   )}
          //   <Button
          //     style={{
          //       width: "75px",
          //       borderBottomRightRadius: "15px",
          //       borderTopRightRadius: "15px",
          //       color:"white",
          //       fontSize:"16px",
          //       backgroundColor: "green",
          //       textTransform:'none'
          //     }}
          //     onClick={props.handleCSVClick}
          //   >
          //     CSV
          //   </Button>
          // </ButtonGroup>
        )}
      </Toolbar>
      <Filter
        filterList={props.filterList}
        deleteFromFilterList={props.deleteFromFilterList}
        updateStringFromFilterList={props.updateStringFromFilterList}
        updateFieldFromFilterList={props.updateFieldFromFilterList}
        updateTypeFromFilterList={props.updateTypeFromFilterList}
        modalOpen={props.modalOpen}
        setModalOpen={props.setModalOpen}
        filterFields={props.filterFields}
        addFilterField={props.addFilterField}
        updateFilteredRows={props.updateFilteredRows}
        handleFilterReset={props.handleFilterReset}
      />
    </div>
  );
};

FormTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default FormTableToolbar;
