import { React, Component } from "react";
import { Redirect } from "react-router-dom";
import "../components/FormItem/FormItem.css";
import HelpPageForm from "./HelpPageForm";
import axios from "axios";
import { url } from "../config";
import Cookies from "js-cookie";

class HelpPageFormHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      software_module_name: "RTM",
      issue_categ: "General",
      issue_msg: "",
      success: false,
      afterCreate: false,
      err: "",
      show: false,
      attachment: [],
      loading: false,
    };
    this.userBaseUrl = `${url}/login`;
    axios.defaults.headers.common["authorization"] = `${Cookies.get(
      "currentUserToken"
    )} ${Cookies.get("device_mfa_key") ? Cookies.get("device_mfa_key") : ""}`;
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  sendEmail = async (e) => {
    e.preventDefault();
    if (!this.state.issue_msg) {
      this.setState({err: "Fill in the missing field highlighted in red.", show: true, afterCreate: true});
      return;
    }
    this.setState({loading: true});
    if (this.state.issue_msg) {
      const { attachment } = this.state;

      const formData = new FormData();
      formData.append('email', this.props.user_email);
      formData.append('software_module_name', this.state.software_module_name);
      formData.append('issue_categ', this.state.issue_categ);
      formData.append('issue_msg', this.state.issue_msg);

      for (let x = 0; x < attachment.length; x++) {
        formData.append("file", attachment[x]);
      }
      try {
        await axios.post(this.userBaseUrl + "/help_page_email", formData, 
        {headers: {
          "Content-Type": "multipart/form-data",
        }}, );
        this.setState({ success: true, 
                        show: true, 
                        loading: false,
                        software_module_name: "RTM",
                        issue_categ: "General",
                        issue_msg: "",
                        attachment: []
                      });
      } catch (err) {
        console.log(err);
        this.setState({ err: err.response.data.message, loading: false });
      }
    }
  };

  handleClose = () => this.setState({ show: false, success: false, err: "" });

  onFileChange = event => {
    this.setState({attachment: event.target.files})
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    if (this.state.goBack) {
      return <Redirect to="/help" />;
    }
    return (
      <div>
        <HelpPageForm
          software_module_name={this.state.software_module_name}
          issue_categ={this.state.issue_categ}
          issue_msg={this.state.issue_msg}
          handleChange={this.handleChange}
          sendEmail={this.sendEmail}
          show={this.state.show}
          success={this.state.success}
          err={this.state.err}
          handleClose={this.handleClose}
          afterCreate={this.state.afterCreate}
          onFileChange={this.onFileChange}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default HelpPageFormHandler;
