import React, {Component} from 'react';
import {dataDumpMutiAndExport} from '../../Utils/DumpData';
import "../../Dashboard/Dashboard.css";

export default class Settings extends Component {
    constructor(props){ 
        super(props);
        this.state = {

        };
    }
    
    exportToExcelAll = () => {
        const table_list = ["CONTACT", "PROPERTY", "PARTY", "REPORT", "REPORT_INSTANCE", "USER", "VERSION"];
        dataDumpMutiAndExport(table_list, "all_data_export.xlsx");
    }

    render() {
        return (
            <div>
                <div className="excel-export">
                <button onClick={this.exportToExcelAll}>Export All</button>
              </div>
            </div>
        );
    }
}