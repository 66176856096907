import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";

  
const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(2),
      minWidth: 120
    },
    smallBoxMargin: {
      margin: theme.spacing(2),
      minWidth: 60
    },
    buttonRoot: {
      margin: theme.spacing(4)
    },
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    "overflow-y": "scroll",
    bottom: "-40%"
  };
}

export default function Filter(props) 
{
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    props.setModalOpen(false);
  };

  const handleChangeString = (event, index, value, key) => {
    props.updateStringFromFilterList(key, event.target.value);
  };

  const handleChangeField = (event, index, value, key) => {
    props.updateFieldFromFilterList(key, event.target.value);
  };

  const handleChangeType = (event, index, value, key) => {
    props.updateTypeFromFilterList(key, event.target.value);
  };

  const handleDelete = (event, index, value, key) => {
    props.deleteFromFilterList(key);
  }

  const handleAddFilter = () => {
    props.addFilterField();
  }

  const updateRows = () => {
    props.updateFilteredRows();
    handleClose();
  }

  const handleReset = () => {
    props.handleFilterReset();
  }

  let length_m = Object.keys(props.filterList).length;

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div id="modal-description">
      {Object.entries(props.filterList).map((item, index) => {
        return (
          <div key={index}>
            <FormControl className={classes.margin}>
              <TextField id={`search-filter-textbox-${item[0]}`} label="Search" value={item[1].filterString} onChange={(event, index, value) => {handleChangeString(event, index, value, item[0])}}/>
            </FormControl>
            <FormControl className={classes.margin}>
              <InputLabel id={`filter-select-field-${item[0]}`}>Field</InputLabel>
              <Select
                labelId={`filter-select-field-${item[0]}`}
                id={`filter-select-field-id-${item[0]}`}
                value={item[1].filterField}
                onChange={(event, index, value) => {handleChangeField(event, index, value, item[0])}}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.entries(props.filterFields).map((in_item,i) => {
                  return (
                    <MenuItem key={i} value={in_item[1]}>{in_item[1].replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function(key) { return key.toUpperCase()})}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.smallBoxMargin}>
              <InputLabel id={`filter-select-type-${item[0]}`}>Type</InputLabel>
              <Select
                labelId={`filter-select-type-${item[0]}`}
                id={`filter-select-type-id-${item[0]}`}
                value={item[1].filterType}
                onChange={(event, index, value) => {handleChangeType(event, index, value, item[0])}}
              >
                <MenuItem value={"C"}>Contains</MenuItem>
                <MenuItem value={"E"}>Exact</MenuItem>
              </Select>
            </FormControl>
            {index !== 0 && (
              <FormControl className={classes.buttonRoot}>
                  <Button color="primary" onClick={(event, index, value) => {handleDelete(event, index, value, item[0])}}>DELETE</Button>
                </FormControl>
            )}
            {index === 0 && (
              <FormControl className={classes.buttonRoot}>
                  <Button color="primary" onClick={handleReset}>RESET</Button>
                </FormControl>
            )}
            {index === length_m - 1 && (
              <FormControl className={classes.buttonRoot}>
                <Button onClick={handleAddFilter} color="primary">ADD</Button>
              </FormControl>
            )}
          </div>
        );
      })}
      <FormControl className={classes.buttonRoot}>
        <Button onClick={updateRows} color="primary">FILTER</Button>
      </FormControl>
      </div>
    </div>
  );

  return (

    <div>
      <Modal
        open={props.modalOpen}
        onClose={handleClose}
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>

  );
}